import { createContext, useEffect, useReducer } from 'react';

import jwtDecode from 'jwt-decode';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LandingPage } from '../constants';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import getQueryVariable from './../utils/querystring';
import { useCurrentUser } from './CurrentUserContext';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  maxrole: null,
};

const JWTReducer = (state, action) => {
  let maxrole = '';
  switch (action.type) {
    case INITIALIZE:
      maxrole = 'SuperAdmin'; //getMaxRole(action.payload?.user?.roles);
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        maxrole: maxrole,
      };
    case SIGN_IN:
      maxrole = 'SuperAdmin'; //getMaxRole(action.payload?.user?.roles);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        maxrole: maxrole,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const navigate = useNavigate();

  const { fetchCurrentUser, setSelectedRole } = useCurrentUser();
  var query = getQueryVariable('source');
  if (query) {
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          fetchCurrentUser();

          //const response = await axios.get("/api/Account/authenticate");
          //const { user } = response.data.data;
          const user = jwtDecode(accessToken);

          const selectedRole = window.localStorage.getItem('selectedRole');
          const hasAccess = user?.roles.indexOf(selectedRole) > -1;

          if (selectedRole && hasAccess) {
            setSelectedRole(selectedRole);
          } else {
            const maxrole = 'SuperAdmin'; //getMaxRole(user?.roles);
            setSelectedRole(maxrole);
          }

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    // const response = await axios.post("/api/Account/authenticate", {
    // });

    // const { accessToken } = response.data.data;

    // const user = jwtDecode(accessToken);

    const maxrole = 'SuperAdmin'; //getMaxRole(user?.roles);

    // setSession(accessToken);

    // //cache permission api object
    // fetchCurrentUser();

    dispatch({
      type: SIGN_IN,
      payload: {
        //user,
        //maxrole,
      },
    });

    const landingPage = LandingPage[maxrole];

    setSelectedRole(maxrole);
    if (query) {
      navigate(query);
    } else {
      //
      navigate(`/${landingPage}`);
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/Account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
