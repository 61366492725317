import React, { useEffect, useState } from 'react';
import Tiles from './tile.js';

// interface TileRepeaterProps {
//     [categoryName: String]: any;
//     [DataSet: Array]: any;
// }

const TileRepeater = (props) => {
  const [defaultConfig, setDefaultConfig] = useState();
  const { categoryName, DataSet, initialTile, showAll } = props;
  const [TileToShow, setTileToShow] = useState(initialTile);

  useEffect(() => {
    setDefaultConfig({
      categoryName: categoryName ?? '',
      DataSet: DataSet ?? [],
      initialTile: initialTile ?? 8,
    });
    if (showAll) {
      ShowAllTiles();
    }
  }, [props]);

  const ShowAllTiles = () => {
    setTileToShow(defaultConfig?.DataSet?.length);
  };
  const ShowLessTiles = () => {
    setTileToShow(initialTile);
  };

  return (
    <>
      {defaultConfig && (
        <>
          <span className="category_name">{defaultConfig.categoryName}</span>
          <section className={'tile-section'}>
            {defaultConfig.DataSet.slice(0, TileToShow).map((data, idx) =>
              data && data.BG_img ? (
                <Tiles tile_details={data} key={idx} />
              ) : (
                ''
              ),
            )}
          </section>
          {TileToShow < defaultConfig.DataSet.length && (
            <button className="show-all" onClick={ShowAllTiles}>
              Show All
            </button>
          )}
          {TileToShow == defaultConfig.DataSet.length &&
            defaultConfig.DataSet.length > initialTile && (
              <button className="show-all" onClick={ShowLessTiles}>
                Show Less
              </button>
            )}
        </>
      )}
    </>
  );
};
export default TileRepeater;
