import React, { memo, useEffect, useState } from 'react';
import { createTreeData } from '../../helpers/create-tree-json';
import TreeMultiSelectDropdown from '../../sharedcomponents/tree-multi-select-dropdown/tree-multi-select-dropdown';
import { GenericName, ProductDeliveryPlatforms } from './../../constants';

const DistributorsDropdown = memo(
  ({ DataSet, Type, callBackDistributors, disabled = false }) => {
    const [distributorsResult, setDistributorsResult] = useState([]);
    const [treeDistributorsData, setTreeDistributorsData] = useState();
    const CI_ParentKeys = ['distributorPlatform'];
    const CP_ParentKeys = ['distributor', 'distributorPlatform'];

    useEffect(() => {
      getDistributorsDataSet();
    }, [DataSet]);

    const getDistributorsDataSet = async () => {
      try {
        if (DataSet) {
          let finalDistributorsResult = await DataSet?.results
            ?.map((el, i) => {
              if (
                el.platformCategories &&
                el.platformCategories !== '' &&
                el.distributor &&
                el.distributor !== '' &&
                el.distributorPlatform &&
                el.distributorPlatform !== '' &&
                el.deliveryType &&
                el.deliveryType !== ''
              )
                return {
                  id: i,
                  checked: Type === 'ProductEntitlements',
                  distributorLabel:
                    el.distributor + ' : ' + el.distributorPlatform,
                  ...el,
                };
              else return;
            })
            .filter((x) =>
              x
                ? Type === 'ProductEntitlements'
                  ? ProductDeliveryPlatforms.includes(x.distributor)
                  : !ProductDeliveryPlatforms.includes(x.distributor)
                : false,
            );

          let CP_Distributor = finalDistributorsResult.filter(
            (el) => el.platformCategories === GenericName.channelPartners,
          );
          let CI_Distributor = finalDistributorsResult.filter(
            (el) => el.platformCategories === GenericName.commodityInsights,
          );
          let CP_TreeData = await createTreeData(
            CP_Distributor,
            CP_ParentKeys,
            'CP',
          );
          let CI_TreeData = await createTreeData(
            CI_Distributor,
            CI_ParentKeys,
            'CI',
          );

          let treeData = [
            {
              label: GenericName.commodityInsights,
              value: 'CI',
              children: CI_TreeData,
            },
            {
              label: GenericName.channelPartners,
              value: 'CP',
              children: CP_TreeData,
            },
          ];
          setTreeDistributorsData(
            finalDistributorsResult.length > 0 ? treeData : [],
          );
          setDistributorsResult(finalDistributorsResult ?? []);
        }
      } catch (err) {}
    };

    const handleSelection = (selectedTreeData) => {
      let tempData = distributorsResult?.map((obj) => {
        obj.checked = selectedTreeData?.includes(String(obj.id));
        return obj;
      });
      setDistributorsResult(tempData);
    };

    useEffect(() => {
      const updatedState = distributorsResult
        ?.filter((distributorsData) => distributorsData.checked === true)
        ?.map((filterdistributorsData) => {
          return {
            distributor:
              filterdistributorsData.distributor +
              ':' +
              filterdistributorsData.distributorPlatform +
              ':' +
              filterdistributorsData.deliveryType,
            distributorId: filterdistributorsData.distributorId,
          };
        });
      callBackDistributors(updatedState);
    }, [distributorsResult]);

    const count = distributorsResult.reduce(
      (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
      0,
    );
    return (
      <TreeMultiSelectDropdown
        ddOptions={treeDistributorsData}
        getSelectedTreeData={handleSelection}
        enableSearch
        enableSelectAll
        ddPlaceHolder={
          <>
            Delivery Platform
            <span className={count === 0 ? 'd-none' : 'd-inline'}>
              ({count})
            </span>
          </>
        }
        disabled={disabled}
      />
    );
  },
  (op, np) => op?.DataSet === np?.DataSet,
);

export default DistributorsDropdown;
