import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlPath } from '../../constants';
import Loader from '../../components/Loader';
import { Endpoints, ReduxAction, contractTypeCSMPlus } from './../../constants';
import { getText } from './../../helpers/index';
import { addContract } from './../../redux/actions';
import { store } from './../../redux/store';
import httpService from './../../services/http-service';
import HtmlContent from './../../sharedcomponents/html-content/html-content';
import { packageImageNames, packageImageNamesCSMPlus } from './packageImages';
import { ReactComponent as BackIcon } from './../../assets/icons/common/back-arrow.svg';

const PackageDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tileDetails, setTileDetails] = useState();
  const [packageDataSets, setPackageDataSet] = useState([]);
  const [contractsResult, setContractsResult] = useState();
  const dispatch = useDispatch();
  const packageDetails = [
    { displayName: 'Markets Covered', value: 'marketsCovered' },
    {
      displayName: 'Key Market Insight Highlights',
      value: 'keyMarketInsightHighlights',
    },
    {
      displayName: 'Key Market Insights',
      value: 'keyMarketInsights',
    },
    { displayName: 'Key Features', value: 'keyFeatures' },
    { displayName: 'Key Benefits', value: 'keyBenefits' },
    { displayName: 'Key Highlights', value: 'keyHighlights' },
    { displayName: 'Key Personas & Use Cases', value: 'keyPersonasUseCases' },
    { displayName: 'Key Service Highlights', value: 'keyServiceHighlights' },
    { displayName: 'Key Markets', value: 'keyMarkets' },
    { displayName: 'Key Assessments', value: 'keyAssessments' },
    { displayName: 'Key Price Assessments', value: 'keyPriceAssessments' },
  ];
  const sectionsWithoutTitle = ['Key Assessments'];

  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      let storeData = store.getState();
      let storeUserInfo = storeData?.userInfo;
      if (storeUserInfo && storeUserInfo.userInfo) {
        getContractsDataSet(storeUserInfo.userInfo.email);
      }
    } catch (err) {}
  }, [useSelector((x) => x.userInfo)]);

  useEffect(() => {
    try {
      let storeData = store.getState();
      let storePackageDatasets = storeData?.datasets?.datasetDetails;
      if (location.state) {
        const tile_details = location?.state?.tile_details;
        setTileDetails(tile_details);
        setLoading(false);
        dispatch({
          type: ReduxAction.tabType,
          payload: {
            tabId: 1,
            id:
              location?.state?.tile_details?.tile_main_heading +
              '_' +
              location?.state?.tile_details?.id,
            category: location?.state?.tile_details.category,
            contractType: location?.state?.tile_details?.contractType,
          },
        });
      } else if (storePackageDatasets) {
        setPackageDataSet(storePackageDatasets);
        setLoading(false);
      } else if (contractsResult) {
        let contractCollection = contractsResult.map((el) => el.contractNumber);
        getContractPackage(contractCollection);
      }
    } catch (err) {}
  }, [contractsResult]);

  useEffect(() => {
    try {
      if (packageDataSets.length > 0) {
        const dataSetId = location.pathname.split('/').pop().split('-').pop();
        const tileDetails = packageDataSets.find((x) => x.id === dataSetId);
        setTileDetails(tileDetails);
        dispatch({
          type: ReduxAction.tabType,
          payload: {
            tabId: 1,
            id: tileDetails?.tile_main_heading + '_' + tileDetails?.id,
            category: tileDetails?.category,
            contractType: tileDetails?.contractType,
          },
        });
      }
    } catch (err) {}
  }, [packageDataSets]);

  const getContractsDataSet = async (userEmail) => {
    // Fetch contracts as per user email
    try {
      let storeData = store.getState();
      let storeContracts = storeData.contract?.contracts;
      if (storeContracts && storeContracts.length > 0) {
        setContractsResult(storeContracts);
      } else {
        let queryParamsContract = {
          PageSize: 5000,
          Field: 'contractNumber,bulkEntitlementEnable',
          Filter: `email:"${userEmail.toLowerCase()}"`,
        };
        let tempState = [];
        httpService
          .get(Endpoints.contractApi, queryParamsContract)
          .then((res) => {
            tempState = res.data?.results?.map((el) => {
              return {
                label: el.contractNumber,
                id: el.contractNumber,
                checked: true,
                bulkEntitlementEnable: el.bulkEntitlementEnable,
                ...el,
              };
            });
          })
          .catch(() => {
            tempState = [];
          })
          .finally(() => {
            setContractsResult(tempState);
            dispatch(addContract(tempState));
          });
      }
    } catch (err) {}
  };

  const getContractPackage = async (contractCollection) => {
    // Fetch contract packages
    try {
      const contractExist = contractCollection.length > 0;
      if (contractExist) {
        const url = Endpoints.contractPackagesApi;
        let queryParams = {
          field: 'contractNumber,packageName,packageId',
          Filter: `contractNumber IN(${contractCollection
            .map((contractNumber) => '"' + contractNumber + '"')
            .join(',')})`,
          PageSize: 50000,
        };
        httpService
          .get(url, queryParams)
          .then((res) => {
            const packageNameArr = res.data?.results?.map((el) => {
              if (el?.packageId) return String(el.packageId);
              else return '';
            });
            getPackageDataSet(packageNameArr, contractExist);
          })
          .catch((err) => {});
      } else {
        getPackageDataSet([], contractExist);
      }
    } catch (err) {}
  };

  const getPackageDataSet = async (packageNameArr, contractExist) => {
    try {
      let storeData = store.getState();
      let isCCA_User =
        storeData?.userInfo.userInfo?.SPGGroups?.includes('SFS_CCA');
      const url = Endpoints.packageDataset;
      httpService
        .get(url)
        .then((res) => {
          let dataset = res.data?.results?.map((el) => {
            let NewDataSet = {
              BG_img:
                el.contractType == contractTypeCSMPlus
                  ? packageImageNamesCSMPlus[el.imagePath]
                  : packageImageNames[el.imagePath],
              tile_title: el.industryCoverageData.replaceAll('"', ''),
              tile_main_heading: el.marketplaceDatasetName.replaceAll(',', ''),
              tile_description: el.marketplaceLongDescription.replaceAll(
                '"',
                '',
              ),
              recentlyAdded: el.recentlyAdded,
              recentlyEnhanced: el.recentlyEnhanced,
              longDescription: el.marketplaceLongDescription.replaceAll(
                '"',
                '',
              ),
              dataProviderDesc: el.marketplaceDataProviderDesc.replaceAll(
                '"',
                '',
              ),
              entityType: 'package',
              tile_disabled: isCCA_User
                ? contractExist
                  ? !packageNameArr.includes(el.packageId)
                  : true
                : false,
              ...el,
            };
            return NewDataSet;
          });
          setPackageDataSet(dataset);

          store.dispatch({
            type: ReduxAction.datasets,
            payload: { datasetDetails: dataset },
          });
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    } catch (err) {}
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <Row className="dataset-introduction-section">
          {/* <ScrollRestoration /> */}
          <Col sm={12} className="dataset-intro-main">
            <div
              onClick={() => navigate(UrlPath.packages)}
              className="d-flex align-items-center gap-2 cursor-pointer mb-3"
            >
              <BackIcon> </BackIcon>
              <span className="back-text">Back</span>
            </div>
            <section className="page-section border-bottom pb-3">
              {' '}
              <h1>{tileDetails?.marketplaceDatasetName}</h1>
              {tileDetails?.longDescription && (
                <HtmlContent
                  className="dataset__overview-description"
                  html={tileDetails?.longDescription}
                />
              )}
              {tileDetails?.dataProviderDesc && (
                <div className="dataset__overview-vendor-info">
                  <h2 className="subheading subheading--with-border">
                    {getText('generic.vendorInfo')}
                  </h2>
                  <HtmlContent
                    className="dataset__overview-vendor-info-body"
                    html={tileDetails?.dataProviderDesc}
                  />
                </div>
              )}
              <div className="package-sub-detail">
                {packageDetails.map((pkg) => {
                  return tileDetails && tileDetails[pkg.value] ? (
                    <>
                      <div>
                        <span className="sub-heading">
                          {!sectionsWithoutTitle.includes(pkg.displayName)
                            ? pkg.displayName + ' : '
                            : ''}
                        </span>
                        <br />
                        <HtmlContent
                          html={tileDetails ? tileDetails[pkg.value] : ''}
                        />
                      </div>
                      <br />
                    </>
                  ) : (
                    ''
                  );
                })}
              </div>
              {tileDetails?.tile_disabled && (
                <>
                  <div className="sub-heading snowflake-text">
                    Currently not subscribed.
                  </div>

                  <div>
                    Please contact{' '}
                    <a
                      className="link-text"
                      href="mailto:ci.support@spglobal.com"
                    >
                      ci.support@spglobal.com
                    </a>{' '}
                    for further assistance.
                  </div>
                </>
              )}
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PackageDetails;
