import React, { useState } from 'react';
import { ScaleLoader, ClipLoader } from 'react-spinners';

const Loader = (props) => {
  let [color, setColor] = useState('#000');
  const scaleLoader = {
    display: 'block',
    margin: '15% auto',
    width: '5.625rem',
  };
  const clipLoader = {
    display: 'block',
    margin: '0 auto',
  };
  return (
    <>
      {props.type === 'scaleLoader' ? (
        <ScaleLoader
          cssOverride={props.cssClass ?? scaleLoader}
          color={color}
          size={150}
        />
      ) : props.type === 'scaleLoaderDropdown' ? (
        <ScaleLoader color={color} width={2} height={15} />
      ) : props.type === 'clipLoader' ? (
        <ClipLoader cssOverride={clipLoader} color={color} />
      ) : (
        ''
      )}
    </>
  );
};

export default Loader;
