const buildUrl = (name, id) => {
  name = name
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/-a-/g, '-') // Replace '-a-' with '-'
    .replace(/(and|the)/g, '') // Replace 'and' and 'the' with ''
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
  return `${name}-${id.toString()}`;
};

export default buildUrl;
