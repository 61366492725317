import { groupBy } from './groupBy';

export const createTreeData = (
  resData = [],
  parentKeys = [], // Parent to child keys in incremental sequence. Last key is child.
  specialKey = '', // Unique tree node key
  level = -1,
  prevKey,
) => {
  let result = [];
  const parentLength = parentKeys?.length - 1;
  // Check root level to not exceed parent key length
  if (level > parentLength) return;
  else level++;

  let currentParentKey = parentKeys[level];
  // Groupby each parentKey as per level
  let groupedData = groupBy(resData, currentParentKey);
  // Loop through grouped object for tree structering
  result = Object.keys(groupedData)?.map((el, i) => {
    let uniqueKey = String(
      prevKey
        ? prevKey + '-' + i
        : (specialKey ? specialKey + '-' : '') + '1-' + i,
    );
    let obj = {
      label: String(el),
      value: uniqueKey,
    };
    // if parent node then loop its children
    if (level < parentLength) {
      obj.children = createTreeData(
        groupedData[el],
        parentKeys,
        '',
        level,
        uniqueKey,
      );
    } else {
      // if leaf node then set reference obj values
      obj.id = String(groupedData[el][0]?.id);
    }
    return obj;
  });
  return result;
};
