import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Endpoints, UrlPath, PersistReduxAction } from '../../../constants';
import httpService from '../../../services/http-service';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import { debounce } from '../../../helpers/debounce';
import {
  getFilterColumns,
  getSortColumns,
} from '../../../sharedcomponents/ag-grid/ag-grid-helper';
import { ReactComponent as ExportIcon } from './../../../assets/icons/common/export.svg';
import { useDispatch } from 'react-redux';
import { persistDataStore } from './../../../redux/persistStore';
import { addContract } from './../../../redux/actions';
const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    persistDataStore.dispatch({
      type: PersistReduxAction.csaActionData,
      payload: {},
    });
    dispatch(addContract([]));
  }, []);

  const gridRef = useRef();
  // const dispatch = useDispatch();
  const columnFilterParams = {
    filter: 'agTextColumnFilter',
    // filterParams: {
    //   buttons: ['reset', 'apply'],
    //   maxNumConditions: 1,
    // },
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const createLink = (params, action) => {
    if (params.value != null) {
      return (
        <div
          className="email-label"
          title={params.value}
          onClick={() => {
            action && action(params.value);
          }}
        >
          {params.value}
        </div>
      );
    } else return '';
  };

  const onCellClickHandler = (gridElement, type) => {
    let ccaData = { type: type, data: gridElement.data };
    switch (type) {
      case 'account':
        ccaData.value = gridElement?.data?.accountId;
        break;
      case 'contract':
        ccaData.value = gridElement?.data?.contractNumber;
        break;
      case 'user':
        ccaData.value = gridElement?.data?.email;
        ccaData.showUserDetails = true;
        break;
    }
    //set ccaData to redux
    persistDataStore.dispatch({
      type: PersistReduxAction.csaActionData,
      payload: ccaData,
    });
    navigate(UrlPath.userLocation);
  };
  const columns = [
    {
      headerName: 'Account name',
      field: 'account',
      tooltipField: 'account',
      initialFlex: 4,
      ...columnFilterParams,
      cellRenderer: (gridElement) => {
        const action = () => onCellClickHandler(gridElement, 'account');
        return createLink(gridElement, action);
      },
    },
    {
      headerName: 'Contract number',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      initialFlex: 3,
      ...columnFilterParams,
      cellRenderer: (gridElement) => {
        const action = () => onCellClickHandler(gridElement, 'contract');
        return createLink(gridElement, action);
      },
    },
    {
      headerName: 'User',
      field: 'email',
      initialFlex: 4,
      ...columnFilterParams,
      cellRenderer: (gridElement) => {
        const action = () => onCellClickHandler(gridElement, 'user');
        return createLink(gridElement, action);
      },
    },
  ];
  const defaultColdef = {
    sortable: true,
    resizable: true,
  };
  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
  };
  const gridStyle = { height: '83vh' };
  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    if (!searchText || searchText.length > 2) {
      gridRef.current.api.onFilterChanged();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    gridRef.current.api.setGridOption(
      'serverSideDatasource',
      getServerSideDatasource(),
    );
  };

  const getServerSideDatasource = () => {
    return {
      getRows: (params) => {
        try {
          let agParams = params?.request;

          let filterColumns = 'email,contractNumber,account';
          let searchText = document.getElementById(
            'admin-dashboard-filter-input',
          ).value;
          let queryParams = {
            Filter: getFilterColumns(agParams),
            Field: 'contractNumber,email,account,accountId,modifiedDate',
            FilteredColumns: JSON.stringify(
              gridRef.current.api.getFilterModel(),
            ),
            Sort: getSortColumns(agParams, 'modifiedDate:desc'),
            PageSize: agParams.endRow - agParams.startRow,
            Page: agParams.startRow / (agParams.endRow - agParams.startRow) + 1,
            querySearch: searchText,
            searchColumns: searchText ? filterColumns : '',
            id: String(Date.now()),
          };
          gridRef.current.api.showLoadingOverlay();
          httpService
            .get(Endpoints.userApi, queryParams)
            .then(({ data }) => {
              let rowData = data?.results;
              params.success({
                rowData: rowData,
                rowCount: data?.metadata.count,
              });
              if (rowData?.length === 0)
                gridRef.current.api.showNoRowsOverlay();
              else {
                gridRef.current.api.hideOverlay();
              }
            })
            .catch(() => {
              params.fail();
              gridRef.current.api.hideOverlay();
            });
        } catch (err) {
          params.fail();
        }
      },
    };
  };
  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };

  const exportDashboardDetailsCSV = () => {
    gridRef.current.api.exportDataAsCsv({
      fileName: 'ClientServiceDashboard_' + getDate(),
    });
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col">
          <input
            type="text"
            id="admin-dashboard-filter-input"
            className="form-control search-box"
            placeholder="Search by Account name, Contract number or User"
            onInput={onFilterTextBoxChangedDebounce}
          />
        </div>
        <div className="col action-item-bar">
          <ExportIcon
            className="icon-active cursor-pointer"
            alt="Email Domain Export"
            width="21"
            title="Export to CSV"
            onClick={exportDashboardDetailsCSV}
          ></ExportIcon>
        </div>
      </div>
      <div className="ag-grid-table admin">
        <AgGridComponent
          config={defaultConfig}
          gridStyle={gridStyle}
          defaultColumnDef={defaultColdef}
          // getRowId={getRowId}
          columns={columns}
          onGridReady={onGridReadyHandler}
        />
      </div>
    </>
  );
};

export default AdminDashboard;
