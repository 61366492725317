import FileSaver from 'file-saver';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import { fileSizeConverter } from '../../../helpers';
import httpService from '../../../services/http-service';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import ModalBox from '../../../sharedcomponents/modal-box/ModalBox';
import { ReactComponent as BulkUploadIcon } from './../../../assets/icons/common/bulk-upload.svg';
import { ReactComponent as CloudBigIcon } from './../../../assets/icons/common/cloud-big-icon.svg';
import { ReactComponent as CrossIcon } from './../../../assets/icons/common/cross-icon.svg';
import {
  Endpoints,
  ReduxAction,
  UserId,
  Verbiage,
  maxPageSize,
  BulkUploadUserEntitlementsTemplateType,
  PendingActive,
  GenericError,
} from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../bulk-upload-users/bulk-upload-users.scss';
import { Notify } from '../../../sharedcomponents/Alert/Notify';

const BulkUploadUserEntitlements = ({
  showBulkUserEntitlements,
  handleCloseBulkUerEntitlements,
  contracts,
  getUpdatedUserEntitlements,
}) => {
  const successGridRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isSuccessGridShow, setIsSuccessGridShow] = useState(false);
  const [isUploadBoxShow, setIsUploadBoxShow] = useState(true);
  const [uploadedUsers, setUploadedUsers] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sourceUsersGridStyle = { height: '35vh' };
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
  };
  const defaultSuccessColdef = {
    enableRowGroup: false,
    enablePivot: false,
    enableValue: false,
    resizable: true,
    headerCheckboxSelection: false,
  };

  const sourceSuccessUsersColumns = [
    {
      headerName: 'Email',
      field: 'email',
      tooltipField: 'email',
      initialFlex: 1,
      resizable: true,
      cellClass: (params) => {
        return 'uploaded-selected-user-row';
      },
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      tooltipField: 'firstName',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        return 'uploaded-selected-user-row';
      },
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      tooltipField: 'lastName',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        return 'uploaded-selected-user-row';
      },
    },
    {
      headerName: 'Office Location',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        return 'uploaded-selected-user-row';
      },
    },
    {
      headerName: 'Entitlement',
      field: 'entitlement',
      tooltipField: 'entitlement',
      resizable: true,
      initialFlex: 2,
      cellClass: (params) => {
        return 'uploaded-selected-user-row';
      },
    },
  ];

  const getRowError = (rowData) => {
    let rowError = '';
    if (rowData) {
      let fieldErrorDetails = [
        {
          field: 'first name',
          errorType: ['required', 'max', 'text'],
          maxLength: 80,
        },
        {
          field: 'last name',
          errorType: ['required', 'max', 'text'],
          maxLength: 80,
        },
        {
          field: 'email',
          errorType: ['required', 'max', 'email'],
          maxLength: 40,
        },
        {
          field: 'office location',
          errorType: ['required', 'dropDown'],
          dropDownIndex: 75,
        },
        {
          field: 'entitlement',
          errorType: ['required', 'dropDown'],
          dropDownIndex: 76,
        },
      ];
      for (let row = 0; row < fieldErrorDetails.length; row++) {
        fieldErrorDetails[row].errorType.forEach((x) => {
          switch (x) {
            case 'required':
              if (!rowData[row])
                rowError =
                  rowError + `Please enter ${fieldErrorDetails[row].field}. `;
              break;
            case 'max':
              if (
                rowData[row] &&
                rowData[row].length > fieldErrorDetails[row].maxLength
              )
                rowError =
                  rowError +
                  `${fieldErrorDetails[row].field} must be ${fieldErrorDetails[row].maxLength} characters or less. `;
              break;
            case 'text':
              if (rowData[row] && !String(rowData[row]).match(/^[a-zA-Z ]*$/))
                rowError =
                  rowError +
                  `No numerical or symbolic values are accepted in ${fieldErrorDetails[row].field}. `;
              break;
            case 'email':
              if (
                rowData[row] &&
                !String(rowData[row]).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
              )
                rowError =
                  rowError +
                  `Please enter valid ${fieldErrorDetails[row].field}. `;
              break;
            case 'dropDown':
              if (
                rowData[row] &&
                (!rowData[fieldErrorDetails[row].dropDownIndex] ||
                  rowData[fieldErrorDetails[row].dropDownIndex].includes(
                    '#ERROR',
                  ))
              )
                rowError =
                  rowError +
                  `Please enter valid ${fieldErrorDetails[row].field}. `;
              break;
            default:
          }
        });
      }
    }
    return rowError;
  };
  const validateBulkData = (excelData) => {
    let correctData = [];
    let i;

    for (i = 0; i < excelData.length; i++) {
      let error = getRowError(excelData[i]);
      if (!error) {
        let entitlementCol = excelData[i][76];
        let splittedEntitlementCol = entitlementCol.split(':');
        const existingDataIndex = correctData.findIndex(
          (group) => group.email.trim() === excelData[i][2].trim(),
        );
        if (existingDataIndex >= 0) {
          correctData[existingDataIndex].entitlements.push({
            entitlement: excelData[i][4],
            productComponentId: splittedEntitlementCol[0],
            contractNumber: splittedEntitlementCol[1],
            status: PendingActive,
          });
        } else {
          let user = {
            contactId: '-1',
            firstName: excelData[i][0],
            lastName: excelData[i][1],
            email: excelData[i][2],
            officeLocation: excelData[i][3],
            officeLocationId: excelData[i][75],
            isDeleted: 0,
            userid: UserId,

            entitlements: [
              {
                userEntitlementId: '-1',
                entitlement: excelData[i][4],
                productComponentId: splittedEntitlementCol[0],
                contractNumber: splittedEntitlementCol[1],
                status: PendingActive,
              },
            ],
          };
          correctData.push(user);
        }
      }
    }
    return correctData;
  };

  const getSuccessOrErrorMessage = (uploadedUsers) => {
    let successMessage = '';
    successMessage =
      uploadedUsers?.length > 0
        ? uploadedUsers.length +
          (uploadedUsers.length === 1
            ? Verbiage.singleUserEntitlement
            : Verbiage.multipleUserEntitlement) +
          ' uploaded successfully'
        : '';
    return successMessage;
  };

  const uploadUserEntitlements = (usersData) => {
    let successMessage = '';
    setLoading(true);
    httpService
      .post(Endpoints.bulkUserEntitlementApi, usersData)
      .then((resp) => {
        let uploadedUsers = resp?.data?.results
          ?.map((item1) => {
            let entitlementResults = item1.entitlements.map((item2) => {
              let finalItem = {
                ...item1,
                entitlement: item2.entitlement,
                productComponentId: item2.productComponentId,
              };
              delete finalItem.entitlements;
              return finalItem;
            });
            return entitlementResults;
          })
          .flat();

        if (uploadedUsers?.length > 0) {
          if (!successMessage) {
            successMessage = getSuccessOrErrorMessage(uploadedUsers);
          }
          setIsSuccessGridShow(true);
          setUploadedUsers(uploadedUsers);
          getUpdatedUserEntitlements();
        } else successMessage = Verbiage.noValidDataForUserEntitlementUpload;
        setSuccessMessage(successMessage);
      })
      .catch((err) => {
        setFileErrorMessage(GenericError.somethingWentWrong);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (uploadedUsers?.length > 0) {
      setTimeout(() => {
        successGridRef?.current?.api?.forEachNode((node) => {
          node.setSelected(true);
        });
        successGridRef?.current?.api?.refreshCells();
      }, 100);
    }
  }, [uploadedUsers]);

  const validateHeader = (firstRow) => {
    return (
      firstRow.filter((c) => c != null).join(',') ===
      'First Name,Last Name,Email Address,Office Location Name,Entitlement'
    );
  };
  const validateFile = () => {
    if (files && files[0]) {
      return true;
    } else {
      setFileErrorMessage('Please upload a file');
      return false;
    }
  };
  const validateFileData = (data) => {
    if (data && data.length > 0 && validateHeader(data[0])) {
      if (data.length > 1) {
        return true;
      } else {
        setFileErrorMessage('No data to upload');
        return false;
      }
    } else {
      setFileErrorMessage('Please upload a valid file');
      return false;
    }
  };
  const uploadBulkUsers = () => {
    if (contracts?.length > 0) {
      if (validateFile()) {
        try {
          readXlsxFile(files[0])
            .then((rows) => {
              if (validateFileData(rows)) {
                setIsUploadBoxShow(false);
                setFileErrorMessage('');
                let excelRows = rows.filter(
                  (r) =>
                    r[75] && r[76] && (r[0] || r[1] || r[2] || r[3] || r[4]),
                );
                if (excelRows && excelRows.length > 0) {
                  let validatedData = validateBulkData(excelRows);
                  if (validatedData && validatedData.length > 0)
                    uploadUserEntitlements(validatedData);
                  else
                    setFileErrorMessage(
                      Verbiage.noValidDataForUserEntitlementUpload,
                    );
                } else {
                  setFileErrorMessage(
                    Verbiage.noValidDataForUserEntitlementUpload,
                  );
                }
              }
            })
            .catch((error) => {
              setFileErrorMessage('No valid data to upload');
            });
        } catch (err) {
          setFileErrorMessage('Please upload a valid file');
        }
      }
    }
  };
  const onSuccessUserGridReadyHandler = (params) => {
    successGridRef.current = params;
    params.api.sizeColumnsToFit(
      'email,firstName,lastName,officeLocation,entitlement',
      false,
    );
  };

  const onUploadModalClose = () => {
    handleCloseBulkUerEntitlements(false);
  };

  const fetchDataAndDownloadExcel = async () => {
    let filteredContract = contracts?.filter(
      (contract) => contract.checked === true && contract.bulkEntitlementEnable,
    );
    if (filteredContract?.length > 0) {
      let headersParams = {
        pageSize: maxPageSize,
        Filter: `contractNumber IN (${filteredContract
          ?.map((t) => (t.id ? '"' + t.id + '"' : ''))
          .join(',')})`,
        templateType: BulkUploadUserEntitlementsTemplateType,
      };
      let queryParams = {
        responseType: 'blob',
      };
      await httpService
        .post(Endpoints.bulkUploadTemplateApi, {}, headersParams, queryParams)
        .then(({ data }) => {
          FileSaver.saveAs(data, 'SFS_Bulk_Upload_User_Entitlements.xlsx');
        })
        .catch((err) => {});
    } else {
      Notify({
        alert: true,
        type: 'warning',
        confirmBtnText: 'OK',
        title: Verbiage.contractNotSelected,
      });
    }
  };
  // Bulk upload drag and drop code
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...acceptedFiles]);
    },
    [files],
  );

  const removeFile = () => {
    setFiles([]);
    setIsSuccessGridShow(false);
    setFileErrorMessage('');
    setSuccessMessage('');
    setIsUploadBoxShow(true);
  };
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.slk', '.xla', '.xlt', '.xlw'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    maxFiles: 1,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <div key={file.path} className="file-name">
      <span className="truncate">{file.path}</span>{' '}
      <span className="fw-bolder align-top">
        ({fileSizeConverter(file.size)})
      </span>
      <span>
        <CrossIcon
          onClick={removeFile}
          className="cross-icon"
          alt="Close"
          title="Close"
        ></CrossIcon>
      </span>
    </div>
  ));
  // Bulk upload drag and drop code ends here

  const onSelectionChanged = () => {
    successGridRef.current.api.refreshCells();
  };

  return (
    <>
      <ModalBox
        backdrop="static"
        size="lg"
        show={showBulkUserEntitlements}
        handleClose={onUploadModalClose}
        modalTitle="Bulk Upload User Entitlements"
        showLoader={loading}
      >
        <Row>
          <Col className="d-flex align-items-center justify-content-end">
            <div onClick={fetchDataAndDownloadExcel}>
              <BulkUploadIcon
                className="me-1 cursor-pointer bulk-upload-download-icon"
                alt="Bulk Upload Users"
                title="Bulk Upload Users"
              ></BulkUploadIcon>
              <span className="bulk-upload-text">
                <span>BULK UPLOAD TEMPLATE</span>
              </span>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            {isUploadBoxShow && (
              <div
                className={`${
                  isDragAccept
                    ? 'upload-box-border-blue'
                    : isDragReject
                    ? 'upload-box-border-red'
                    : ''
                } text-center upload-box`}
                {...getRootProps({
                  onClick: removeFile,
                })}
              >
                <span>
                  <input {...getInputProps()} />
                  <CloudBigIcon
                    className="mb-2"
                    alt="Bulk Upload Users Entitlements"
                    title="Bulk Upload Users Entitlements"
                  ></CloudBigIcon>
                  <br />
                  Please download the bulk upload template from the link above
                  and upload here.
                  <br />
                  <span className="non-italic"> Upload XLSX</span>
                </span>
              </div>
            )}
            {files.length > 0 && (
              <div className={`grid-wrapper ${isUploadBoxShow ? 'mt-3' : ''}`}>
                {files.length > 0 ? (
                  <>
                    <div>{acceptedFileItems}</div>
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs lg="8">
            <div className="error-message-text">{fileErrorMessage}</div>
            <div className="error-message-text">{successMessage}</div>
          </Col>
          <Col xs lg="4">
            {isUploadBoxShow && (
              <button
                className="btn btn-dark upload-btn btn-opacity float-end"
                disabled={!files.length > 0}
                onClick={uploadBulkUsers}
              >
                UPLOAD
              </button>
            )}
          </Col>
        </Row>
        {isSuccessGridShow && (
          <div>
            <Row>
              <Col>
                <AgGridComponent
                  config={defaultConfig}
                  defaultColumnDef={defaultSuccessColdef}
                  data={uploadedUsers}
                  columns={sourceSuccessUsersColumns}
                  onSelectionChanged={onSelectionChanged}
                  gridStyle={sourceUsersGridStyle}
                  onGridReady={onSuccessUserGridReadyHandler}
                />
              </Col>
            </Row>
          </div>
        )}
      </ModalBox>
    </>
  );
};

export default memo(BulkUploadUserEntitlements);
