import React, { useState, useEffect } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { Chips } from 'primereact/chips';
import { Endpoints, GenericError, loaderStyle } from './../../constants';
import httpService from '../../services/http-service';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import './manage-email-domains.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Loader from '../../components/Loader';

const EmailDomainsAddEdit = ({
  show,
  handleClose,
  editedEmailDomains,
  getEmailDomains,
}) => {
  const [loading, setLoading] = useState(false);
  const [manageEmailDomainsChanged, setManageEmailDomainsChanged] =
    useState(false);
  const [initialValue, setInitialValue] = useState({
    emailDomains: [],
    accountId: '',
    accountName: '',
    contractNumber: '',
  });
  const [isDuplicate, setIsDuplicate] = useState(false);
  const emailDomainLimit = 100;

  useEffect(() => {
    if (editedEmailDomains) {
      const splitedEmailDomains =
        editedEmailDomains?.emailDomain?.split(',') ?? [];
      const accountId = editedEmailDomains?.accountId;
      const accountName = editedEmailDomains?.accountName;
      setInitialValue({
        emailDomains: splitedEmailDomains,
        accountId: accountId,
        accountName: accountName,
        contractNumber: editedEmailDomains?.contractNumber,
      });
    }
  }, [editedEmailDomains]);

  const validate = Yup.object({
    emailDomains: Yup.array()
      .of(
        Yup.string()
          .matches(
            '^@(?!-)[A-Za-z0-9-]+(?<!-)([.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,7}$',
            'Invalid email domain.',
          )
          .max(63, 'Email domain must be 63 characters or less'),
      )
      .required('Email domains are required.')
      .min(1, 'Email domains are required.'),
  });

  const SubmitHandler = (value) => {
    try {
      let postData = value?.emailDomains?.map((el, i) => {
        return {
          contractNumber: initialValue?.contractNumber,
          emailDomain: el,
        };
      });

      setLoading(true);
      httpService
        .post(Endpoints.emailDomainApi, postData)
        .then((res) => {
          if (res?.data?.code === 0) {
            getEmailDomains();
            handleClose();

            let successMsg =
              initialValue?.emailDomains?.length > 0
                ? 'Email domains updated'
                : 'Email domains saved';
            Notify({
              alert: true,
              type: 'success',
              title: successMsg,
            });
          }
        })
        .catch((err) => {
          let errorMsg =
            err?.response?.data?.resultCode === '-2'
              ? err?.response?.data?.cause
              : GenericError.somethingWentWrong;

          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {}
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: SubmitHandler,
  });

  const isFormFieldValid = (name) => !!formik.errors[name];
  const getFormErrorMessage = (name) => {
    if (isDuplicate)
      return <small className="p-error">Email domain already exists</small>;
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  function arraysEqual(a, b) {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    let arraysEqual =
      a.length === b.length && a.every((el, i) => b.includes(el));
    return arraysEqual;
  }

  const handleSubmitEnableDisable = (latestValue) => {
    if (
      latestValue?.value?.length === 0 ||
      arraysEqual(initialValue?.emailDomains, latestValue?.value)
    )
      setManageEmailDomainsChanged(false);
    else setManageEmailDomainsChanged(true);
  };

  const checkErrorOnAddChip = (e) => {
    try {
      if (
        formik.errors['emailDomains']?.includes('Invalid email domain.') ||
        isDuplicate
      ) {
        if (e?.target?.value) {
          e.target.value = '';
        }
        return false;
      }
    } catch (err) {}
  };

  const onChipKeyDown = (e) => {
    try {
      if (e.code === 'Tab' || e.code === 'Comma') {
        e.preventDefault();
        // check any existing error or duplicate flag
        checkErrorOnAddChip(e);

        // add new value and set duplicate flag
        let newValue = e.target.value;
        if (newValue) {
          let duplicateValue = false;
          let chipValues = [...formik.values.emailDomains];
          chipValues.forEach((el) => {
            if (!duplicateValue) {
              if (el === newValue) {
                duplicateValue = true;
              }
            } else return;
          });
          setIsDuplicate(duplicateValue);

          //  clear input values
          e.target.value = '';

          chipValues.push(newValue);
          getFormErrorMessage('emailDomains');
          formik.setFieldValue('emailDomains', chipValues);
          handleSubmitEnableDisable({ value: chipValues });
        }
      }
    } catch (err) {}
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="modal-b"
        size="lg"
        centered
        backdrop="static"
      >
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}
        <Modal.Header closeButton>
          <span className="location-form-title">
            Allowed Email Domains{' '}
            {initialValue?.accountName && (
              <span className="add-edit-manage-email-domain-subtitle">
                {' '}
                ({initialValue?.accountName})
              </span>
            )}
            <div className="add-edit-manage-email-domain-info">
              Please enter the email domain in the following format '@' followed
              by domain name.
            </div>
          </span>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <div className="p-fluid chips-block">
              <label className="chip-label" htmlFor="emailDomains">
                Email Domains
              </label>
              <Chips
                placeholder="Press Enter, Comma or Tab to add"
                onKeyDown={onChipKeyDown}
                id="emailDomains"
                name="emailDomains"
                max={emailDomainLimit}
                className={
                  formik.errors['emailDomains'] || isDuplicate
                    ? 'invalid-chip'
                    : ''
                }
                onAdd={checkErrorOnAddChip}
                value={formik.values.emailDomains}
                onChange={(e) => {
                  // code for duplicate domains
                  const emailDomainSet = new Set();
                  e.value.map((x) => emailDomainSet.add(x));
                  if (emailDomainSet.size !== e.value.length)
                    setIsDuplicate(true);
                  else setIsDuplicate(false);

                  formik.handleChange(e);
                  getFormErrorMessage('emailDomains');
                  handleSubmitEnableDisable(e);
                }}
                pt={{
                  input: { autoComplete: 'off' },
                }}
              />
            </div>
            <Row className="mt-2">
              <Col className="text-start error-text">
                <div>Max limit - {emailDomainLimit}</div>
                {getFormErrorMessage('emailDomains')}
              </Col>
              <Col className="text-end">
                <button
                  type="submit"
                  className="btn btn-dark btn-opacity"
                  disabled={
                    !manageEmailDomainsChanged ||
                    formik.errors['emailDomains'] ||
                    isDuplicate
                  }
                >
                  {initialValue?.emailDomains?.length > 0 ? 'Update' : 'Save'}
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmailDomainsAddEdit;
