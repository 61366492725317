import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  Endpoints,
  ProductDeliveryPlatforms,
  ReduxAction,
  UserId,
} from '../../../constants';
import { useDispatch } from 'react-redux';
import httpService from '../../../services/http-service';
import ProductsDropdown from '../../Entitlements/ProductsDropdown';

const UserGroupEmailPreferences = ({
  contractNumber,
  entitlementMappingData,
  editData,
  onSaveClick,
}) => {
  //state variable declarations starts
  const [productRowData, setProductRowData] = useState();
  const [productDataset, setProductDataset] = useState();
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [availableMappings, setAvailableMappings] = useState([]);
  const [initialEntitlements, setInitialEntitlements] = useState([]);
  const [deselectRowsData, setDeselectRowsData] = useState([]);
  const [isEntitlementsChanged, setIsEntitlementsChanged] = useState(false);
  const [isFirstDataSelection, setIsFirstDataSelection] = useState(true);
  const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);
  const productEntitlementgridApi = useRef(null);
  const dispatch = useDispatch();
  //state variable declarations ends

  const onProductEntitlementGridReadyHandler = (params) => {
    productEntitlementgridApi.current = params;
    productEntitlementgridApi.current?.api.showLoadingOverlay();
  };

  const isAllSelect = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params?.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params?.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    suppressDragLeaveHidesColumns: true,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    overlayNoRowsTemplate: 'This user group has no entitlements',
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    headerCheckboxSelection: isAllSelect,
    checkboxSelection: isFirstColumn,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const validateEntitlementChange = () => {
    let currentlySelectedEntitlements =
      productEntitlementgridApi?.current?.api?.getSelectedRows();
    if (currentlySelectedEntitlements && initialEntitlements) {
      if (objectsEqual(currentlySelectedEntitlements, initialEntitlements)) {
        setIsEntitlementsChanged(false);
      } else {
        setIsEntitlementsChanged(true);
      }
    }
  };

  const setSelectionInGrid = () => {
    productEntitlementgridApi.current.api.selectAll();
    if (isFirstDataSelection) {
      setIsFirstDataSelection(false);
    }

    if (isRowDataLoaded && deselectRowsData?.length > 0) {
      productEntitlementgridApi?.current?.api?.forEachNode((node) => {
        if (
          deselectRowsData.some(
            (nd) => nd.productComponentId === node.data.productComponentId,
          )
        )
          node.setSelected(false);
      });
    }
    setDeselectRowsData([]);
    setIsRowDataLoaded(true);
    productEntitlementgridApi.current?.api.refreshCells();
    validateEntitlementChange();
  };

  const getPostEntitlementChanges = () => {
    try {
      let groupId = editData?.groupId;
      let entitlementChanges = [];
      if (groupId) {
        productEntitlementgridApi?.current?.api.forEachNode((node) => {
          // remove entitlements that aren't selected
          if (node.data?.isDeleted === 0 && !node.selected) {
            entitlementChanges.push({
              groupId: groupId,
              productcomponentid: node.data.productComponentId,
              isDeleted: 1,
            });
          }
          // add entitlements that are selected
          if (node.data?.isDeleted == undefined && node.selected) {
            entitlementChanges.push({
              groupId: groupId,
              productcomponentid: node.data.productComponentId,
              isDeleted: 0,
            });
          }
        });
      }
      return entitlementChanges;
    } catch (err) {}
  };

  //useEffects start
  useEffect(() => {
    // Controller to abort previous API calls if any ongoing flight
    const controller = new AbortController();
    const signal = controller.signal;
    if (editMode) {
      getContractProducts(signal);
    }
    return () => {
      controller?.abort();
    };
  }, [editMode]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (editData && entitlementMappingData) {
        let currentUserEntitlements = entitlementMappingData.filter(
          (ue) =>
            ue.contactId === editData.contactId &&
            ProductDeliveryPlatforms.includes(ue.distributor) &&
            ue.contractNumber === editData.contractNumber,
        );
        setInitialEntitlements(currentUserEntitlements);
        setProductRowData(currentUserEntitlements);
        dispatch({
          type: ReduxAction.updateUGProductEntitlement,
          payload: {
            initialProductEntitlements: currentUserEntitlements,
          },
        });
        if (currentUserEntitlements?.length === 0) {
          setIsRowDataLoaded(true);
          productEntitlementgridApi.current?.api.showNoRowsOverlay();
        } else productEntitlementgridApi.current?.api.hideOverlay();
      }
    }

    setEditMode(Boolean(editData));
    return () => {
      isMounted = false;
    };
  }, [entitlementMappingData, editData]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (
        (selectedProducts?.length > 0 || selectedDistributors?.length > 0) &&
        availableMappings
      ) {
        let matchedMappings = [];
        selectedProducts?.map((product) => {
          selectedDistributors?.map((distributor) => {
            let matchedRows = availableMappings.filter(
              (map) =>
                map.productId === product && map.distributorId === distributor,
            );
            if (matchedRows?.length > 0) {
              matchedMappings = matchedMappings.concat(matchedRows);
            }
          });
        });
        if (matchedMappings?.length > 0) {
          // filter out duplicate rows
          initialEntitlements.forEach((row) => {
            let nodeIndex = matchedMappings.findIndex(
              (nid) => nid.productComponentId === row.productComponentId,
            );
            if (nodeIndex !== -1) {
              matchedMappings.splice(nodeIndex, 1);
            }
          });
        }
        let updatedData = matchedMappings.concat(initialEntitlements);
        //set deselected rows from previous state
        let selectedRows =
          productEntitlementgridApi?.current?.api?.getSelectedRows();
        if (productRowData?.length > 0) {
          let deselectedRows =
            selectedRows?.length > 0
              ? productRowData?.filter(
                  (x) =>
                    !selectedRows.some(
                      (s) => s.productComponentId === x.productComponentId,
                    ),
                )
              : productRowData;
          setDeselectRowsData(deselectedRows);
        } else {
          setDeselectRowsData([]);
        }
        if (productRowData) {
          setProductRowData(updatedData);
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedProducts, selectedDistributors, availableMappings]);

  useEffect(() => {
    let isMounted = true;
    const reduceCallback = setTimeout(() => {
      if (productRowData && isMounted) {
        if (productRowData.length > 0 && isRowDataLoaded) {
          setSelectionInGrid();
        } else {
          if (
            productRowData.length === 0 &&
            initialEntitlements?.length === 0
          ) {
            setIsEntitlementsChanged(false);
          }
        }
      }
    }, 100);
    return () => {
      clearTimeout(reduceCallback);
      isMounted = false;
    };
  }, [productRowData]);
  //useeffect ends

  const getColumnClass = (params) => {
    if (
      params?.node?.data?.isDeleted == undefined &&
      params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'new-selected-entitlement-row';
    }
    if (
      params?.node?.data?.isDeleted != undefined &&
      params?.node?.data?.isDeleted === 0 &&
      !params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    } else {
      return '';
    }
  };

  const productEntitlementsColumns = [
    {
      headerName: '',
      field: 'id',
      resizable: true,
      filter: 'agTextColumnFilter',
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
    },
    {
      headerName: 'Packages',
      headerTooltip: 'Packages',
      field: 'packageName',
      tooltipField: 'packageName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Products',
      headerTooltip: 'Products',
      field: 'productName',
      tooltipField: 'productName',
      initialFlex: 2,
      cellClass: getColumnClass,
    },
  ];

  const productCompare = (current, next) => {
    if (current.productName < next.productName) {
      return -1;
    }
    if (current.productName > next.productName) {
      return 1;
    }
    return 0;
  };

  const getContractProducts = (signal) => {
    if (contractNumber) {
      let productDistributors = ProductDeliveryPlatforms?.map(
        (t) => '"' + t + '"',
      ).join(',');

      let queryParams = {
        pageSize: 5000,
        id: new Date().getTime(),
        filter: `contractNumber:"${contractNumber}" ${
          productDistributors
            ? 'AND distributor in (' + productDistributors + ')'
            : ''
        }`,
        Field:
          'productId,productName,packageName,productComponentId,distributorId,distributor,distributorPlatform,deliveryType,contractNumber',
      };
      httpService
        .get(Endpoints.contractProductsApi, queryParams, { signal })
        .then((res) => {
          if (res) {
            let productData = [];
            setAvailableMappings(res?.data?.results);
            const allProducts = res?.data?.results?.map((prd) => {
              return { productId: prd.productId, productName: prd.productName };
            });
            let uniqueProducts = allProducts.filter((value, index, self) => {
              return (
                self.findIndex((x) => x.productId === value.productId) === index
              );
            });
            uniqueProducts?.sort(productCompare);
            productData.results = uniqueProducts;
            setProductDataset(productData);

            const filteredDistributors = res?.data?.results.filter((item) =>
              ProductDeliveryPlatforms.includes(item.distributor),
            );
            const uniqueDistributorIds = [
              ...new Set(
                filteredDistributors.map((item) => item.distributorId),
              ),
            ];
            setSelectedDistributors(uniqueDistributorIds);
          }
        })
        .catch((err) => {
          if (err.code !== 'ERR_CANCELED') setProductDataset([]);
        });
    } else {
      setProductDataset([]);
    }
  };

  const callBackProducts = (params) => {
    let dsts = [];
    params?.forEach((dst) => {
      dsts.push(dst?.productId);
    });
    setSelectedProducts(dsts);
  };

  const onSelectionChanged = () => {
    productEntitlementgridApi.current?.api.refreshCells({ force: true });
    validateEntitlementChange();
    setTimeout(() => {
      let postData = getPostEntitlementChanges();

      dispatch({
        type: ReduxAction.updateUGProductEntitlement,
        payload: {
          productEntitlementsToSave: postData,
        },
      });
    }, 300);
  };

  const onFirstDataRenderedHandler = useCallback((params) => {
    setSelectionInGrid();
  }, []);
  return (
    <>
      <Container className="pt-2-5">
        <Row>
          <Col className="mb-2">
            <span className="email-preference-note-label">
              Please select CI Market Reports & Insights the user group shall
              receive via Email
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <ProductsDropdown
                DataSet={productDataset}
                callBackProducts={callBackProducts}
              />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <div className="ag-grid-modal-content">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              data={productRowData}
              columns={productEntitlementsColumns}
              onGridReady={onProductEntitlementGridReadyHandler}
              onSelectionChanged={onSelectionChanged}
              onFirstDataRendered={onFirstDataRenderedHandler}
            />
          </div>
        </Row>
        <Row className="modal-row">
          <div className="col-10 mt-4">
            <span className="email-preference-note-label">
              <span className="notes-asterisk-label">*</span>
              Please note that this user group can self-manage and customise
              Email alerts on Platts Connect
            </span>
          </div>
          <Form className="col-2">
            <Form.Group className=" user-save-btn-body">
              <button
                type="button"
                disabled={!isEntitlementsChanged}
                className="ug-save-btn user-save-btn btn btn-dark float-end btn-opacity"
                onClick={onSaveClick}
              >
                {editMode ? 'Update' : 'Save'}
              </button>
            </Form.Group>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default UserGroupEmailPreferences;
