import FileSaver from 'file-saver';
import { useFormik } from 'formik';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import * as Yup from 'yup';
import { fileSizeConverter } from '../../../helpers';
import httpService from '../../../services/http-service';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import DropdownInput from '../../../sharedcomponents/DropdownInput/DropdownInput';
import ModalBox from '../../../sharedcomponents/modal-box/ModalBox';
import { ReactComponent as BulkUploadIcon } from './../../../assets/icons/common/bulk-upload.svg';
import { ReactComponent as CloudBigIcon } from './../../../assets/icons/common/cloud-big-icon.svg';
import { ReactComponent as CrossIcon } from './../../../assets/icons/common/cross-icon.svg';
import {
  Endpoints,
  ReduxAction,
  UserId,
  Verbiage,
  maxPageSize,
  BulkUploadUsersTemplateType,
} from './../../../constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './bulk-upload-users.scss';

const BulkUploadUsers = ({
  showBulkUsers,
  handleCloseBulkUers,
  contracts,
  accounts,
  getUpdatedUsers,
}) => {
  const [initialValue, setInitialValue] = useState({
    accountId: '',
  });
  const gridRef = useRef(null);
  const successGridRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isErrorGridShow, setIsErrorGridShow] = useState(false);
  const [isSuccessGridShow, setIsSuccessGridShow] = useState(false);
  const [isUploadBoxShow, setIsUploadBoxShow] = useState(true);
  const [isEntitlementDisable, setIsEntitlementDisable] = useState(true);
  const [errorAnimation, setErrorAnimation] = useState('');
  const [errorData, setErrorData] = useState([]);
  const [uploadedUsers, setUploadedUsers] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sourceUsersGridStyle = { height: '35vh' };
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
  };
  const defaultColdef = {
    resizable: true,
    enableRowGroup: false,
    enablePivot: false,
    enableValue: false,
    headerCheckboxSelection: false,
    // checkboxSelection: isFirstColumn,
  };
  const isAllSelect = (params) => {
    let displayedColumns = params.api.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params.api.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };
  const defaultSuccessColdef = {
    enableRowGroup: false,
    enablePivot: false,
    enableValue: false,
    resizable: true,
    headerCheckboxSelection: isAllSelect,
    checkboxSelection: isFirstColumn,
  };

  const sourceUsersColumns = [
    {
      headerName: 'Email',
      field: 'email',
      tooltipField: 'email',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      tooltipField: 'firstName',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      tooltipField: 'lastName',
      initialFlex: 1,
      resizable: true,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'Office Location',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
      initialFlex: 1,
      resizable: true,
      // wrapText:true,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'Job Function',
      field: 'jobFunction',
      tooltipField: 'jobFunction',
      initialFlex: 1,
      resizable: true,
      // wrapText:true,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'Role',
      field: 'designation',
      tooltipField: 'designation',
      initialFlex: 1,
      resizable: true,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
    {
      headerName: 'Errors',
      field: 'error',
      tooltipField: 'error',
      initialFlex: 1,
      resizable: true,
      // wrapText:true,
      cellClass: (params) => {
        return 'error-bulk-upload-row';
      },
    },
  ];

  const sourceSuccessUsersColumns = [
    {
      headerName: 'Email',
      field: 'email',
      tooltipField: 'email',
      initialFlex: 1,
      resizable: true,
      cellClass: (params) => {
        if (params.node.selected) {
          return 'uploaded-selected-user-row';
        }
      },
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      tooltipField: 'firstName',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        if (params.node.selected) {
          return 'uploaded-selected-user-row';
        }
      },
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      tooltipField: 'lastName',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        if (params.node.selected) {
          return 'uploaded-selected-user-row';
        }
      },
    },
    {
      headerName: 'Office Location',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
      resizable: true,
      initialFlex: 1,
      // wrapText:true,
      cellClass: (params) => {
        if (params.node.selected) {
          return 'uploaded-selected-user-row';
        }
      },
    },
    {
      headerName: 'Job Function',
      field: 'jobFunction',
      tooltipField: 'jobFunction',
      resizable: true,
      initialFlex: 1,
      // wrapText:true,
      cellClass: (params) => {
        if (params.node.selected) {
          return 'uploaded-selected-user-row';
        }
      },
    },
    {
      headerName: 'Role',
      field: 'designation',
      tooltipField: 'designation',
      resizable: true,
      initialFlex: 1,
      cellClass: (params) => {
        if (params.node.selected) {
          return 'uploaded-selected-user-row';
        }
      },
    },
  ];

  useEffect(() => {
    formik.setFieldValue('accountId', accounts[0]?.accountId);
  }, [accounts]);

  const getRowError = (rowData) => {
    let rowError = '';
    if (rowData) {
      let fieldErrorDetails = [
        {
          field: 'first name',
          errorType: ['required', 'max', 'text'],
          maxLength: 80,
        },
        {
          field: 'last name',
          errorType: ['required', 'max', 'text'],
          maxLength: 80,
        },
        {
          field: 'email',
          errorType: ['required', 'max', 'email'],
          maxLength: 40,
        },
        {
          field: 'office location',
          errorType: ['required', 'dropDown'],
          dropDownIndex: 75,
        },
        {
          field: 'job function',
          errorType: ['required', 'dropDown'],
          dropDownIndex: 76,
        },
        {
          field: 'role',
          errorType: ['required', 'dropDown'],
          dropDownIndex: 77,
        },
      ];
      for (let row = 0; row < fieldErrorDetails.length; row++) {
        fieldErrorDetails[row].errorType.forEach((x) => {
          switch (x) {
            case 'required':
              if (!rowData[row])
                rowError =
                  rowError + `Please enter ${fieldErrorDetails[row].field}. `;
              break;
            case 'max':
              if (
                rowData[row] &&
                rowData[row].length > fieldErrorDetails[row].maxLength
              )
                rowError =
                  rowError +
                  `${fieldErrorDetails[row].field} must be ${fieldErrorDetails[row].maxLength} characters or less. `;
              break;
            case 'text':
              if (rowData[row] && !String(rowData[row]).match(/^[a-zA-Z ]*$/))
                rowError =
                  rowError +
                  `No numerical or symbolic values are accepted in ${fieldErrorDetails[row].field}. `;
              break;
            case 'email':
              if (
                rowData[row] &&
                !String(rowData[row]).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
              )
                rowError =
                  rowError +
                  `Please enter valid ${fieldErrorDetails[row].field}. `;
              break;
            case 'dropDown':
              if (
                rowData[row] &&
                (!rowData[fieldErrorDetails[row].dropDownIndex] ||
                  rowData[fieldErrorDetails[row].dropDownIndex].includes(
                    '#ERROR',
                  ))
              )
                rowError =
                  rowError +
                  `Please enter valid ${fieldErrorDetails[row].field}. `;
              break;
            default:
          }
        });
      }
    }
    return rowError;
  };
  const validateBulkData = (excelData) => {
    let errorsDetails = [];
    let correctData = [];
    let i;

    for (i = 0; i < excelData.length; i++) {
      let error = getRowError(excelData[i]);
      if (error) {
        let errorModel = {
          firstName: excelData[i][0],
          lastName: excelData[i][1],
          email: excelData[i][2],
          officeLocation: excelData[i][3],
          jobFunction: excelData[i][4],
          designation: excelData[i][5],
          error: error,
        };
        errorsDetails.push(errorModel);
      } else {
        let user = {
          contactId: '-1',
          firstName: excelData[i][0],
          lastName: excelData[i][1],
          email: excelData[i][2],
          officeLocation: excelData[i][3],
          jobFunction: excelData[i][4],
          designation: excelData[i][5],
          officeLocationId: excelData[i][75],
          isDeleted: 0,
          accountId: formik.values.accountId,
          userid: UserId,
          department: '',
          phone: '',
          isEmailOptOut: 0,
          emailProcessed: '',
        };
        correctData.push(user);
      }
    }
    setErrorData(errorsDetails);

    return [correctData, errorsDetails];
  };

  const getSuccessOrErrorMessage = (uploadedUsers, errorsData) => {
    let successMessage = '';
    successMessage =
      (uploadedUsers?.length > 0
        ? uploadedUsers.length +
          (uploadedUsers.length === 1
            ? Verbiage.singleUser
            : Verbiage.multipleUser) +
          ' uploaded successfully'
        : '') +
      (uploadedUsers?.length > 0 && errorsData?.length > 0 ? ' and ' : '') +
      (errorsData?.length > 0
        ? errorsData?.length +
          (errorsData.length === 1
            ? Verbiage.singleUser
            : Verbiage.multipleUser) +
          ' upload has failed'
        : '');
    return successMessage;
  };

  const uploadUsers = (usersData, errorsData) => {
    let successMessage = '';
    setLoading(true);
    httpService
      .post(Endpoints.userApi, usersData)
      .then((resp) => {
        let uploadedUsers = resp?.data?.results.filter(
          (user) =>
            user.contactId !== '-1' &&
            user.contactId !== '-2' &&
            user.contactId !== '-3',
        );
        let errorUsers = resp?.data?.results.filter(
          (user) =>
            user.contactId === '-1' ||
            user.contactId === '-2' ||
            user.contactId === '-3',
        );

        if (errorUsers?.length > 0 || errorsData?.length > 0) {
          errorUsers.forEach((user) => {
            let errorModel = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              officeLocation: user.officeLocation,
              jobFunction: user.jobFunction,
              designation: user.designation,
              error:
                user.contactId === '-2'
                  ? 'User already exists.'
                  : user.contactId === '-3'
                  ? 'Invalid email domain.'
                  : 'Error in uploading the user.',
            };
            errorsData.push(errorModel);
          });
          setErrorData([]);
          setErrorData(errorsData);
          successMessage = getSuccessOrErrorMessage(uploadedUsers, errorsData);
        }
        if (uploadedUsers?.length > 0) {
          if (!successMessage) {
            successMessage = getSuccessOrErrorMessage(
              uploadedUsers,
              errorsData,
            );
          }
          setIsSuccessGridShow(true);
          setUploadedUsers(uploadedUsers);
          getUpdatedUsers();
        }
        if (errorsData?.length === 0) {
          getUpdatedUsers();
        }
        setSuccessMessage(successMessage);

        if (errorsData?.length > 0) setIsErrorGridShow(true);
      })
      .catch((err) => {
        let errorDetails = errorsData;
        if (usersData?.length === 1) {
          let errorModel = {
            firstName: usersData[0].firstName,
            lastName: usersData[0].lastName,
            email: usersData[0].email,
            officeLocation: usersData[0].officeLocation,
            jobFunction: usersData[0].jobFunction,
            designation: usersData[0].designation,
            error:
              err.response.data.resultCode === '-2'
                ? 'User already exists.'
                : err.response.data.resultCode === '-3'
                ? 'Invalid email domain.'
                : 'Error in uploading the user.',
          };
          errorDetails.push(errorModel);
        }
        setErrorData(errorDetails);
        setSuccessMessage(
          errorDetails?.length +
            (errorDetails.length === 1
              ? Verbiage.singleUser
              : Verbiage.multipleUser) +
            ' upload has failed',
        );
        if (errorDetails && errorDetails.length > 0) setIsErrorGridShow(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (uploadedUsers?.length > 0) {
      setTimeout(() => {
        successGridRef?.current?.api?.forEachNode((node) => {
          node.setSelected(true);
        });
        successGridRef?.current?.api?.refreshCells();
        setIsEntitlementDisable(true);
      }, 100);
    }
  }, [uploadedUsers]);

  const validateHeader = (firstRow) => {
    return (
      firstRow.filter((c) => c != null).join(',') ===
      'First Name,Last Name,Email Address,Office Location Name,Job Function,Role'
    );
  };
  const validateFile = () => {
    if (files && files[0]) {
      return true;
    } else {
      setFileErrorMessage('Please upload a file');
      return false;
    }
  };
  const validateFileData = (data) => {
    if (data && data.length > 0 && validateHeader(data[0])) {
      if (data.length > 1) {
        return true;
      } else {
        setFileErrorMessage('No data to upload');
        return false;
      }
    } else {
      setFileErrorMessage('Please upload a valid file');
      return false;
    }
  };
  const uploadBulkUsers = () => {
    if (formik.values.accountId) {
      if (validateFile()) {
        try {
          readXlsxFile(files[0])
            .then((rows) => {
              if (validateFileData(rows)) {
                setIsUploadBoxShow(false);
                setFileErrorMessage('');
                let excelRows = rows.filter(
                  (r) =>
                    r[75] && (r[0] || r[1] || r[2] || r[3] || r[4] || r[5]),
                );
                if (excelRows && excelRows.length > 0) {
                  let validatedData = validateBulkData(excelRows);
                  if (validatedData[0] && validatedData[0].length > 0)
                    uploadUsers(validatedData[0], validatedData[1]);
                  else if (validatedData[1] && validatedData[1].length > 0) {
                    setSuccessMessage(
                      validatedData[1].length +
                        (validatedData[1].length === 1
                          ? Verbiage.singleUser
                          : Verbiage.multipleUser) +
                        ' upload has failed',
                    );
                    setIsErrorGridShow(true);
                  }
                } else {
                  setFileErrorMessage('No data to upload');
                }
              }
            })
            .catch((error) => {
              setFileErrorMessage('No data to upload');
            });
        } catch (err) {
          setFileErrorMessage('Please upload a valid file');
        }
      }
    } else {
      formik.setFieldTouched('accountId');
    }
  };
  const onSuccessUserGridReadyHandler = (params) => {
    successGridRef.current = params;
    params.api.sizeColumnsToFit(
      'email,firstName,lastName,officeLocation,jobFunction,designation',
      false,
    );
  };
  const onSourceUserGridReadyHandler = (params) => {
    gridRef.current = params;
    params.api.sizeColumnsToFit(
      'email,firstName,lastName,officeLocation,jobFunction,designation,error',
      false,
    );
  };
  const onUploadModalClose = async (isUpdateUsers = false) => {
    await setInitialValue({
      accountId: '',
    });

    handleCloseBulkUers(isUpdateUsers);
  };
  // const AccountHandler = (e) => {
  //   const { value } = e.target;
  //   formik.handleChange(e);
  // };
  // const createDropdownOptions = (menuData, menuDataID, menuDataLabel) => {
  //   try {
  //     let options = [];
  //     options.push(
  //       <option className="m-0 user-dd-select-menu" value="" key={-1}>
  //         Select Account
  //       </option>,
  //     );
  //     options = [
  //       ...options,
  //       menuData?.map((obj, i) => {
  //         return (
  //           <option
  //             className="m-0 user-dropdown-menu"
  //             key={i}
  //             value={obj[menuDataID] ?? obj}
  //           >
  //             {obj[menuDataLabel] ?? obj}
  //           </option>
  //         );
  //       }),
  //     ];
  //     return options;
  //   } catch (err) {}
  // };
  const validate = Yup.object({
    accountId: Yup.string().required('Please select account to proceed'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
  });

  const fetchDataAndDownloadExcel = async () => {
    if (formik.values.accountId) {
      let filteredContracts = contracts.filter(
        (contract) => contract.checked === true,
      );
      let contractFilters = filteredContracts
        .map((contract) => `contractNumber:"${contract.id}"`)
        .join(' OR ');
      const dbFilter = `accountId:"${formik.values.accountId}"${
        filteredContracts.length > 0 ? ` AND (${contractFilters})` : ''
      }`;
      let headersParams = {
        pageSize: maxPageSize,
        filter: dbFilter,
        templateType: BulkUploadUsersTemplateType,
      };
      let queryParams = {
        responseType: 'blob',
      };
      await httpService
        .post(Endpoints.bulkUploadTemplateApi, {}, headersParams, queryParams)
        .then(({ data }) => {
          FileSaver.saveAs(data, 'SFS_Bulk_Upload_Users.xlsx');
        })
        .catch((err) => {});
    } else {
      formik.setFieldTouched('accountId');
    }
  };
  // Bulk upload drag and drop code
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...acceptedFiles]);
    },
    [files],
  );

  const removeFile = () => {
    setFiles([]);
    setIsErrorGridShow(false);
    setIsSuccessGridShow(false);
    setIsEntitlementDisable(true);
    setErrorData([]);
    setFileErrorMessage('');
    setSuccessMessage('');
    setIsUploadBoxShow(true);
  };
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.slk', '.xla', '.xlt', '.xlw'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      // 'text/csv': ['.csv'],
    },
    maxFiles: 1,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <div key={file.path} className="file-name">
      <span className="truncate">{file.path}</span>{' '}
      <span className="fw-bolder align-top">
        ({fileSizeConverter(file.size)})
      </span>
      <span>
        <CrossIcon
          onClick={removeFile}
          className="cross-icon"
          alt="Close"
          title="Close"
        ></CrossIcon>
      </span>
    </div>
  ));
  // Bulk upload drag and drop code ends here

  const addUserEntitlement = () => {
    let selectUsers = successGridRef.current.api.getSelectedRows();
    if (selectUsers?.length > 0) {
      dispatch({
        type: ReduxAction.selectedEntitlementUser,
        payload: selectUsers,
      });
      navigate('/add-entitlements');
    }
  };

  const onSelectionChanged = () => {
    successGridRef.current.api.refreshCells();
    if (successGridRef?.current?.api?.getSelectedRows()?.length === 0)
      setIsEntitlementDisable(true);
    else setIsEntitlementDisable(false);
  };

  const onChangeHandler = (val) => {
    formik.setFieldValue('accountId', val.accountId);
  };

  return (
    <>
      <ModalBox
        backdrop="static"
        size="lg"
        show={showBulkUsers}
        handleClose={onUploadModalClose}
        modalTitle="Bulk Upload Users"
        showLoader={loading}
      >
        <Row>
          <Col>
            <label className="user-form-label form-label">Account</label>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <div
              onClick={() => {
                if (accounts?.length > 0) {
                  fetchDataAndDownloadExcel();
                } else {
                  setErrorAnimation('shake');
                  setTimeout(() => setErrorAnimation(''), 1000);
                }
              }}
            >
              <BulkUploadIcon
                className="me-1 cursor-pointer bulk-upload-download-icon"
                alt="Bulk Upload Users"
                title="Bulk Upload Users"
              ></BulkUploadIcon>
              <span className="bulk-upload-text">
                <span>BULK UPLOAD TEMPLATE</span>
              </span>
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            {formik.values.accountId !== '' && accounts?.length > 0 ? (
              <DropdownInput
                name="accountId"
                targetValue="accountId"
                dropdownOptions={accounts}
                formik={formik}
                ddPlaceHolder={
                  formik.values.accountId !== '' && accounts?.length > 0
                    ? accounts?.filter(
                        (el) => el.accountId === formik.values.accountId,
                      )[0]?.accountName
                    : 'Select'
                }
                labelKeyName="accountName"
                ddName={'account'}
                onChangeHandler={(e) => onChangeHandler(e, 'accountId')}
              />
            ) : (
              <label className={`label-red ${errorAnimation}`}>
                Select contracts with associated accounts
              </label>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {isUploadBoxShow && (
              <div
                className={`${
                  isDragAccept
                    ? 'upload-box-border-blue'
                    : isDragReject
                    ? 'upload-box-border-red'
                    : ''
                } text-center upload-box`}
                {...getRootProps({
                  onClick: removeFile,
                })}
              >
                <span>
                  <input {...getInputProps()} />
                  <CloudBigIcon
                    className="mb-2"
                    alt="Bulk Upload Users"
                    title="Bulk Upload Users"
                  ></CloudBigIcon>
                  <br />
                  Please download the bulk upload template from the link above
                  and upload here.
                  <br />
                  <span className="non-italic"> Upload XLSX</span>
                </span>
              </div>
            )}
            {files.length > 0 && (
              <div className={`grid-wrapper ${isUploadBoxShow ? 'mt-3' : ''}`}>
                {files.length > 0 ? (
                  <>
                    <div>
                      {acceptedFileItems}

                      {/* {showProgress ? <ProgressBar now={countOfProgess} /> : ''} */}
                    </div>
                    {/* {showProgress ? (
                      <div className="percentage-block">
                        <span className="percentage-text">
                          {parseInt(countOfProgess)} %
                        </span>
                      </div>
                    ) : (
                      ''
                    )} */}
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs lg="8">
            <div className="error-message-text">{fileErrorMessage}</div>
            <div className="error-message-text">{successMessage}</div>
          </Col>
          <Col xs lg="4">
            {isUploadBoxShow && (
              <button
                className="btn btn-dark upload-btn btn-opacity float-end"
                disabled={!files.length > 0}
                onClick={uploadBulkUsers}
              >
                UPLOAD
              </button>
            )}
            {isSuccessGridShow && (
              <button
                className="btn btn-dark upload-btn btn-opacity float-end"
                disabled={isEntitlementDisable}
                onClick={addUserEntitlement}
              >
                ADD ENTITLEMENTS
              </button>
            )}
          </Col>
        </Row>
        {isSuccessGridShow && (
          <div>
            <Row>
              <Col>
                <AgGridComponent
                  config={defaultConfig}
                  defaultColumnDef={defaultSuccessColdef}
                  data={uploadedUsers}
                  columns={sourceSuccessUsersColumns}
                  onSelectionChanged={onSelectionChanged}
                  gridStyle={sourceUsersGridStyle}
                  onGridReady={onSuccessUserGridReadyHandler}
                />
              </Col>
            </Row>
          </div>
        )}
        {isErrorGridShow && (
          <div>
            <Row>
              <Col>
                <AgGridComponent
                  config={defaultConfig}
                  defaultColumnDef={defaultColdef}
                  data={errorData}
                  columns={sourceUsersColumns}
                  // onSelectionChanged={onSelectionChanged}
                  gridStyle={sourceUsersGridStyle}
                  onGridReady={onSourceUserGridReadyHandler}
                />
              </Col>
            </Row>
          </div>
        )}
      </ModalBox>
    </>
  );
};

export default memo(BulkUploadUsers);
