import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';
// import { ReduxAction } from './../../constants';
import { ADD_CONTRACT } from './../../redux/actions';
// Basic component with logout button
const LogoutButton = () => {
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.clear();
    oktaAuth.signOut().then(() => {
      // dispatch({ type: ReduxAction.userInfoSet, payload: {} });
      dispatch({ type: ADD_CONTRACT, payload: {} });
    });
  };

  return (
    <div>
      <div
        id="logout_option"
        className="option-list-item-text"
        onClick={logout}
      >
        Logout
      </div>
    </div>
  );
};

export default LogoutButton;
