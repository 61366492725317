import React, { memo, useEffect, useState } from 'react';
import { Endpoints } from './../../constants';
import HttpService from './../../services/http-service';
import TreeMultiSelectDropdown from '../../sharedcomponents/tree-multi-select-dropdown/tree-multi-select-dropdown';
import { createTreeData } from '../../helpers/create-tree-json';

const PackagesDropdown = memo(
  ({ contracts, DataSet, callBackPackages, disabled = false }) => {
    const [packagesResult, setPackagesResult] = useState([]);
    const [productData, setProductData] = useState();
    const parentKeys = ['packageCategory', 'packageLabel'];

    useEffect(() => {
      getPackagesDataSet();
    }, [contracts, DataSet]);

    const createKeyValuePair = (data = []) => {
      let newArr = data?.map((el) => {
        let arr = el?.packageName?.split(' - ', 2);
        let obj = { ...el, packageCategory: arr[0], packageLabel: arr[1] };
        return obj;
      });
      return newArr;
    };

    const getPackagesDataSet = async () => {
      try {
        if (!DataSet) {
          if (contracts?.length > 0 && contracts.some((el) => el.checked)) {
            let queryParamsPackage = {
              PageSize: 5000,
              Field: 'packageName,packageId',
              Filter: `contractNumber in(${contracts
                .filter((x) => x.checked)
                .map((t) => (t.id ? '"' + t.id + '"' : ''))
                .join(',')})`,
            };

            HttpService.get(Endpoints.contractPackagesApi, queryParamsPackage)
              .then((res) => {
                let tempState = res?.data?.results;
                tempState = tempState?.map((el, i) => {
                  return { id: i, checked: false, ...el };
                });

                // Create tree structured json
                let keyValuePair = createKeyValuePair(tempState);
                let treeData = createTreeData(keyValuePair, parentKeys);
                let sortedTreeData = treeData?.sort((a, b) =>
                  a.label > b.label ? 1 : -1,
                );
                setProductData(sortedTreeData ?? []);
                setPackagesResult(tempState ?? []);
              })
              .catch((err) => {
                setProductData([]);
                setPackagesResult([]);
              });
          } else {
            setProductData();
            setPackagesResult([]);
          }
        } else {
          if (DataSet) {
            let tempState = DataSet?.results?.map((el, i) => {
              return { id: i, checked: false, ...el };
            });

            // Create tree structured json
            let keyValuePair = createKeyValuePair(tempState);
            let treeData = createTreeData(keyValuePair, parentKeys);
            let sortedTreeData = treeData?.sort((a, b) =>
              a.label > b.label ? 1 : -1,
            );
            setProductData(sortedTreeData ?? []);
            setPackagesResult(tempState ?? []);
          }
        }
      } catch (err) {}
    };

    const handleSelection = (selectedTreeData) => {
      let tempData = packagesResult?.map((obj) => {
        obj.checked = selectedTreeData?.includes(String(obj?.id));
        return obj;
      });
      setPackagesResult(tempData);
    };

    useEffect(() => {
      const updatedState = packagesResult
        ?.filter((packageData) => packageData.checked === true)
        ?.map((filterPackageData) => {
          return {
            packageName: filterPackageData.packageName,
            packageId: filterPackageData.packageId,
          };
        });
      callBackPackages(updatedState);
    }, [packagesResult]);

    const count = packagesResult.reduce(
      (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
      0,
    );
    return (
      <TreeMultiSelectDropdown
        ddOptions={productData}
        getSelectedTreeData={handleSelection}
        enableSearch
        enableSelectAll
        ddPlaceHolder={
          <>
            Packages
            <span className={count === 0 ? 'd-none' : 'd-inline'}>
              ({count})
            </span>
          </>
        }
        disabled={disabled}
      />
    );
  },
  (op, np) => op?.contracts === np?.contracts && op?.DataSet === np?.DataSet,
);

export default PackagesDropdown;
