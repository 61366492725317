import React from 'react';
import { Form } from 'react-bootstrap';
import cn from './../../helpers/cn';

const TextAreaInput = ({
  formLabel,
  formik,
  addAsterisk,
  inputClass,
  labelClass,
  formGroupClass,
  name,
  ...props
}) => {
  const Asterisk = () =>
    addAsterisk ? <span className="asterik">*</span> : null;

  const FormikError = () =>
    formik.touched[name] && formik.errors[name] ? (
      <div className="form-input-error">{formik.errors[name]}</div>
    ) : null;

  return (
    <Form.Group className={cn('col-6 mb-2', formGroupClass)}>
      <Form.Label className={cn('user-form-label', labelClass)}>
        {formLabel}
      </Form.Label>
      <Asterisk />
      <Form.Control
        type="text"
        as="textarea"
        rows={3}
        className={cn('user-form-input', inputClass)}
        {...props}
      />
      <FormikError />
    </Form.Group>
  );
};

export default React.memo(TextAreaInput);
