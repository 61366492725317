const initialState = {};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_CONTRACTS': {
      return {
        ...state,
        contracts: action.payload,
      };
    }
    default:
      return state;
  }
};
export default contractReducer;
