import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useRef } from 'react';
import './AgGrid.css';
import { filterOptions } from './ag-grid-helper';

// Works for Ag Grid Version 31.0.2
const AgGridComponent = ({
  columns,
  data,
  config = {},
  defaultColumnDef = {},
  gridStyle = {},
  autoGroupColumnDef = {},
  getRowId,
  onGridReady,
  onCellClicked,
  onSelectionChanged,
  onFirstDataRendered,
  onRowSelected,
  onColumnVisible,
  isRowSelectable,
  getChildCount,
  onCellValueChanged,
  onCellEditingStarted,
}) => {
  const gridRef = useRef();

  const defaultColumnDefValue = {
    editable: false,
    filter: true,
    sortable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filterParams: {
      filterOptions,
      buttons: ['reset', 'apply'],
      maxNumConditions: 1,
    },
  };

  const configValue = {
    isExportCSV: true,
    isExportExcel: true,
    csvSeparator: ',',
    isAnimateRows: true,
    rowGroupPanelShow: 'always',
    pivotPanelShow: 'always',
    rowSelection: 'multiple',
    overlayNoRowsTemplate: 'No Data Available',
    overlayLoadingTemplate: 'Please wait while your data is loading',
    enableRangeSelection: true,
    enableBrowserTooltips: true,
    allowContextMenuWithControlKey: true,
    enableCharts: true,
    rowModelType: 'serverSide',
    // infiniteInitialRowCount: 1000,
    pagination: true,
    paginationPageSize: 20,
    suppressCsvExport: false,
    suppressExcelExport: false,
    serverSideStoreType: 'partial',
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
      ],
    },
    cacheBlockSize: 100,
    maxBlocksInCache: 500,
    suppressDragLeaveHidesColumns: true,
    suppressRowClickSelection: true,
    suppressMultiRangeSelection: false,
    groupMultiAutoColumn: true,
    groupSelectsChildren: true,
    groupSelectsFiltered: true,
    groupDisplayType: 'multipleColumns',
    stopEditingWhenCellsLoseFocus: true,
  };

  const defaultGridStyle = { height: 600 };

  const getContextMenuItems = () => ['copy', 'copyWithHeaders', 'paste'];
  config = {
    ...configValue,
    ...config,
    // apply column menu on right side bar of grid
    sideBar: config.sideBar ? { ...configValue.sideBar } : config.sideBar,
  };

  if (config?.rowModelType === 'clientSide') {
    delete config.cacheBlockSize;
    delete defaultColumnDefValue.filterParams.filterOptions;
  }
  defaultColumnDef = { ...defaultColumnDefValue, ...defaultColumnDef };

  gridStyle = { ...defaultGridStyle, ...gridStyle };

  const getMainMenuItems = useCallback((params) => {
    // Remove Row group by option from filter menu
    const menuItems = [];
    const itemsToExclude = ['rowGroup', 'pinSubMenu'];
    params?.defaultItems?.forEach((item) => {
      if (itemsToExclude.indexOf(item) < 0) {
        menuItems.push(item);
      }
    });

    return menuItems;
  }, []);

  return (
    <div className="ag-theme-alpine" style={gridStyle}>
      <AgGridReact
        ref={gridRef}
        defaultColDef={defaultColumnDef}
        rowData={data}
        columnDefs={columns}
        rowModelType={config.rowModelType}
        sideBar={config.sideBar}
        enableBrowserTooltips={config.enableBrowserTooltips}
        rowGroupPanelShow={config.rowGroupPanelShow}
        pivotPanelShow={config.pivotPanelShow}
        getRowId={getRowId}
        animateRows={config.isAnimateRows}
        overlayNoRowsTemplate={config.overlayNoRowsTemplate}
        overlayLoadingTemplate={config.overlayLoadingTemplate}
        enableRangeSelection={config.enableRangeSelection}
        allowContextMenuWithControlKey={config.allowContextMenuWithControlKey}
        enableCharts={config.enableCharts}
        pagination={config.pagination}
        paginationPageSize={config.paginationPageSize}
        cacheBlockSize={config.cacheBlockSize}
        maxBlocksInCache={config.maxBlocksInCache}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        suppressCsvExport={config.suppressCsvExport}
        suppressExcelExport={config.suppressExcelExport}
        onCellClicked={onCellClicked}
        rowSelection={config.rowSelection}
        suppressRowClickSelection={config.suppressRowClickSelection}
        suppressMultiRangeSelection={config.suppressMultiRangeSelection}
        autoGroupColumnDef={autoGroupColumnDef}
        suppressDragLeaveHidesColumns={config.suppressDragLeaveHidesColumns}
        getContextMenuItems={getContextMenuItems}
        groupMultiAutoColumn={config.groupMultiAutoColumn}
        getMainMenuItems={getMainMenuItems}
        onFirstDataRendered={onFirstDataRendered}
        getChildCount={getChildCount}
        onRowSelected={onRowSelected}
        onColumnVisible={onColumnVisible}
        groupDisplayType={config.groupDisplayType}
        groupSelectsChildren={config.groupSelectsChildren}
        groupSelectsFiltered={config.groupSelectsFiltered}
        stopEditingWhenCellsLoseFocus={config.stopEditingWhenCellsLoseFocus}
        isRowSelectable={isRowSelectable}
        onCellValueChanged={onCellValueChanged}
        onCellEditingStarted={onCellEditingStarted}
      />
    </div>
  );
};

export default AgGridComponent;
