import React, {
  memo,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  ProductDeliveryPlatforms,
  ReduxAction,
  UserId,
  Endpoints,
  GenericError,
  Active,
  PendingActive,
  PendingApproval,
  Cancelled,
  Rejected,
  PendingInactive,
} from './../../../constants';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import httpService from '../../../services/http-service';
import DistributorsDropdown from './../../Entitlements/DistributorsDropdown';
import PackagesDropdown from './../../Entitlements/PackagesDropdown';

const UserEntitlements = memo(
  ({
    editMode,
    editData,
    distributorDataSet,
    entitlementMappingData,
    getEntitlementMappings,
    getUsers,
    onAddEditModalClose,
    formik,
    initialValue,
    userEntitlementsData,
    packageDataSet,
    getAllUserEntitlements,
    setLoading,
    setActiveTab,
    setIsUserIdEnabled,
  }) => {
    //state variable declarations starts
    const gridApi = useRef(null);
    const [rowData, setRowData] = useState();
    const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);
    const [initialEntitlements, setInitialEntitlements] = useState([]);
    const [entitlementsChanged, setEntitlementsChanged] = useState(false);
    const [deselectRowsData, setDeselectRowsData] = useState([]);

    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedDistributors, setSelectedDistributors] = useState([]);
    const [selectedDistributorsId, setSelectedDistributorsId] = useState([]);

    const [initialEntitlementIds, setInitialEntitlementIds] = useState([]);
    const [currentlySelectedEntitlements, setCurrentlySelectedEntitlements] =
      useState([]); // set value for default selection

    const [filteredPackages, setFilteredPackages] = useState();
    const [filteredDistributors, setFilteredDistributors] = useState();
    const [selectedParentCascade, setSelectedParentCascade] = useState();
    const [isFirstDataSelection, setIsFirstDataSelection] = useState(true);
    const dispatch = useDispatch();
    //state variable declarations ends

    const defaultConfig = {
      rowModelType: 'clientSide',
      isExportCSV: false,
      isExportExcel: false,
      pivotPanelShow: '',
      suppressDragLeaveHidesColumns: true,
      pagination: false,
      isAutoSizeColumns: true,
      enableCharts: false,
      rowGroupPanelShow: false,
      sideBar: false,
      overlayNoRowsTemplate: 'This user has no entitlements',
    };

    const isFirstColumn = (params) => {
      let displayedColumns = params.api.getAllDisplayedColumns();
      let thisIsFirstColumn = displayedColumns[0] === params.column;
      if (thisIsFirstColumn) {
        return thisIsFirstColumn;
      }
    };
    const defaultColdef = {
      sortable: true,
      resizable: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: editData?.groupId ? false : isFirstColumn,
      checkboxSelection: editData?.groupId ? false : isFirstColumn,
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
    };
    const getColumnClass = (params) => {
      if (
        (params?.node?.data?.status === Cancelled ||
          params?.node?.data?.status === PendingInactive ||
          params?.node?.data?.status === Rejected) &&
        !isRowDataLoaded
      ) {
        return 'unselected-entitlement-row';
      }

      if (
        (!params?.node?.data?.status ||
          params?.node?.data?.status === Cancelled ||
          params?.node?.data?.status === PendingInactive ||
          params?.node?.data?.status === Rejected) &&
        params?.node?.selected &&
        isRowDataLoaded
      ) {
        return 'new-selected-entitlement-row';
      }
      if (
        params?.node?.data?.status &&
        !params?.node?.selected &&
        isRowDataLoaded
      ) {
        return 'unselected-entitlement-row';
      } else {
        return '';
      }
    };

    const distributorGetter = (params) => {
      if (params?.data)
        return (
          params?.data?.distributor +
          ' : ' +
          params?.data?.distributorPlatform +
          ' (' +
          params?.data?.deliveryType +
          ')'
        );

      return '';
    };
    const entitlementsColumns = [
      {
        headerName: '',
        field: 'id',
        resizable: true,
        filter: 'agTextColumnFilter',
        suppressHeaderMenuButton: true,
        // suppressCellSelection: true,
        sortable: false,
        hide: true,
      },
      {
        headerName: 'Packages',
        headerTooltip: 'Packages',
        field: 'packageName',
        tooltipField: 'packageName',
        initialFlex: 3,
        cellClass: getColumnClass,
      },
      {
        headerName: 'Delivery Platform (Type)',
        headerTooltip: 'Delivery Platform (Type)',
        field: 'deliveryPlatform',
        initialFlex: 3,
        valueGetter: distributorGetter,
        tooltipValueGetter: distributorGetter,
        cellClass: getColumnClass,
      },
      {
        headerName: 'Status',
        headerTooltip: 'Status',
        field: 'status',
        tooltipField: 'status',
        initialFlex: 2,
        cellClass: getColumnClass,
      },
    ];
    const objectsEqual = (o1, o2) =>
      Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p]);

    const getPostEntitlementChanges = () => {
      let contactId =
        initialValue.contactId !== '' ? initialValue.contactId : -1;
      let entitlementChanges = [];

      gridApi.current.api.forEachNode((node) => {
        // remove entitlements that have a status and aren't selected
        if (
          node.data?.status &&
          (node.data?.status === Active ||
            node.data?.status === PendingActive ||
            node.data?.status === PendingApproval) &&
          !node.selected
        ) {
          entitlementChanges.push({
            contactid: contactId,
            productcomponentid: node.data.productComponentId,
            status: PendingInactive,
            contractNumber: node.data.contractNumber,
            distributor: node.data.distributor,
            distributorId: node.data.distributorId,
          });
        }
        // add entitlements that don't have a status and are selected
        if (
          (!node.data?.status ||
            node.data?.status === Cancelled ||
            node.data?.status === PendingInactive ||
            node.data?.status === Rejected) &&
          node.selected
        ) {
          entitlementChanges.push({
            contactid: contactId,
            productcomponentid: node.data.productComponentId,
            status: PendingActive,
            contractNumber: node.data.contractNumber,
            distributor: node.data.distributor,
            distributorId: node.data.distributorId,
          });
        }
      });
      return entitlementChanges;
    };
    //useEffects start
    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        if (rowData?.length > 0) {
          setSelectionsInGrid();
        } else {
          if (rowData?.length === 0 && initialEntitlements?.length === 0) {
            setEntitlementsChanged(false);
          }
        }
      }, 100);
      return () => clearTimeout(reduceCallback);
    }, [rowData]);

    useEffect(() => {
      // Cartesian Start
      if (selectedPackages?.length > 0 || selectedDistributors?.length > 0) {
        const cartesian = (...selectedPackages) =>
          selectedPackages.reduce((selectedPackages, selectedDistributors) =>
            selectedPackages.flatMap((d) =>
              selectedDistributors.map((e) => [d, e].flat()),
            ),
          );
        const result = cartesian(selectedPackages, selectedDistributors);
        const newRows = [];

        result.forEach((product) => {
          const splitDist = product[1].split(':');
          const matchingEntitlement = userEntitlementsData.find(
            (entitlement) =>
              entitlement.packageName === product[0] &&
              entitlement.distributor === splitDist[0] &&
              entitlement.distributorPlatform === splitDist[1] &&
              entitlement.deliveryType === splitDist[2] &&
              entitlement.contractNumber === editData?.contractNumber,
          );
          if (matchingEntitlement) {
            newRows.push(matchingEntitlement);
          }
        });

        const filteredRows = newRows.filter(
          (row) => !initialEntitlementIds.includes(row.productComponentId),
        );

        const selectedRows = currentlySelectedEntitlements;
        const deselectedRows = rowData.filter(
          (row) =>
            !selectedRows?.some(
              (selectedRow) =>
                selectedRow.productComponentId === row.productComponentId,
            ),
        );
        setDeselectRowsData(deselectedRows);

        setRowData([...filteredRows, ...initialEntitlements]);

        setTimeout(() => {
          const postData = {
            userEntitlements: getPostEntitlementChanges(),
            userId: UserId,
          };
          dispatch({
            type: ReduxAction.updateEntitlement,
            payload: {
              updatedEntitlements: postData,
              initialEntitlements: initialEntitlements,
            },
          });
        }, 300);
      }
    }, [selectedPackages, selectedDistributors]);
    // filter and display user's initial entitlements
    useEffect(() => {
      if (entitlementMappingData && userEntitlementsData.length > 0) {
        let filtered = entitlementMappingData.filter(function (el) {
          return (
            el.contactId === formik.getFieldProps('contactId').value &&
            !ProductDeliveryPlatforms.includes(el.distributor)
          );
        });

        if (filtered?.length === 0) {
          setIsRowDataLoaded(true);
          gridApi.current.api.showNoRowsOverlay();
        } else {
          gridApi.current.api.hideOverlay();
        }

        let ids = [];
        filtered.forEach((initialEntitlement) => {
          ids.push(initialEntitlement.productComponentId);
        });

        setInitialEntitlements(filtered);
        setInitialEntitlementIds(ids);
        setCurrentlySelectedEntitlements(filtered);
        setRowData(filtered);

        let selectedEntitlements = [];
        selectedEntitlements = filtered.filter(
          (x) =>
            x.status != Cancelled &&
            x.status != PendingInactive &&
            x.status != Rejected,
        );

        dispatch({
          type: ReduxAction.updateEntitlement,
          payload: {
            initialEntitlements: filtered,
            selectedEntitlements: selectedEntitlements,
          },
        });
      }
    }, [userEntitlementsData, entitlementMappingData]);

    useEffect(() => {
      let initialEntitlementsToValidateChanges = initialEntitlements.filter(
        (x) =>
          x.status != Cancelled &&
          x.status != PendingInactive &&
          x.status != Rejected,
      );

      if (
        objectsEqual(
          currentlySelectedEntitlements,
          initialEntitlementsToValidateChanges,
        )
      ) {
        setEntitlementsChanged(false);

        dispatch({
          type: ReduxAction.updateEntitlement,
          payload: {
            selectedEntitlements: currentlySelectedEntitlements,
          },
        });
      } else {
        setEntitlementsChanged(true);
      }
    }, [currentlySelectedEntitlements]);

    useEffect(() => {
      // Set initial state
      if (packageDataSet) setFilteredPackages(packageDataSet);
      if (distributorDataSet) setFilteredDistributors(distributorDataSet);
    }, [packageDataSet, distributorDataSet]);

    useEffect(() => {
      if (selectedParentCascade === 'PackageDropdown' && userEntitlementsData) {
        // Filter distributor based on package selection
        let filterData = userEntitlementsData.filter((el) =>
          selectedPackages.includes(el.packageName),
        );
        setFilteredDistributors({ results: filterData });
      }
    }, [selectedPackages]);

    useEffect(() => {
      if (
        selectedParentCascade === 'DistributorDropdown' &&
        userEntitlementsData
      ) {
        // Filter packages based on distributor selection and contract number
        let filterData = userEntitlementsData.filter((el) => {
          return (
            selectedDistributorsId.includes(el.distributorId) &&
            editData?.contractNumber === el.contractNumber
          );
        });
        setFilteredPackages({ results: filterData });
      }
    }, [selectedDistributorsId]);
    //useeffect ends

    //functions
    const cascadingPackageDistributor = () => {
      // On empty selection, set to default data
      if (
        selectedPackages.length === 0 &&
        selectedParentCascade === 'PackageDropdown'
      ) {
        setFilteredDistributors(distributorDataSet);
      } else if (
        selectedDistributorsId.length === 0 &&
        selectedParentCascade === 'DistributorDropdown'
      ) {
        setFilteredPackages(packageDataSet);
      }

      // Cascading functionality to set which dropdown is parent
      if (selectedPackages.length === 0 && selectedDistributors.length === 0) {
        setSelectedParentCascade();
      } else if (!selectedParentCascade) {
        if (selectedPackages.length > 0) {
          setSelectedParentCascade('PackageDropdown');
        }
        if (selectedDistributors.length > 0) {
          setSelectedParentCascade('DistributorDropdown');
        }
      }
    };

    // Memoized selection to stop loop from dropdown callback
    const cascadingPackageDistributorMemo = useMemo(
      cascadingPackageDistributor,
      [selectedPackages, selectedDistributors],
    );

    const onSelectionChanged = () => {
      gridApi.current.api.refreshCells();

      let currentlySelected = [];
      gridApi.current.api.getSelectedNodes().forEach((node) => {
        currentlySelected.push(node.data);
      });
      setCurrentlySelectedEntitlements(currentlySelected);
    };

    const setSelectionsInGrid = () => {
      gridApi.current.api.selectAll();
      const nodesToDeselect = [];
      if (isFirstDataSelection) {
        setIsFirstDataSelection(false);
        gridApi.current.api.forEachNode((node) => {
          // Deselect Cancelled and Pending Inactive entitlements
          if (
            [Cancelled, PendingInactive, Rejected].includes(node?.data?.status)
          )
            nodesToDeselect.push(node);
        });
      }

      if (isRowDataLoaded && deselectRowsData?.length > 0) {
        gridApi.current.api.forEachNode((node) => {
          if (
            deselectRowsData.some(
              (nd) => nd.productComponentId === node?.data?.productComponentId,
            )
          )
            nodesToDeselect.push(node);
        });
      }
      gridApi.current.api.setNodesSelected({
        nodes: nodesToDeselect,
        newValue: false,
      });
      setIsRowDataLoaded(true);
      setDeselectRowsData([]);
    };
    const onEntitlementGridReadyHandler = (params) => {
      gridApi.current = params;
      params.api.showLoadingOverlay();

      if (!packageDataSet?.results?.length > 0) {
        getAllUserEntitlements(editData?.contractNumber);
        getEntitlementMappings();
      }
    };
    const onFirstEntitlementDataRendered = useCallback((params) => {
      setSelectionsInGrid();
    }, []);

    // update entitlements in DB
    const AddEntitlements = () => {
      try {
        let entitlementChanges = getPostEntitlementChanges();
        let postData = {
          userEntitlements: entitlementChanges,
          userId: UserId,
        };
        dispatch({
          type: ReduxAction.updateEntitlement,
          payload: {
            updatedEntitlements: postData,
            initialEntitlements: initialEntitlements,
            selectedEntitlements: currentlySelectedEntitlements,
          },
        });

        // check for channel partner match in changed entitlements
        let partnerMatch = false;

        for (const newEntitlement of entitlementChanges) {
          let match = distributorDataSet.results.find(
            (matchingEntitlement) =>
              matchingEntitlement.platformCategories == 'Channel Partners' &&
              matchingEntitlement.distributorId == newEntitlement.distributorId,
          );

          if (
            match &&
            (newEntitlement?.status === PendingActive ||
              newEntitlement?.status === Active)
          ) {
            partnerMatch = true;
            break;
          }
        }

        if (partnerMatch) {
          setIsUserIdEnabled(true);
          setActiveTab('manage-user-ids-tab');
        } else {
          // save CI entitlements
          saveEntitlements(postData);
        }
      } catch (err) {}
    };

    const saveEntitlements = (postData, showAlert = true) => {
      setLoading(true);
      httpService
        .post(Endpoints.userentitlementsApi, postData)
        .then((res) => {
          if (res.data === 0) {
            afterUpdateAction('User Entitlements have been updated');
          }
        })
        .catch((err) => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => setLoading(false));
    };

    const afterUpdateAction = (displayMessage) => {
      getUsers();
      onAddEditModalClose();
      Notify({
        alert: true,
        type: 'success',
        title: displayMessage,
      });
    };

    const callBackPackages = (params) => {
      let pkgs = [];
      params.forEach((pkg) => {
        pkgs.push(pkg.packageName);
      });
      setSelectedPackages(pkgs);
    };
    const callBackDistributors = (params) => {
      let dsts = [];
      let dstsId = [];
      params.forEach((dst) => {
        dsts.push(dst.distributor);
        dstsId.push(dst.distributorId);
      });
      setSelectedDistributors(dsts);
      setSelectedDistributorsId(dstsId);
    };
    return (
      <>
        <Container className="pt-3">
          {!editData?.groupId && (
            <Row>
              <Col>
                <div>
                  <PackagesDropdown
                    DataSet={filteredPackages}
                    callBackPackages={callBackPackages}
                    disabled={editData?.groupId}
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <DistributorsDropdown
                    DataSet={filteredDistributors}
                    callBackDistributors={callBackDistributors}
                    disabled={editData?.groupId}
                  />
                </div>
              </Col>
            </Row>
          )}
          {editData?.groupId && (
            <Row>
              <Col className="mb-2">
                <span className="email-preference-note-label">
                  This user belongs to a group and cannot be edited
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <div className="ag-grid-modal-content">
              <AgGridComponent
                config={defaultConfig}
                defaultColumnDef={defaultColdef}
                data={rowData}
                columns={entitlementsColumns}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onEntitlementGridReadyHandler}
                // onFirstDataRendered={onFirstEntitlementDataRendered}
              />
            </div>
          </Row>
          <Row
            className={
              editData?.groupId
                ? 'modal-row group-mapped-entitlement-fix'
                : 'modal-row entitlement-fix'
            }
          >
            <Form>
              <Form.Group className="col-12 user-save-btn-body">
                <button
                  type="button"
                  disabled={!entitlementsChanged || editData?.groupId}
                  onClick={AddEntitlements}
                  className="user-save-btn btn btn-dark float-end btn-opacity"
                >
                  {editMode ? 'Update' : 'Save'}
                </button>
              </Form.Group>
            </Form>
          </Row>
        </Container>
      </>
    );
  },
  (op, np) =>
    op?.editData === np?.editData &&
    op?.entitlementMappingData === np?.entitlementMappingData &&
    op?.initialValue === np?.initialValue &&
    op?.packageDataSet === np?.packageDataSet &&
    op?.userEntitlementsData === np?.userEntitlementsData &&
    op?.initialValue === np?.initialValue,
);

export default UserEntitlements;
