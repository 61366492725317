import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import csaUserDetailsReducer from './persistReducers/csaUserDetailsReducer';

const persistConfig = {
  key: 'root',
  storage,
};
let rootReducer = combineReducers({ userActionData: csaUserDetailsReducer });
let persistedReducer = persistReducer(persistConfig, rootReducer);
export const persistDataStore = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
persistStore(persistDataStore);

export default persistDataStore;
