import { ReduxAction } from './../../constants';
const initialState = {};

const distributorDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.distributorsDetail: {
      return {
        ...state,
        distributorDetails: action.payload?.distributorDetails,
      };
    }
    default:
      return state;
  }
};
export default distributorDetailsReducer;
