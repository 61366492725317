import jwtDecode from 'jwt-decode';
//import { verify, sign } from "jsonwebtoken";
import axios from './axios';
//import useLocalStorage from "../hooks/useLocalStorage";
//import { useNavigate } from "react-router-dom";

export function JWT() {
  //const navigate = useNavigate();
  //const [name, setName] = useLocalStorage("name", "Bob");
  //const [currentUser, setCurrentUser] = React.useState(null);
}

//navigate(`/`);

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// const handleTokenExpired = (exp) => {
//   let expiredTimer;
//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   expiredTimer = window.setTimeout(() => {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//     window.location.pathname = '/login';
//   }, timeLeft);

//  setInterval(() => {
//   const currentTime1 = Date.now();
//   const timeLeft1 = exp * 1000 - currentTime1;
//  }, 1000);
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    //handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem('selectedRole');
  }
};

export {
  // verify, sign,
  isValidToken,
  setSession,
};
