import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './slices/counter';
import contractReducer from '../redux/slices/reducers';
import userInfoReducer from '../redux/slices/userInfoReducer';
import entitlementReducer from '../redux/slices/EntitlementReducer';
import packageReducer from '../redux/slices/PackageReducer';
import entitlementDetailsReducer from '../redux/slices/EntitlementDetailReducer';
import thunk from 'redux-thunk';
import datasetDetailsReducer from '../redux/slices/DatasetDetailReducer';
import distributorDetailsReducer from '../redux/slices/DistributorDetailsReducer';
import distributorsReducer from '../redux/slices/DistributorsReducer';
import jobFunctionDesignationReducer from '../redux/slices/JobFunctionDesignationReducer';
import userGroupEntitlementReducer from '../redux/slices/UserGroupEntitlementReducer';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    contract: contractReducer,
    userInfo: userInfoReducer,
    entitlementUsers: entitlementReducer,
    packageDetails: packageReducer,
    jobDesignationDetails: jobFunctionDesignationReducer,
    entitlementDetails: entitlementDetailsReducer,
    datasets: datasetDetailsReducer,
    distributorsDetails: distributorDetailsReducer,
    distributors: distributorsReducer,
    userGroupEntitlement: userGroupEntitlementReducer,
  },
  middleware: [thunk],
});
export default store;
