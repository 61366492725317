import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlPath } from '../../constants';
import { Endpoints, ReduxAction } from '../../constants';
import { getText } from '../../helpers/index';
import HttpService from '../../services/http-service';
import HtmlContent from '../../sharedcomponents/html-content/html-content';
import { useDispatch } from 'react-redux';
import './distributors.scss';
import Loader from '../../components/Loader';
import { ReactComponent as BackIcon } from './../../assets/icons/common/back-arrow.svg';
const DistributorDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [distLoading, setDistLoading] = useState(true);
  const [tileDetails, setTileDetails] = useState();
  const [packageDataSets, setPackageDataSet] = useState([]);
  const dispatch = useDispatch();
  const distributorDetails = [
    { displayName: 'Key Use Cases', value: 'keyUseCases' },
    { displayName: 'Key Features', value: 'keyFeatures' },
    { displayName: 'Key Capabilities', value: 'keyCapabilities' },
    { displayName: 'Key Workflows', value: 'keyWorkflows' },
    { displayName: 'Delivery', value: 'delivery' },
    { displayName: 'Coverage', value: 'coverage' },
    { displayName: 'Features', value: 'features' },
    { displayName: 'Key Personas', value: 'keyPersonas' },
    { displayName: 'Key Benefits', value: 'keyBenefits' },
    { displayName: 'Accessible Data', value: 'accessibleData' },
    { displayName: 'Platform User Persona', value: 'platformUserPersona' },
    { displayName: 'Commodity Coverage', value: 'commodityCoverage' },
    { displayName: 'Benefits', value: 'benefits' },
    {
      displayName: 'CSM Market Data Package Coverage',
      value: 'csmMarketDataPackageCoverage',
    },
    {
      displayName: 'CSM Market Insights Package Coverage',
      value: 'csmMarketInsightsPackageCoverage',
    },
    {
      displayName: 'CSM Risk Insights Package Coverage',
      value: 'csmRiskInsightsPackageCoverage',
    },
    {
      displayName: 'Distributor Platform Link',
      value: 'distributorPlatformLink',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    const distributorId = location.pathname.split('/').pop().split('-').pop();
    if (location.state) {
      const tile_details = location?.state?.tile_details;
      setTileDetails(tile_details);
      setDistLoading(false);
      dispatch({
        type: ReduxAction.tabType,
        payload: {
          tabId: 2,
          id:
            location?.state?.tile_details?.tile_main_heading +
            '_' +
            location?.state?.tile_details?.id,
          contractType: location?.state?.tile_details?.contractType,
        },
      });
    } else {
      if (packageDataSets.length > 0) {
        setTileDetails(packageDataSets.find((x) => x.id === distributorId));
        setDistLoading(false);
        setTimeout(() => {
          dispatch({
            type: ReduxAction.tabType,
            payload: {
              tabId: 2,
              id: tileDetails?.tile_main_heading + '_' + tileDetails?.id,
              contractType: tileDetails?.contractType,
            },
          });
        }, 100);
      } else {
        getDistributors(distributorId);
      }
    }
  }, [location]);

  const getDistributors = async (distributorId) => {
    setDistLoading(true);
    HttpService.get(Endpoints.distributorApi)
      .then((res) => {
        setPackageDataSet(res.data?.results);
        const tileDetails = res.data?.results.find(
          (x) => x.id === distributorId,
        );
        if (tileDetails)
          tileDetails.tile_main_heading =
            tileDetails.distributorDisplayName +
            ' - ' +
            tileDetails.distributorPlatformDisplayName;
        setTileDetails(tileDetails);
        dispatch({
          type: ReduxAction.tabType,
          payload: {
            tabId: 2,
            id: tileDetails?.tile_main_heading + '_' + tileDetails?.id,
            contractType: tileDetails?.contractType,
          },
        });
      })
      .catch((err) => {})
      .finally(() => setDistLoading(false));
  };

  return (
    <>
      {distLoading ? (
        <Loader type="scaleLoader" />
      ) : (
        <Row className="dataset-introduction-section">
          <Col sm={12} className="dataset-intro-main">
            <div
              onClick={() => navigate(UrlPath.packages)}
              className="d-flex align-items-center gap-2 cursor-pointer mb-3"
            >
              <BackIcon> </BackIcon>
              <span className="back-text">Back</span>
            </div>
            <section className="page-section border-bottom">
              {' '}
              <h1>{tileDetails?.tile_main_heading}</h1>
              {tileDetails?.distributorDescription && (
                <HtmlContent
                  className="dataset__overview-description"
                  html={tileDetails?.distributorDescription}
                />
              )}
              {tileDetails?.platformDescription && (
                <HtmlContent
                  className="dataset__overview-description"
                  html={tileDetails?.platformDescription}
                />
              )}
              <div className="distributor-sub-detail">
                {distributorDetails.map((dist) => {
                  return tileDetails && tileDetails[dist.value] ? (
                    <>
                      <div>
                        <span className="sub-heading">
                          {dist.displayName + ' : '}
                        </span>
                        <br />
                        <HtmlContent
                          html={tileDetails ? tileDetails[dist.value] : ''}
                        />
                      </div>
                      <br />
                    </>
                  ) : (
                    ''
                  );
                })}
                {tileDetails?.distributor === 'Snowflake' ||
                (tileDetails?.distributor === 'Platts' &&
                  tileDetails?.distributorPlatform === 'iFTP') ? (
                  <>
                    <div>
                      <span className="sub-heading snowflake-text">
                        Would you like to access your content through this
                        platform?
                      </span>
                      <br />
                      <span>
                        Please contact{' '}
                        <a
                          className="link-text"
                          href="mailto:ci.support@spglobal.com"
                        >
                          ci.support@spglobal.com
                        </a>{' '}
                        for assistance.
                      </span>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {tileDetails?.dataProviderDesc && (
                  <div className="dataset__overview-vendor-info">
                    <h2 className="subheading subheading--with-border">
                      {getText('generic.vendorInfo')}
                    </h2>
                    <HtmlContent
                      className="dataset__overview-vendor-info-body"
                      html={tileDetails?.dataProviderDesc}
                    />
                  </div>
                )}
              </div>
            </section>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DistributorDetails;
