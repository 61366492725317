import React from 'react';
import { NavLink } from 'reactstrap';
import { ReactComponent as AvatarIcon } from './../../assets/icons/avatars/avatar.svg';
import bell_icon from './../../assets/icons/avatars/bell_icon.svg';

const NavbarUser = (props) => {
  const { name, navLinkData } = props;

  return (
    <NavLink
      {...navLinkData}
      className={'no-border-right ' + navLinkData.className}
    >
      <span className="truncate user">{name}</span>
      <AvatarIcon className="avatar img-fluid rounded-circle" />
      <img
        src={bell_icon}
        className="bell-icon img-fluid rounded-circle"
        alt="Admin"
      />
    </NavLink>
  );
};

export default NavbarUser;
