import { ReduxAction } from './../../constants';
const initialState = {};

const entitlementDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.updateEntitlement: {
      return {
        ...state,
        initialEntitlements: action.payload?.initialEntitlements,
        entitlementsToUpdate: action.payload?.updatedEntitlements,
        selectedEntitlements:
          action.payload?.selectedEntitlements ?? state?.selectedEntitlements,
      };
    }
    case ReduxAction.updateProductEntitlement: {
      return {
        ...state,
        initialProductEntitlements: action.payload?.initialProductEntitlements,
        productsEntitlementsToUpdate:
          action.payload?.updatedProductEntitlements,
      };
    }
    default:
      return state;
  }
};
export default entitlementDetailsReducer;
