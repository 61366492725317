import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const TokenRefreshWrapper = ({ children }) => {
  const { oktaAuth } = useOktaAuth();
  const [timer, setTimer] = useState(null);
  const signOut = () => {
    localStorage.clear();
    oktaAuth.signOut();
  };
  const getInitialLoginTime = () => {
    const initialLoginTime = localStorage.getItem('initialLoginTime');
    return initialLoginTime ? parseInt(initialLoginTime) : null;
  };
  useEffect(() => {
    if (oktaAuth && localStorage.getItem('token')) {
      const initialLoginTimeDiff = new Date().getTime() - getInitialLoginTime();
      const totalHoursPassed = Math.floor(
        initialLoginTimeDiff / (1000 * 60 * 60),
      );
      if (totalHoursPassed >= 24) {
        signOut();
      }
      let expiresInS =
        (oktaAuth?.authStateManager?._authState?.idToken?.expiresAt
          ? oktaAuth?.authStateManager?._authState?.idToken?.expiresAt
          : parseInt(localStorage.getItem('expiresAt'))) -
        new Date().getTime() / 1000;

      expiresInS =
        expiresInS < 180
          ? expiresInS > 1
            ? expiresInS - 1
            : 0
          : expiresInS - 180;
      // Set up the timer to refresh the token
      const refreshTimer = setTimeout(() => {
        refreshAccessToken();
      }, expiresInS * 1000);

      // Store the timer in the state
      setTimer(refreshTimer);
    }

    return () => {
      // Clear the timer when the component unmounts or the token is refreshed
      clearTimeout(timer);
    };
  }, [localStorage.getItem('lastLoginTime')]);

  const refreshAccessToken = async () => {
    try {
      // Exchange the refresh token for a new access token
      oktaAuth.token
        .renewTokens()
        .then((newToken) => {
          // token refresh successful
          const loginTime = new Date().getTime();
          let accessToken = newToken?.accessToken?.accessToken;
          if (accessToken) {
            localStorage.setItem('token', accessToken);
            localStorage.setItem(
              'refreshToken',
              newToken?.refreshToken?.refreshToken,
            );
            localStorage.setItem('expiresAt', newToken?.idToken?.expiresAt);
          }
          localStorage.setItem('lastLoginTime', loginTime);

          let expiresInS =
            (oktaAuth?.authStateManager?._authState?.idToken?.expiresAt
              ? oktaAuth?.authStateManager?._authState?.idToken?.expiresAt
              : parseInt(localStorage.getItem('expiresAt'))) -
            new Date().getTime() / 1000;

          expiresInS =
            expiresInS < 180
              ? expiresInS > 0
                ? expiresInS / 2
                : 0
              : expiresInS - 180;
          // Set up the timer to refresh the token
          const refreshTimer = setTimeout(() => {
            refreshAccessToken();
          }, expiresInS * 1000);

          // Store the timer in the state
          setTimer(refreshTimer);
        })
        .catch((error) => {
          // token renewal error
          signOut();
        });
    } catch (error) {}
  };

  return <>{children}</>;
};

export default TokenRefreshWrapper;
