import React from 'react';
import axios from '../utils/axios';
import { useEffect } from 'react';

const initialState = {
  name: 'test',
  phone: '123',
};

const CurrentUserContext = React.createContext(initialState);

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState(null);

  useEffect(() => {
    window.localStorage.setItem('selectedRole', selectedRole);
  }, [selectedRole]);

  const fetchCurrentUser = async () => {
    const response = await axios('/api/Account/permissions');
    setCurrentUser(response.data);
  };

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        fetchCurrentUser,
        selectedRole,
        setSelectedRole,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
