import persistDataStore from './../redux/persistStore';
import httpService from './../services/http-service';
import { Endpoints } from './../constants';
export const getCSAContractsData = async () => {
  let csaActionData =
    persistDataStore.getState()?.userActionData?.csaUserActionInfo;
  let queryParamsContract = {
    PageSize: 5000,
    Field: 'contractNumber',
  };
  if (csaActionData?.type && csaActionData?.value) {
    switch (csaActionData?.type) {
      case 'account': {
        queryParamsContract.Filter = `accountId:"${csaActionData?.value}"`;

        const contractResponse = await httpService.get(
          Endpoints.locationApi,
          queryParamsContract,
        );
        let tempState = contractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }

      case 'contract': {
        let csaSelectedContract = [
          {
            label: csaActionData?.value,
            id: csaActionData?.value,
            checked: true,
            contractNumber: csaActionData?.value,
          },
        ];
        return csaSelectedContract;
      }
      case 'user': {
        let queryParams = {
          PageSize: 5000,
          Field: 'contractNumber',
          Filter: `email:"${csaActionData?.value}"`,
        };
        const usersContractResponse = await httpService.get(
          Endpoints.userApi,
          queryParams,
        );
        let tempState = usersContractResponse.data?.results?.map((el, i) => {
          return {
            label: el.contractNumber,
            id: el.contractNumber,
            checked: true,
            ...el,
          };
        });
        return tempState;
      }
    }
  }
  return [];
};
