import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Main from '../components/Main';
import Wrapper from '../components/Wrapper';
import Navbar from '../components/navbar/Navbar';
import ErrorBoundary from './ErrorBoundary';

const BasicLayout = ({ children }) => (
  <React.Fragment>
    <Wrapper>
      {/* <Sidebar items={allItems} /> */}
      <Main>
        <Navbar />
        <ErrorBoundary>
          {children}
          <Outlet />
        </ErrorBoundary>
        <Footer />
      </Main>
    </Wrapper>
  </React.Fragment>
);

export default BasicLayout;
