/**
 *
 * Usage
 *
 * getText('hello'); // => 'world'
 *
 * getText('pages.dataSets.text'); // => 'Datasets'
 *
 */

import langFile from "../languages/en-us.json";
import get from "./get";

const missingValue = "Missing text: ";

export default (key) => {
  return get(langFile, key, `${missingValue}${key}`);
};
