import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import copy_icon from '../../assets/icons/common/copy-icon.svg';
import Loader from '../../components/Loader';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import bell_icon from './../../assets/icons/avatars/bell_icon.svg';
import BuildingsBwImg from './../../assets/img/vector/buildings-bw.png';
import {
  Endpoints,
  GenericError,
  PendingActive,
  PendingApproval,
  PendingInactive,
  UrlPath,
  UserId,
} from './../../constants';
import { Notify } from './../../sharedcomponents/Alert/Notify';

const UserDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState();
  const [filteredContracts, setFilteredContracts] = useState();
  const [entitlementsData, setEntitlementsData] = useState();
  const [isEmailOpt, setIsEmailOpt] = useState(false);
  const [profileUser, setProfileUser] = useState();
  const [loggedInUserContractNumber, setLoggedInUserContractNumber] =
    useState();
  const UserRole = ['Admin', 'Contract Admin'];
  const gridRefContract = useRef(null);
  const navigate = useNavigate();

  const getUserById = (email, contractNumbersString) => {
    try {
      let queryParams = {
        Filter:
          'email:"' +
          email.toLowerCase() +
          '" AND contractNumber in(' +
          contractNumbersString +
          ')',
        Field:
          'contactName,contactId,contractNumber,email,phone,department,jobFunction,isEmailOptOut,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate,middlename,iscontractadmin',
      };
      httpService
        .get(Endpoints.userApi, queryParams)
        .then(({ data }) => {
          let rowData = data?.results ?? data?.aggResultValue;
          setProfileUser(data?.results[0]);
          data?.results[0]?.isEmailOptOut == 1
            ? setIsEmailOpt(true)
            : setIsEmailOpt(false);
        })
        .catch(() => {
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        });
    } catch (err) {}
  };

  useEffect(() => {
    if (localStorage?.userID) {
      getContractsDataSet();
    }
  }, [localStorage]);

  const getContractsDataSet = async () => {
    try {
      gridRefContract?.current?.api?.showLoadingOverlay();
      let queryParams = {
        pageSize: 5000,
      };
      const contractResponse = await httpService.get(
        Endpoints.contractApi,
        queryParams,
      );
      let allContracts = contractResponse?.data?.results;

      // set User Details after filtering email id of logged in user
      let tempUserDetails = allContracts?.filter(
        (el) =>
          el.email?.toLowerCase().trim() ===
          localStorage.userID?.toLowerCase().trim(),
      );
      // List of logged in user's contract numbers
      let contractIDs = tempUserDetails?.map((el) => el.contractNumber);

      let contractNumbersToString = contractIDs
        ?.map((contract) => (contract ? '"' + contract + '"' : ''))
        .join(',');

      setLoggedInUserContractNumber(contractNumbersToString);
      getUserById(UserId, contractNumbersToString);
      // Comparator array to distinct values for contract admin
      let uniqueArr = [];
      let tempFilteredContracts = [];
      allContracts?.map((el) => {
        if (
          el.email?.toLowerCase().trim() ===
            localStorage.userID?.toLowerCase().trim() &&
          contractIDs?.includes(el.contractNumber) &&
          !uniqueArr?.includes(String(el.contractNumber + el.officeLocation))
        ) {
          uniqueArr?.push(String(el.contractNumber + el.officeLocation));
          const encodedContract = btoa(el.contractNumber);
          let obj = {
            ...el,
            uniqueLink:
              window.location.origin +
              UrlPath.endUserRegistration.replace(':id', encodedContract),
          };
          tempFilteredContracts.push(obj);
        }
      });

      // Get alternate CCAs for user's contracts
      let alternateCCAs = [];
      contractIDs.forEach((id) => {
        let matchingContracts = allContracts.filter(
          (contract) => contract.contractNumber === id,
        );
        let matchingEmails = matchingContracts
          .map((contract) => contract.email)
          .filter(
            (email) =>
              email?.toLowerCase().trim() !==
              localStorage.userID?.toLowerCase().trim(),
          );
        alternateCCAs[id] = matchingEmails;
      });

      // Add alternate CCAs to table data
      for (let i = 0; i < tempFilteredContracts.length; i++) {
        let altEmails = alternateCCAs[tempFilteredContracts[i].contractNumber];
        tempFilteredContracts[i].alternateCCAs = altEmails;
      }

      setUserDetails(tempUserDetails[0]);

      setFilteredContracts(tempFilteredContracts);
    } catch (err) {
    } finally {
      setLoading(false);
      gridRefContract?.current?.api?.hideOverlay();
    }
  };

  const getEntitlementsStatusCount = async () => {
    try {
      setLoading(true);
      gridRefContract?.current?.api?.showLoadingOverlay();
      let queryParams = {
        Filter: `status IN ("${PendingActive}","${PendingInactive}","${PendingApproval}") AND contractNumber in(${loggedInUserContractNumber})`,
        Field: 'count(status)',
        groupBy: 'status',
        pageSize: 50,
        id: String(Date.now()),
      };

      const entitlementResponse = await httpService.get(
        Endpoints.userentitlementsApi,
        queryParams,
      );

      let statusWithCount = {
        pending_active: 0,
        pending_inActive: 0,
        pending_approval: 0,
        totalPendingActAndInActEnt: 0,
      };

      entitlementResponse?.data?.aggResultValue?.forEach((obj) => {
        if (obj.status === PendingActive)
          statusWithCount.pending_active = obj.count;
        else if (obj.status === PendingInactive)
          statusWithCount.pending_inActive = obj.count;
        else if (obj.status === PendingApproval)
          statusWithCount.pending_approval = obj.count;
        if ([PendingActive, PendingInactive].includes(obj.status))
          statusWithCount.totalPendingActAndInActEnt += obj.count;
      });

      setEntitlementsData(statusWithCount);
    } catch (err) {
    } finally {
      setLoading(false);
      gridRefContract?.current?.api?.hideOverlay();
    }
  };

  useEffect(() => {
    if (loggedInUserContractNumber) {
      getEntitlementsStatusCount();
    }
  }, [loggedInUserContractNumber]);

  const redirectToUserEntitlementsPage = (entitlementStatus) => {
    navigate(UrlPath.entitlements, { state: entitlementStatus });
  };

  // Ag Grid Table

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    suppressDragLeaveHidesColumns: true,
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    rowSelection: 'single',
    suppressRowClickSelection: false,
  };

  const defaultColdef = {
    resizable: true,
  };

  const columnFilterParams = {
    filter: 'agSetColumnFilter',
    sortable: true,
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  // Contract admins grid column
  const contractAdminColumn = [
    {
      headerName: 'Contract ID',
      headerTooltip: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      initialFlex: 1.6,
      ...columnFilterParams,
    },
    {
      headerName: 'Alternate CCAs',
      headerTooltip: 'Alternate CCAs',
      field: 'alternateCCAs',
      tooltipField: 'alternateCCAs',
      initialFlex: 2,
      ...columnFilterParams,
    },
    {
      headerName: 'Allowed Email Domains',
      headerTooltip: 'Allowed Email Domains',
      field: 'allowedEmailDomains',
      tooltipField: 'allowedEmailDomains',
      initialFlex: 2,
      ...columnFilterParams,
    },
    {
      headerName: 'User Onboard Link',
      headerTooltip: 'User Onboard Link',
      field: 'uniqueLink',
      tooltipField: 'uniqueLink',
      initialFlex: 4,
      ...columnFilterParams,

      cellRenderer: (params) => (
        <div className="">
          <span className="link-block 'user-name-wrapper">
            <img
              onClick={() => navigator.clipboard.writeText(params.value)}
              className="cursor-pointer me-1"
              src={copy_icon}
              title="Copy Link"
            />
            {params.value}
          </span>
        </div>
      ),
    },
    {
      headerName: 'Approval Required',
      headerTooltip: 'Approval Required',
      field: 'isRestricted',
      initialFlex: 1.6,
      ...columnFilterParams,
      menuTabs: ['generalMenuTab'],

      cellRenderer: (params) => {
        let switchElement = document.getElementById(params.data.contractNumber);
        if (switchElement)
          switchElement.checked = params.data && params.data.isRestricted === 1;
        return (
          <>
            <div className="form-check form-switch form-switch-custom">
              <input
                type="checkbox"
                role="switch"
                name="isRestricted"
                id={params.data.contractNumber}
                className="form-check-input form-check-input-custom"
                onChange={saveApprovalStatusDebounce}
                defaultChecked={params.data && params.data.isRestricted === 1}
                prevchecked={(
                  params.data && params.data.isRestricted === 1
                ).toString()}
              />
            </div>
          </>
        );
      },
    },
  ];

  const saveApprovalStatus = (e) => {
    const { checked, id: contractNumber, prevChecked } = e.target;

    // To avoid extra API calls for same status
    if (prevChecked !== checked) {
      // set new value for previous checked state
      let switchElement = document.getElementById(contractNumber);

      switchElement.disabled = true;

      const postData = {
        contractNumber: contractNumber,
        userId: UserId,
        isRestricted: checked ? 1 : 0,
      };

      httpService
        .post(Endpoints.contractApi, postData)
        .then((resp) => {
          if (resp?.data !== 0) throw 'error';
          else switchElement.prevChecked = checked;
        })
        .catch((err) => {
          switchElement.checked = prevChecked;
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        })
        .finally(() => (switchElement.disabled = false));
    }
  };

  const saveApprovalStatusDebounce = debounce(saveApprovalStatus, 300);

  const onGridReadyContract = (params) => {
    gridRefContract.current = params;
  };

  const updateUserEmailNotification = (checked) => {
    try {
      if (profileUser?.contactId) {
        setLoading(true);
        let User_Info = {
          ...profileUser,
          isEmailOptOut: checked ? 1 : 0,
          userid: UserId,
          isDeleted: 0,
        };

        let postData = {};
        for (let key in User_Info) {
          if (typeof User_Info[key] === 'string') {
            postData[key] = User_Info[key].trim();
          } else {
            postData[key] = User_Info[key];
          }
        }
        httpService
          .post(Endpoints.userApi, [postData])
          .then((resp) => {
            if (resp?.data?.code === 0) {
              //after success
              Notify({
                alert: true,
                type: 'success',
                title: 'User has been updated',
              });
            }
          })
          .catch((err) => {
            let errorMsg =
              err?.response?.data?.resultCode === '-2'
                ? err?.response?.data?.cause
                : GenericError.somethingWentWrong;
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
            setIsEmailOpt(!checked);
          })
          .finally(() => setLoading(false));
      } else {
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const emailNotificationChangeHandler = (event) => {
    let checked = event.target.checked;

    Notify({
      alert: true,
      type: '',
      title: `Do you want to opt ${
        checked ? 'out of' : 'into'
      } email notifications?`,
      confirmBtnText: 'Yes',
      cancelBtnText: 'No',
      showCloseBtn: false,
      onConfirm: () => updateUserEmailNotification(checked),
      onCancel: () => {
        setIsEmailOpt(!checked);
      },
    });

    setIsEmailOpt(checked);
  };
  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="content mx-3">
            <div className="container-fluid">
              <Row className="dashboard-header">
                <Col sm={3}>
                  <div
                    className="user-name-wrapper"
                    title={
                      userDetails?.contactName +
                      '  (' +
                      UserRole[userDetails?.isContractAdmin ?? 1] +
                      ')'
                    }
                  >
                    {userDetails?.contactName}
                    <span className="user-role">
                      ({UserRole[userDetails?.isContractAdmin ?? 1]})
                    </span>
                  </div>
                </Col>
                <Col sm={9}>
                  <div className="user-clickable-content">
                    <div className="user-content-wrapper col-sm-3">
                      <span className="user-key">Email</span>
                      <span className="user-value" title={userDetails?.email}>
                        {userDetails?.email}
                      </span>
                    </div>
                    <div className="user-content-wrapper col-sm-6">
                      <span className="user-key">Location</span>
                      <span
                        className="user-value"
                        title={userDetails?.officeLocation}
                      >
                        {userDetails?.officeLocation}
                      </span>
                    </div>
                    <div className="user-content-wrapper col-sm-3">
                      <span className="user-key">Support</span>
                      <span
                        className="user-value support"
                        title="Contact CI Support"
                      >
                        <a
                          href="mailto:CI.support@spglobal.com"
                          className="user-blue"
                          target="_blank"
                        >
                          Contact CI Support
                        </a>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Col sm={12} className="user-right-section">
                    <div className="d-flex">
                      <img
                        src={bell_icon}
                        className="user-bell-icon img-fluid rounded-circle"
                        alt="Admin"
                      />
                      {entitlementsData?.totalPendingActAndInActEnt >= 0 ? (
                        <div className="user-history-wrapper">
                          <span className="user-history-title">
                            There are{' '}
                            <span
                              className="spg-red cursor-pointer"
                              onClick={() =>
                                redirectToUserEntitlementsPage([
                                  PendingActive,
                                  PendingInactive,
                                ])
                              }
                            >
                              {entitlementsData?.totalPendingActAndInActEnt}{' '}
                              changes
                            </span>{' '}
                            pending
                          </span>
                          <span className="user-history-description">
                            You will be notified after feed file is generated
                          </span>
                        </div>
                      ) : (
                        <span className="text-center w-25">
                          <Loader type="scaleLoaderDropdown" />
                        </span>
                      )}
                    </div>
                  </Col>
                  {entitlementsData?.pending_approval > 0 && (
                    <Col sm={12} className="user-right-section  mt-3">
                      <div className="d-flex">
                        <img
                          src={bell_icon}
                          className="user-bell-icon img-fluid rounded-circle"
                          alt="Admin"
                        />

                        <div className="user-history-wrapper">
                          <span className="user-history-title">
                            There are new entitlement requests pending approval.{' '}
                            <span
                              className="spg-red cursor-pointer"
                              onClick={() =>
                                redirectToUserEntitlementsPage([
                                  PendingApproval,
                                ])
                              }
                            >
                              Click here
                            </span>{' '}
                            to view and approve
                          </span>
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col sm={12} className="user-sub-header">
                    Contracts
                  </Col>
                  <Col sm={12}>
                    <div className="ag-grid-table user">
                      <AgGridComponent
                        config={defaultConfig}
                        defaultColumnDef={defaultColdef}
                        data={filteredContracts}
                        columns={contractAdminColumn}
                        onGridReady={onGridReadyContract}
                      />
                    </div>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <div className="d-flex">
                      <Form.Check
                        type="checkbox"
                        className="user-history-title"
                      >
                        <Form.Check.Input
                          type={'checkbox'}
                          onChange={emailNotificationChangeHandler}
                          checked={isEmailOpt}
                        />
                        <Form.Check.Label>
                          <div className="label-mt-2">
                            Marketing Email Opt-Out : You will not receive S&P
                            Global Commodity Insights promotional emails,such as
                            insightful content, event invitations and product
                            information.
                          </div>
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
          </div>
          <div className="user-dashboard-footer">
            <Col sm={4}>
              {/* <div className="user-clickable-content ms-3">
                <div>
                  <LogoutIcon className="user-clickable-icon" />
                  <span className="user-clickable-txt">logout</span>
                </div>
              </div> */}
            </Col>
            <Col sm={8}>
              <img
                className="user-buildings-img img-fluid"
                src={BuildingsBwImg}
                alt=""
              />
            </Col>
          </div>
        </>
      )}
    </>
  );
};

export default UserDashboard;
