import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import {
  Active,
  DistributorUserIDRequiredList,
  Endpoints,
  GenericError,
  PendingActive,
  ReduxAction,
  RequiredPlatformCategory,
  UserId,
} from './../../../constants';
import store from './../../../redux/store';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import './manage-user-ids.scss';

const ManageUserIds = ({
  distributorDataSet,
  editData,
  editMode,
  getUsers,
  onAddEditModalClose,
  setLoading,
  isEndUser,
  endUserDistributors,
  setEndUserDistributors,
}) => {
  const [userRowData, setUserRowData] = useState([]);
  const [currentUserRowDataRedux, setCurrentUserRowDataRedux] = useState();
  const [userInitialRowData, setUserInitialRowData] = useState([]);
  const [entitlementsAndUserIdsChanged, setEntitlementsAndUserIdsChanged] =
    useState(false);
  const [initialExUsers, setInitialExUsers] = useState([]);
  const [errordetails, setErrordetails] = useState('');
  const gridRef = useRef();
  const dispatch = useDispatch();
  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    suppressDragLeaveHidesColumns: true,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: false,
    overlayNoRowsTemplate: isEndUser
      ? 'Currently, you are not entitled to any platforms requiring a User ID'
      : 'Currently, none of the delivery platforms selected require a User ID',
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const onInputsChanged = (params) => {
    let index = userRowData.findIndex(
      (x) => x.distributorId === params.data.distributorId,
    );
    if (index >= 0) {
      userRowData[index]['userId'] = params.newValue ?? '';
    }
    validateUserIdChange();
  };

  const manageUserIdColumns = [
    {
      headerName: 'User Id',
      headerTooltip: 'User Id',
      field: 'userId',
      tooltipField: 'userId',
      initialFlex: 4,
      singleClickEdit: true,
      editable: true,
      cellRenderer: (params) => {
        return (
          <div className="aggrid-input-border">
            {params.data?.userId ? params.data.userId : ''}
          </div>
        );
      },
      menuTabs: ['generalMenuTab'],
      cellClass: 'aggrid-input-wrapper',
    },

    {
      headerName: 'Distributor Platform',
      headerTooltip: 'Distributor Platform',
      field: 'distributorPlatform',
      tooltipField: 'distributorPlatform',
      initialFlex: 3,
    },
    {
      headerName: 'Distributor',
      headerTooltip: 'Distributor',
      field: 'distributor',
      tooltipField: 'distributor',
      initialFlex: 3,
      cellRenderer: (params) => {
        if (
          params.value != null &&
          DistributorUserIDRequiredList.includes(params.value)
        ) {
          let asterikElement = (
            <>
              {params.value} <span className="asterik">*</span>
            </>
          );
          return asterikElement;
        } else return params.value;
      },
    },
  ];
  const onManageUserGridReadyHandler = (params) => {
    gridRef.current = params;
    gridRef?.current?.api?.showLoadingOverlay();
  };
  const setInitialRowData = (initialLoadData) => {
    // Create deep copy
    let updatedUserData = JSON.parse(
      JSON.stringify(
        distributorDataSet?.results?.filter((x) =>
          RequiredPlatformCategory.includes(x.platformCategories),
        ),
      ),
    );

    if (isEndUser) {
      updateUserData(endUserDistributors, updatedUserData);
    } else {
      updateUserData(initialLoadData, updatedUserData);
    }
    updatedUserData?.sort(distributorCompare);
    let initialData = updatedUserData?.map((data) => ({ ...data }));
    setUserInitialRowData(initialData ?? []);
  };

  const updateUserData = (distributors, userData) => {
    distributors.forEach((distUser) => {
      let matchedDistributorIndex = userData?.findIndex(
        (z) => z.distributorId === distUser.distributorId,
      );
      if (matchedDistributorIndex >= 0) {
        userData[matchedDistributorIndex].userId = distUser.userId;
      }
    });
  };

  const saveDistributor = async (postUserDistributor) => {
    try {
      setLoading(true);
      let postDistributorUserResponse = await httpService.post(
        Endpoints.distributorUserApi,
        postUserDistributor,
      );
      return postDistributorUserResponse;
    } catch (err) {
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    } finally {
      setLoading(false);
    }
  };
  const updateDistributor = async (postUserDistributor) => {
    try {
      setLoading(true);
      let postDistributorUserResponse = await httpService.update(
        Endpoints.distributorUserApi,
        postUserDistributor,
      );
      return postDistributorUserResponse;
    } catch (err) {
      Notify({
        alert: true,
        type: 'error',
        title: GenericError.somethingWentWrong,
      });
    } finally {
      setLoading(false);
    }
  };

  const afterUpdateAction = (displayMessage) => {
    if (!isEndUser) {
      getUsers();
      onAddEditModalClose();
    } else {
      setEndUserDistributors(userRowData);
    }
    resetInitialState();
    Notify({
      alert: true,
      type: 'success',
      title: displayMessage,
    });
  };

  const resetInitialState = () => {
    // Reset Initial state to current changes in data
    setUserInitialRowData([...userRowData]);
  };

  const saveEntitlements = (postData) => {
    setLoading(true);
    httpService
      .post(Endpoints.userentitlementsApi, postData)
      .then(() => {
        dispatch({
          type: ReduxAction.updateEntitlement,
          payload: {
            updatedEntitlements: [],
          },
        });
        afterUpdateAction('User Entitlements have been updated');
      })
      .catch(() => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      })
      .finally(() => setLoading(false));
  };
  const getAllRequiredEntitlement = (
    entitlementPostData,
    intialEntitlementData,
  ) => {
    let postDetailEntitlements =
      entitlementPostData?.userEntitlements?.length > 0
        ? entitlementPostData?.userEntitlements
        : [];
    //remove duplicate from  initial entitlement
    postDetailEntitlements.map((pd) => {
      let index = intialEntitlementData.findIndex(
        (ud) => ud.distributorId === pd.distributorId,
      );
      if (index >= 0) {
        intialEntitlementData.splice(index, 1);
      }
    });
    let allEntitlements = postDetailEntitlements
      .filter((x) => x.status === Active || x.status === PendingActive)
      .concat(
        intialEntitlementData.filter(
          (x) => x.status === Active || x.status === PendingActive,
        ),
      );
    return allEntitlements;
  };
  const validateData = (entitlementPostData, intialEntitlementData) => {
    let allEntitlements = getAllRequiredEntitlement(
      entitlementPostData,
      intialEntitlementData,
    );
    let requiredEntitlements = allEntitlements.filter((en) =>
      DistributorUserIDRequiredList.includes(en.distributor),
    );
    requiredEntitlements.map((re) => {
      let userDistributorData = userRowData.find(
        (ud) => ud.distributorId === re.distributorId,
      );
      re.isValidated =
        userDistributorData?.userId && userDistributorData?.userId?.trim()
          ? true
          : false;
    });
    return requiredEntitlements;
  };
  const getChangedDistributorUsers = () => {
    let updatedDistributorUsers = [];
    userRowData.map((du) => {
      let existingDistributorUsers = initialExUsers.filter(
        (x) => x.distributorId === du.distributorId,
      );
      if (existingDistributorUsers?.length > 0) {
        if (du.userId !== existingDistributorUsers[0].userId)
          updatedDistributorUsers.push(du);
      } else {
        if (du.userId) updatedDistributorUsers.push(du);
      }
    });
    return updatedDistributorUsers;
  };
  const saveOrUpdateDistributorUsers = async (postData) => {
    let flag = false;
    let postDataDistributorId = [];
    // flag for required user id
    getChangedDistributorUsers().forEach((ur) => {
      if (flag) return;

      if (!ur?.userId?.trim()) {
        if (DistributorUserIDRequiredList.includes(ur.distributor)) {
          flag = true;
          return;
        }
      }

      postDataDistributorId.push({
        userId: ur.userId,
        contactId: editData?.contactId,
        distributorId: ur.distributorId,
      });
    });

    if (flag) {
      return setErrordetails(
        'Please enter user id for ' + DistributorUserIDRequiredList.join(','),
      );
    }

    let postUserDistributor = {
      distributorUsers: postDataDistributorId,
      userId: UserId,
    };
    if (postUserDistributor.distributorUsers?.length > 0) {
      let distributorSaveResponse =
        initialExUsers?.length > 0
          ? await updateDistributor(postUserDistributor)
          : await saveDistributor(postUserDistributor);
      return distributorSaveResponse;
    } else if (postData?.userEntitlements?.length > 0) return true;
  };

  const updateEntitlementsWithDistributor = async () => {
    let entitlementDetails = store.getState()?.entitlementDetails;
    let postData = entitlementDetails?.entitlementsToUpdate;
    let initialEntitlementData = entitlementDetails?.initialEntitlements;

    if (postData || initialEntitlementData) {
      //validate required distributor
      let requiredEntitlements = validateData(postData, initialEntitlementData);
      if (requiredEntitlements.every((x) => x.isValidated)) {
        setErrordetails('');
        //call save or update dist user
        let distributorSaveResponse = await saveOrUpdateDistributorUsers(
          postData,
        );

        // save entitlements
        if (
          postData?.userEntitlements?.length > 0 ||
          entitlementDetails?.productsEntitlementsToUpdate?.length > 0
        ) {
          if (distributorSaveResponse) {
            if (entitlementDetails?.productsEntitlementsToUpdate?.length > 0) {
              if (!postData)
                postData = { userEntitlements: [], userId: UserId };
              entitlementDetails.productsEntitlementsToUpdate.forEach(
                (pEntitlements) => {
                  postData.userEntitlements.push(pEntitlements);
                },
              );
            }
            saveEntitlements(postData);
          }
        } else {
          afterUpdateAction('User ID details updated successfully');
        }
      } else {
        setErrordetails(
          'Please enter user id for ' + DistributorUserIDRequiredList.join(','),
        );
      }
    } else {
      if (endUserDistributors) {
        let requiredEntitlements =
          validateEndUserDistributors(endUserDistributors);
        if (requiredEntitlements.every((x) => x.isValidated)) {
          setErrordetails('');
          let updateUserResponse = await saveOrUpdateDistributorUsers();
          if (updateUserResponse) {
            afterUpdateAction('User ID details updated successfully');
          }
        } else {
          setErrordetails(
            'Please enter user id for ' +
              DistributorUserIDRequiredList.join(','),
          );
        }
      } else {
        //call save or update dist user
        let updateUserResponse = await saveOrUpdateDistributorUsers();
        if (updateUserResponse) {
          afterUpdateAction('User ID details updated successfully');
        }
      }
    }
  };

  const validateEndUserDistributors = (endUserDistributors) => {
    let requiredEntitlements = endUserDistributors.filter(
      (en) =>
        DistributorUserIDRequiredList.includes(en.distributor) &&
        distributorDataSet?.some((x) => x.distributorId == en.distributorId),
    );
    requiredEntitlements.map((re) => {
      let userDistributorData = userRowData.find(
        (ud) => ud.distributorId === re.distributorId,
      );
      re.isValidated =
        userDistributorData?.userId && userDistributorData?.userId?.trim()
          ? true
          : false;
    });
    return requiredEntitlements;
  };

  const distributorCompare = (current, next) => {
    if (current.distributor < next.distributor) {
      return -1;
    }
    if (current.distributor > next.distributor) {
      return 1;
    }
    return 0;
  };
  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    o1.every((k, index) =>
      Object.keys(k).every(
        (p) => (k[p] === '' && !o2[index][p]) || k[p] === o2[index][p],
      ),
    );
  const validateButtonDisable = (entitlementPostData) => {
    let postDetailEntitlements =
      entitlementPostData?.userEntitlements?.length > 0
        ? entitlementPostData?.userEntitlements
        : [];
    let managedUsers = distributorDataSet?.results?.filter((x) =>
      RequiredPlatformCategory.includes(x.platformCategories),
    );
    let requiredEntitlements = postDetailEntitlements.filter(
      (en) =>
        managedUsers?.some((md) => md.distributorId === en.distributorId) &&
        (!en.status || en.status === Active || en.status === PendingActive),
    );
    return requiredEntitlements?.length > 0 ? true : false;
  };

  const getFilteredEntitlementDistributors = (selectedEntitlements) => {
    let distributorId = [];
    selectedEntitlements?.forEach((el) => {
      if (
        el?.distributorId &&
        !distributorId.some((x) => x.distributorId == el.distributorId)
      ) {
        distributorId.push({
          distributorId: el.distributorId,
          userId: el.userId,
        });
      }
    });
    if (distributorId.length > 0) {
      let filteredEntitlementDistributor = [];
      userInitialRowData.forEach((obj) =>
        distributorId.forEach((idx) => {
          if (idx.distributorId === obj.distributorId) {
            if (isEndUser) obj.userId = idx.userId;
            filteredEntitlementDistributor.push(obj);
          }
        }),
      );

      return filteredEntitlementDistributor;
    }

    return [];
  };
  //useEffects start
  useEffect(() => {
    try {
      let entitlementDetails = store.getState()?.entitlementDetails;
      let selectedEntitlements = entitlementDetails?.selectedEntitlements;
      // Check for any selected entitlements and initial Grid Data in redux
      if (selectedEntitlements.length > 0 && userInitialRowData) {
        let filteredDistributors =
          getFilteredEntitlementDistributors(selectedEntitlements);
        // set for comparing, to detect changes
        setCurrentUserRowDataRedux(
          // Remove object memory reference
          filteredDistributors.map((x) => {
            return { ...x };
          }),
        );
        // set grid data
        setUserRowData([...filteredDistributors]);
        if (filteredDistributors?.length === 0)
          gridRef?.current?.api?.showNoRowsOverlay();
      }
    } catch (err) {}
  }, [useSelector((x) => x.entitlementDetails), userInitialRowData]);

  useEffect(() => {
    if (endUserDistributors && endUserDistributors.length > 0) {
      let filteredDistributors =
        getFilteredEntitlementDistributors(endUserDistributors);
      // set for comparing, to detect changes
      setCurrentUserRowDataRedux(
        // Remove object memory reference
        filteredDistributors.map((x) => {
          return { ...x };
        }),
      );
      // set grid data
      setUserRowData([...filteredDistributors]);
    }
  }, [userInitialRowData]);

  useEffect(() => {
    validateUserIdChange();
  }, [useSelector((x) => x.entitlementDetails), userRowData]);

  useEffect(() => {
    try {
      let selectedEntitlements =
        store.getState()?.entitlementDetails.selectedEntitlements;
      if (selectedEntitlements) {
        if (
          selectedEntitlements.length == 0 &&
          userInitialRowData &&
          userInitialRowData.length > 0
        ) {
          setUserRowData([]);
        }

        if (selectedEntitlements.length > 0 && !entitlementsAndUserIdsChanged) {
          if (currentUserRowDataRedux) {
            setUserRowData(
              getFilteredEntitlementDistributors(selectedEntitlements),
            );
          }
        }
      }
    } catch (err) {}
  }, [store.getState()?.entitlementDetails.selectedEntitlements]);

  useEffect(() => {
    if (
      editData?.contactId &&
      editData?.contactId != '-1' &&
      distributorDataSet
    ) {
      let queryParams = {
        pageSize: 5000,
        id: new Date().getTime(),
        filter: `contactId:"${editData?.contactId}"`,
      };
      httpService
        .get(Endpoints.distributorUserApi, queryParams)
        .then((res) => {
          if (res?.data?.results?.length > 0) {
            let managedUsers = res?.data?.results;
            managedUsers?.sort(distributorCompare);
            setInitialExUsers(managedUsers);
            setInitialRowData(managedUsers);
          } else {
            let managedUsers = distributorDataSet?.results?.filter((x) =>
              RequiredPlatformCategory.includes(x.platformCategories),
            );

            managedUsers?.sort(distributorCompare);
            let initialData = managedUsers?.map((data) => ({ ...data }));
            setUserInitialRowData(initialData ?? []);
            gridRef?.current?.api?.showNoRowsOverlay();
            setUserRowData([]);
          }
        })
        .catch((err) => {
          gridRef?.current?.api?.showNoRowsOverlay();
          Notify({
            alert: true,
            type: 'error',
            title: GenericError.somethingWentWrong,
          });
        });
    }
  }, [editData, distributorDataSet]);

  const validateUserIdChange = () => {
    try {
      let entitlementDetails = store.getState()?.entitlementDetails;
      let entitlementsPostData = entitlementDetails?.entitlementsToUpdate;
      let initialRowData = !currentUserRowDataRedux
        ? []
        : currentUserRowDataRedux;
      let modifiedRowData = !userRowData ? [] : userRowData;

      if (
        (entitlementsPostData?.userEntitlements?.length > 0 &&
          validateButtonDisable(entitlementsPostData)) ||
        !objectsEqual(modifiedRowData, initialRowData)
      ) {
        setEntitlementsAndUserIdsChanged(true);
      } else {
        setEntitlementsAndUserIdsChanged(false);
      }
    } catch (err) {}
  };

  const textLength = 9999;
  const unSelectInputText = (params, colId) => {
    if (params && colId && params.column.getColId() === colId) {
      if (params.column && params.rowIndex != null) {
        const cellElement = document.activeElement;
        if (cellElement && typeof cellElement.blur === 'function') {
          // Call the function to unselect all text
          cellElement.setSelectionRange(textLength, textLength);
        }
      }
    }
  };

  return (
    <>
      <div className="container-fluid pt-2-5">
        <Row>
          <Col className="mb-1">
            {userRowData?.length > 0 ? (
              <span className="email-preference-note-label">
                Please add User IDs against channel partners below
              </span>
            ) : (
              <div style={{ height: '1.1rem' }}></div>
            )}
          </Col>
        </Row>
        <Row>
          <div className="ag-grid-modal-content user-id-tab">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              columns={manageUserIdColumns}
              data={userRowData}
              onCellValueChanged={onInputsChanged}
              onGridReady={onManageUserGridReadyHandler}
              onCellEditingStarted={(e) => unSelectInputText(e, 'userId')}
            />
          </div>
        </Row>

        <Row className="modal-row">
          <div className="col-8 text-danger mt-2">{errordetails}</div>
          <div className="col-4">
            <Form>
              <Form.Group className="user-save-btn-body">
                <button
                  type="button"
                  disabled={!entitlementsAndUserIdsChanged}
                  className="user-save-btn btn btn-dark float-end btn-opacity"
                  onClick={updateEntitlementsWithDistributor}
                >
                  {editMode ? 'Update' : 'Save'}
                </button>
              </Form.Group>
            </Form>
          </div>
        </Row>
      </div>
    </>
  );
};

export default ManageUserIds;
