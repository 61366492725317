import React, { memo, useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow-icon.svg';
import cn from '../../helpers/cn';
import './multi-select-dropdown.scss';
import Loader from '../../components/Loader';

// Example
/* Call it into component
    <MultiSelectDropdown
          ddClassName="any Class Name"
          searchKeys={["label","label2"]}
          labelKeyName="label key from obj"
          ddOptionsKey="unique id key from obj"
          ddOptions={array of objects to show as options}
          setddOptions={set the objects as per checkbox}
          ddName={any unique name}
          ddPlaceHolder={ can be html, component,etc
            selectedCount === contractsResult.length
              ? "Contracts: All Contracts"
              : `Contracts: (${selectedCount})`
          }
          enableSearch={true}
          enableSelectAll={true}
          defaultSelectAll={false}
          /> */

const MultiSelectDropdown = memo(
  ({
    ddClassName,
    ddOptions,
    setddOptions,
    ddName,
    ddPlaceHolder,
    enableSearch = false,
    enableSelectAll = false,
    defaultSelectAll = false,
    searchKeys = [],
    labelKeyName,
    ddOptionsKey,
    disabled = false,
    ddDisabledMsg = '',
  }) => {
    const [searchText, setSearchText] = useState('');

    const checkboxRef = useRef();
    const callBoxState = () => {
      const reduceCallback = setTimeout(() => {
        if (ddOptions && ddOptions.length > 0 && checkboxRef.current)
          CheckBoxState();
      }, 300);
      return () => clearTimeout(reduceCallback);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const ResetSearchText = () => {
      enableSearch && setSearchText('');
      setTimeout(() => {
        if (ddOptions && ddOptions.length > 0 && checkboxRef.current)
          CheckBoxState();
      }, 100);
    };
    useEffect(callBoxState, [ddOptions, searchText]);

    const DropDownOptions = () => {
      try {
        let filteredResult =
          searchText?.length > 2 && searchKeys.length > 0
            ? ddOptions?.filter((dataSet) =>
                searchKeys.some((prop) =>
                  dataSet[prop]
                    .toString()
                    .toLowerCase()
                    .includes(searchText?.toLowerCase()),
                ),
              )
            : ddOptions;

        let element = document.getElementById(`${ddName}-selectAll`);
        if (filteredResult?.length === 0 && searchText?.length > 2) {
          element?.classList?.add('d-none');
        } else {
          element?.classList?.remove('d-none');
        }
        return !ddOptions ? (
          <h6 className="text-center pt-2">
            <Loader type="scaleLoaderDropdown" />
          </h6>
        ) : filteredResult?.length > 0 ? (
          filteredResult?.map((res, i) => {
            return (
              <div
                className={cn('add-entitlements-block', {
                  'opacity-50': res?.disabled,
                })}
                title={res?.disabled && ddDisabledMsg}
                key={`${ddName}_` + res.id}
              >
                <div className="add-entitlements-dropdown">
                  <input
                    type="checkbox"
                    id={`${ddName}_` + res.id}
                    className={
                      'search-box' +
                      (disabled ? ' checkbox-selection-disabled' : '')
                    }
                    checked={res?.checked}
                    onChange={onChangeSelection}
                    value={res[ddOptionsKey]}
                    disabled={disabled || res?.disabled}
                  />
                  <label
                    className="add-entitlements-title"
                    htmlFor={`${ddName}_` + res.id}
                  >
                    {res[labelKeyName]}
                  </label>
                </div>
              </div>
            );
          })
        ) : (
          filteredResult?.length === 0 &&
          ddOptions?.length >= 0 && (
            <h6 className="text-center pt-2">No results found</h6>
          )
        );
      } catch (err) {}
    };

    const onChangeSelection = (e) => {
      const { defaultValue, checked, id } = e.target;

      let newState = [...ddOptions];
      if (defaultValue === 'Select All') {
        // when select all
        newState = newState?.map((obj, i) => {
          if (searchText?.length > 2) {
            if (
              searchKeys.some((prop) =>
                obj[prop]
                  .toString()
                  .toLowerCase()
                  .includes(searchText?.toLowerCase()),
              )
            ) {
              // when select all and search combine
              return { ...obj, checked: checked };
            } else {
              // when select all but search not matched
              return { ...obj };
            }
          } else {
            // when select all without search
            return { ...obj, checked: checked };
          }
        });
      } else {
        // when single obj selection
        let objId = id.replace(`${ddName}_`, '');
        let index = newState.findIndex((x) => String(x.id) === String(objId));
        newState[index].checked = checked;
      }
      setddOptions(newState);
    };

    const CheckBoxState = () => {
      let checked;
      let indeterminate;
      let newOptions =
        enableSearch && searchText?.length > 2 && searchKeys.length > 0
          ? ddOptions?.filter((dataSet) =>
              searchKeys?.some((prop) =>
                dataSet[prop]
                  .toString()
                  .toLowerCase()
                  .includes(searchText?.toLowerCase()),
              ),
            )
          : ddOptions;
      if (newOptions.length === 0) {
        // When no filter data
        checked = false;
        indeterminate = false;
      } else {
        if (!newOptions?.some((el) => el.checked === false)) {
          // When select all
          checked = true;
          indeterminate = false;
        } else if (
          newOptions?.some((el) => el.checked === false) &&
          newOptions?.some((el) => el.checked === true)
        ) {
          // when any one is selected
          checked = true;
          indeterminate = true;
        } else if (newOptions?.every((el) => el.checked === false)) {
          // when nothing is selected
          checked = false;
          indeterminate = false;
        }
      }
      // Set value as per condition
      checkboxRef.current.checked = checked;
      checkboxRef.current.indeterminate = indeterminate;
    };

    return (
      <>
        <Dropdown
          className={cn('entitlements-wrapper', ddClassName)}
          onToggle={ResetSearchText}
        >
          <Dropdown.Toggle
            variant="light"
            className="bg-white w-100 text-start shadow-sm"
          >
            {ddPlaceHolder} <DownArrow />
          </Dropdown.Toggle>
          <Dropdown.Menu className="m-0">
            {enableSearch && (
              <div className="add-entitlements-block" key={'-1'}>
                <div className="add-entitlements-dropdown">
                  <input
                    className="w-100 px-2 py-1 search-box"
                    type="text"
                    id="dd-search-input"
                    placeholder="Search"
                    value={searchText}
                    onChange={({ target }) => setSearchText(target.value)}
                    autoComplete="off"
                  />
                </div>
              </div>
            )}
            {enableSelectAll && (
              <div
                id={`${ddName}-selectAll`}
                className={
                  ddOptions?.length > 0
                    ? 'add-entitlements-block'
                    : 'add-entitlements-block d-none'
                }
                key={'-2'}
              >
                <div className="add-entitlements-dropdown">
                  <input
                    ref={checkboxRef}
                    type="checkbox"
                    id={`${ddName}`}
                    className={
                      'search-box' +
                      (disabled ? ' checkbox-selection-disabled' : '')
                    }
                    defaultValue={'Select All'}
                    // defaultChecked={defaultSelectAll}
                    onChange={onChangeSelection}
                    disabled={disabled}
                  />
                  <label
                    className="add-entitlements-title"
                    htmlFor={`${ddName}`}
                  >
                    Select All
                  </label>
                </div>
              </div>
            )}
            <DropDownOptions />
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  },
  (op, np) =>
    op?.ddOptions === np?.ddOptions && op?.ddPlaceHolder === np?.ddPlaceHolder,
);

export default MultiSelectDropdown;
