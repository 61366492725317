import React, { memo, useEffect, useRef, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import ModalBox from './../../sharedcomponents/modal-box/ModalBox';
import httpService from '../../services/http-service';
import {
  Endpoints,
  GenericError,
  UserId,
  DistributorUserIDRequiredList,
} from '../../constants';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import { debounce } from '../../helpers/debounce';

// NOTE: This component is common for Add Entitlement & Add User Group
const DistributorUserIdModal = memo(
  ({
    filteredDistributorUserIdData,
    handleEntitlementSave,
    handleClose,
    isUserGroupComp = false,
  }) => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errordetails, setErrordetails] = useState('');

    useEffect(() => {
      const reduceCallback = setTimeout(() => {
        setRowData(filteredDistributorUserIdData);
      }, 1000);
      return () => clearTimeout(reduceCallback);
    }, [filteredDistributorUserIdData]);

    const onInputsChanged = (params) => {
      let index = rowData.findIndex(
        (x) =>
          x.distributorId === params.data?.distributorId &&
          x.contactId === params.data?.contactId,
      );
      if (index >= 0) {
        rowData[index]['userId'] = params.newValue;
      }
      setRowData([...rowData]);
    };

    const defaultConfig = {
      rowModelType: 'clientSide',
      isExportCSV: false,
      isExportExcel: false,
      pivotPanelShow: '',
      pagination: false,
      isAutoSizeColums: true,
      enableCharts: false,
      sideBar: false,
      rowGroupPanelShow: false,
    };

    const gridRef = useRef();
    const defaultColdef = {
      sortable: true,
      resizable: true,
      menuTabs: ['generalMenuTab', 'filterMenuTab'],
      enableValue: false,
      enableRowGroup: false,
    };

    const columns = [
      {
        headerName: 'Users',
        field: 'contactData',
        tooltipField: 'contactData',
        initialFlex: 3,
      },
      {
        headerName: 'Delivery Platform',
        field: 'distributorData',
        tooltipField: 'distributorData',
        initialFlex: 3,
      },
      {
        headerName: 'Status',
        field: 'status',
        tooltipField: 'status',
        initialFlex: 2,
        hide: isUserGroupComp,
      },
      {
        headerName: 'User Id',
        headerTooltip: 'User Id',
        field: 'userId',
        tooltipField: 'userId',
        initialFlex: 4,
        singleClickEdit: true,
        editable: true,
        cellRenderer: (params) => {
          return (
            <div className="aggrid-input-border">
              {params.data?.userId ? params.data.userId : ''}
            </div>
          );
        },
        menuTabs: ['generalMenuTab'],
        cellClass: 'aggrid-input-wrapper',
      },
    ];

    const onSourceUserGridReadyHandler = (params) => {
      gridRef.current = params;
      params.api.showLoadingOverlay();
    };

    const saveDistributor = async (postUserDistributor) => {
      try {
        setLoading(true);
        let postDistributorUserResponse = await httpService.post(
          Endpoints.distributorUserApi,
          postUserDistributor,
        );
        setLoading(false);
        return postDistributorUserResponse;
      } catch (err) {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      } finally {
        setLoading(false);
      }
    };

    const handleUserIdSave = async () => {
      if (rowData.every((x) => x.userId)) {
        setErrordetails('');
        let postUserDistributor = {
          distributorUsers: rowData.map((ur) => {
            return {
              userId: ur.userId,
              contactId: ur.contactId,
              distributorId: ur.distributorId,
            };
          }),
          userId: UserId,
        };
        let postDistributorUserResponse = await saveDistributor(
          postUserDistributor,
        );
        if (postDistributorUserResponse) {
          handleEntitlementSave();
        }
      } else {
        setErrordetails(
          'Please enter user id for ' + DistributorUserIDRequiredList.join(','),
        );
      }
    };

    const textLength = 9999;
    const unSelectInputText = (params, colId) => {
      if (params && colId && params.column.getColId() === colId) {
        if (params.column && params.rowIndex != null) {
          const cellElement = document.activeElement;
          if (cellElement && typeof cellElement.blur === 'function') {
            // Call the function to unselect all text
            cellElement.setSelectionRange(textLength, textLength);
          }
        }
      }
    };

    return (
      <>
        <ModalBox
          backdrop="static"
          size="xl"
          showLoader={loading}
          show={filteredDistributorUserIdData?.length > 0}
          handleClose={handleClose}
          showHeader={true}
          modalTitle={
            <>
              <span className="edit-user-subtitle">
                {`Please enter User Id for below user${
                  filteredDistributorUserIdData?.length > 1 ? 's' : ''
                }`}
              </span>
            </>
          }
        >
          <div className="container">
            <div className="row">
              <div
                className={`col ag-grid-table ${
                  isUserGroupComp && 'user-group'
                }`}
              >
                <AgGridComponent
                  config={defaultConfig}
                  defaultColumnDef={defaultColdef}
                  data={rowData}
                  suppressRowClickSelection={false}
                  columns={columns}
                  onCellValueChanged={onInputsChanged}
                  onCellEditingStarted={(e) => unSelectInputText(e, 'userId')}
                  onGridReady={onSourceUserGridReadyHandler}
                />
              </div>
            </div>
            <Row className="modal-row">
              <div className="col-8 text-danger mt-2">{errordetails}</div>
              <div className="col-4">
                <Form>
                  <Form.Group className="entitlements-userid-save-btn-body">
                    <button
                      type="button"
                      onClick={handleUserIdSave}
                      className="btn btn-dark float-end btn-opacity"
                    >
                      Save
                    </button>
                  </Form.Group>
                </Form>
              </div>
            </Row>
          </div>
        </ModalBox>
      </>
    );
  },
  (op, np) =>
    op?.filteredDistributorUserIdData === np?.filteredDistributorUserIdData,
);

export default DistributorUserIdModal;
