import { ReduxAction } from './../../constants';
const initialState = {};

const entitlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.selectedEntitlementUser: {
      return {
        ...state,
        users: action.payload,
      };
    }
    default:
      return state;
  }
};
export default entitlementReducer;
