import axios from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Axios: Something went wrong"
    )
);

//const authAxios = axios.create();

// authAxios.interceptors.request.use(
//   config => {
//     if (!config.headers.Authorization) {
//       config.headers.Authorization = `Bearer ${authContext.getAccessToken()}`;
//     }

//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   },
// );

export default axiosInstance;
