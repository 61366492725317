import React from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh.svg';
import SPG_logo from '../../assets/img/logo/sp-global-white.svg';
import { Verbiage } from '../../constants';
import { ReactComponent as LockIcon } from './../../assets/icons/common/lock.svg';
import ImageComponent from './../../sharedcomponents/Image/ImageComponent';
import buildUrl from './../../utils/build-url';
import HtmlContent from './../../sharedcomponents/html-content/html-content';
// Interface TileProps {
//     BG_img: String;
//     tile_title: String;
//     tile_description: String;
//     tile_main_heading: String;
//     recentlyAdded: boolean;
//     recentlyEnhanced: boolean;
// }

const Tiles = (props) => {
  const {
    BG_img,
    tile_title,
    tile_description,
    tile_main_heading,
    recentlyAdded,
    recentlyEnhanced,
    tile_disabled = false,
    key,
  } = props.tile_details;

  const Navigate = useNavigate();
  const Img_URL =
    props.tile_details.entityType === 'distributor' ||
    props.tile_details.entityType === 'package'
      ? props?.tile_details?.BG_img
      : `${getStaticFilesUrl(window.location.origin)}Datasets/${BG_img}/${
          BG_img + '.jpg'
        }`;

  function getStaticFilesUrl(key) {
    let env = 'https://dev-images.developer.platts.com/';
    if (
      key === 'https://prod-us.developer.spglobal.com' ||
      key === 'https://prod-eu.developer.spglobal.com' ||
      key === 'https://prod.developer.spglobal.com' ||
      key === 'https://developer.spglobal.com'
    ) {
      env = 'https://prod-images.developer.platts.com/';
    } else if (
      key === 'https://qa-us.developer.spglobal.com' ||
      key === 'https://qa-eu.developer.spglobal.com' ||
      key === 'https://qa.developer.spglobal.com'
    ) {
      env = 'https://qa-images.developer.platts.com/';
    }
    return env;
  }

  const NavigateToDatasetDetailPage = () => {
    const datasetPathSegment = buildUrl(
      props.tile_details.name,
      props.tile_details.id,
    );
    if (props.tile_details.entityType === 'distributor') {
      Navigate(`delivery-platform-details/${datasetPathSegment}`, {
        state: props,
      });
    } else {
      Navigate(`package-details/${datasetPathSegment}`, { state: props });
    }
  };

  return (
    <Card
      className="card-default"
      title={tile_disabled ? Verbiage.tileDisabledTooltip : ''}
      key={key}
      id={tile_main_heading + '_' + props?.tile_details?.id}
    >
      {recentlyAdded?.toLowerCase() === 'true' && (
        <div className="recent-added">
          <PlusIcon />
          <span>Recently Added</span>
        </div>
      )}
      {recentlyEnhanced?.toLowerCase() === 'true' && (
        <div className="recent-enhanced">
          <RefreshIcon />
          <span>Recently Enhanced</span>
        </div>
      )}
      <div className="tile-content">
        {tile_title?.length > 1 && (
          <>
            <div className="tile-header">{tile_title} </div>
            <span className="tile-border"></span>
          </>
        )}
        <span className="tile-main-header line-clamp">
          {tile_main_heading}{' '}
        </span>
        <img src={SPG_logo} alt="" className="img-fluid spg_logo" />
        <div
          className={`image-gradient ${
            props.tile_details.entityType === 'distributor'
              ? 'image-gradient-background-2'
              : 'image-gradient-background'
          }`}
        >
          {tile_disabled && (
            <div className="tile-disabled">
              <LockIcon />
            </div>
          )}
          <ImageComponent
            src={Img_URL}
            alt=""
            className="tile-bg-img img-fluid"
          />
        </div>
        {/* <img src={round_tick} className="round_tick" /> */}
      </div>
      <div className="tile-flip">
        <div className="tile-flip-body">
          <span className="tile-flip-description">
            {tile_description && <HtmlContent html={tile_description} />}
          </span>
        </div>

        {/* <Link to={{
                    pathname: '/cdiet',
                    state: { props }
                }} > */}
        <button className="btn-dataset" onClick={NavigateToDatasetDetailPage}>
          View{' '}
          {props.tile_details.entityType === 'distributor'
            ? 'Solution'
            : 'dataset'}
        </button>
        {/* </Link> */}
      </div>
    </Card>
  );
};

export default Tiles;
