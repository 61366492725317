import React from 'react';
import { useLocation } from 'react-router-dom';
import { UrlPath } from './../constants';

const Content = ({ children }) => {
  const location = useLocation();
  return (
    <div
      className={
        location?.pathname?.includes(UrlPath.userDashboard)
          ? ''
          : 'content mx-lg-3'
      }
    >
      <div
        className={
          location?.pathname?.includes(UrlPath.userDashboard)
            ? ''
            : 'container-fluid'
        }
      >
        {children}
      </div>
    </div>
  );
};

export default Content;
