import React from 'react';
import LoginButton from './LoginButton';

import './Logout.scss';

const Logout = () => {
  return (
    <div className="logout-contanier">
      <h3 className="logout-text">
        You have been successfully logged out of SFS Application
      </h3>
      <LoginButton />
    </div>
  );
};

export default Logout;
