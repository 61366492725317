/**
 *
 * Usage
 *
 * cn('foo', 'bar'); // => 'foo bar'
 * cn('foo', { bar: true }); // => 'foo bar'
 * cn({ foo: true }, { bar: true }); // => 'foo bar'
 * cn({ foo: true, bar: true }); // => 'foo bar'
 *
 * Dynamic class names
 *
 * let buttonType = 'primary';
 * cn({ [`btn-${buttonType}`]: true });
 *
 *  Conditional class name based on a boolean variable
 *
 * let addActiveClass = true;
 * className={cn('foo', {
 *  'foo--active': addActiveClass
 * })}
 *
 */

import cn from 'classnames';

export default cn;
