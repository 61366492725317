import React, { useState, useRef, useEffect } from 'react';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import Loader from '../../components/Loader';
import { ReactComponent as AddContractsIcon } from './../../assets/icons/contracts/add-contracts.svg';
import { Verbiage } from './../../constants';

const OnboardContracts = () => {
  const [loading, setLoading] = useState(true);
  const [onboardContractsRowData, setOnboardContractsRowData] = useState();
  const gridRef = useRef();

  const defaultConfig = {
    rowModelType: 'clientSide',
    sortable: true,
    resizable: true,
    isExportCSV: false,
    isExportExcel: false,
    sideBar: false,
    rowGroupPanelShow: false,
    pagination: false,
    overlayNoRowsTemplate: Verbiage.noData,
  };
  const defaultColdef = {
    resizable: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const columnFilterParams = {
    filter: 'agSetColumnFilter',
    sortable: true,
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };

  const onboardContractsColumns = [
    {
      headerName: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      headerTooltip: 'Contract ID',
      lockPosition: true,
      initialFlex: 2,
    },
    {
      headerName: 'Accounts',
      field: 'accountName',
      tooltipField: 'accountName',
      headerTooltip: 'Accounts',
      initialFlex: 3,
    },
    {
      headerName: 'Onboard Status',
      field: 'onboardStatus',
      tooltipField: 'onboardStatus',
      headerTooltip: 'Onboard Status',
      initialFlex: 2,
    },
    {
      headerName: 'Onboarding Date',
      headerTooltip: 'Onboarding Date',
      field: 'onboardingDate',
      tooltipField: 'onboardingDate',
      initialFlex: 2,
    },
    {
      headerName: 'Onboarded By',
      headerTooltip: 'Onboarded By',
      field: 'onboardedBy',
      tooltipField: 'onboardedBy',
      initialFlex: 2,
    },
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    setTimeout(() => {
      gridRef?.current?.api?.hideOverlay();
    }, 50);
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="row mb-1">
            <div className="col-12">
              <span className="page-header">Onboard Contracts</span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <input
                type="text"
                id="search-filter-input"
                className="form-control search-box"
                placeholder="Search by contract or accounts"
                onInput={''}
              />
            </div>
            <div className="col action-item-bar">
              <AddContractsIcon
                className="icon-active cursor-pointer"
                alt="Add Contracts"
                width="23"
                title="Add Contracts"
                onClick={''}
              ></AddContractsIcon>
            </div>
          </div>
          <div className="ag-grid-table mb-2 mt-2 history">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              data={onboardContractsRowData}
              columns={onboardContractsColumns}
              onGridReady={onGridReadyHandler}
            />
          </div>
        </>
      )}
    </>
  );
};

export default OnboardContracts;
