// Create Unique Array of object with given key
export const arrayObjUniqueByKey = (array, key) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

// ***** EXAMPLE *****

// const array =
//   [
//     { "name": "Joe", "age": 17 },
//     { "name": "Bob", "age": 17 },
//     { "name": "Carl", "age": 35 }
//   ]

// const key = 'age';

/*OUTPUT
       [
        { "name": "Bob", "age": 17 },
        { "name": "Carl", "age": 35 }
       ]
   */
