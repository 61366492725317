import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PersistReduxAction, Verbiage } from '../../constants';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import {
  getFilterColumns,
  getGroupByColumns,
  getSortColumns,
} from '../../sharedcomponents/ag-grid/ag-grid-helper';
import CheckedIcon from './../../assets/icons/common/checked.png';
import { ReactComponent as CloneIcon } from './../../assets/icons/common/clone.svg';
import { ReactComponent as CloudIcon } from './../../assets/icons/common/cloud.svg';
import { ReactComponent as DeleteIcon } from './../../assets/icons/common/delete.svg';
import { ReactComponent as ExportIcon } from './../../assets/icons/common/export.svg';
import UncheckedIcon from './../../assets/icons/common/unchecked.png';
import { ReactComponent as AddUserIcon } from './../../assets/icons/users/add-user.svg';
import Loader from './../../components/Loader';
import { Endpoints, UrlPath, loaderStyle } from './../../constants';
import { persistDataStore } from './../../redux/persistStore';
import { Notify } from './../../sharedcomponents/Alert/Notify';
import AddEditUser from './add-edit-user/add-edit-user';
import BulkUploadUsers from './bulk-upload-users/bulk-upload-users';
import CloneUserEntitlements from './clone-user-entitlements/clone-user-entitlements';
import './user-details.scss';

const UserDetails = ({ contracts, accounts }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showBulkUsers, setShowBulkUsers] = useState(false);
  const [showCloneUserEntitlements, setShowCloneUserEntitlements] =
    useState(false);
  const [editData, setEditData] = useState();
  const [cloneToUser, setCloneToUser] = useState();
  const [cloneEntitlementData, setCloneEntitlementData] = useState();
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseBulkUers = () => setShowBulkUsers(false);
  const handleShowBulkUsers = () => setShowBulkUsers(true);

  const handleCloseCloneUserEntitlements = () => {
    setShowCloneUserEntitlements(false);
    setCloneEntitlementData();
    setCloneToUser();
  };
  const handleCloneUserEntitlementsSuccess = () => {
    setCloneToUser();
    gridRef.current.api.onFilterChanged();
  };

  const gridRef = useRef();
  const navigate = useNavigate();

  const isFirstColumn = (params) => {
    let displayedColumns = params.api.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const isSameOrFirstColumn = (params) => {
    // Show checkbox on each row of first column and on grouped, show it only on grouped columns
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let rowGroupCheckBox = !params?.node?.group;
    let showCheckBox =
      // rowGroupCheckBox &&
      displayedColumns[params?.node?.level] === params?.column;
    return showCheckBox;
  };

  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    suppressDragLeaveHidesColumns: true,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: true,
    overlayNoRowsTemplate:
      contracts && contracts.some((x) => x.checked)
        ? Verbiage.users
        : Verbiage.noData,
  };

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      contractChangeHandler();
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const columnFilterParams = {
    filter: 'agTextColumnFilter',
    // filterParams: {
    //   buttons: ['reset', 'apply'],
    //   maxNumConditions: 1,
    // },
    enableRowGroup: false,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
  };

  const defaultColdef = {
    ...columnFilterParams,
    sortable: true,
    resizable: true,
    checkboxSelection: isSameOrFirstColumn,
    headerCheckboxSelection: isFirstColumn,
  };

  const columns = [
    {
      headerName: '',
      field: 'contractNumber',
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          'inRange',
          {
            displayKey: 'inRange',
            displayName: 'Range Between',
            predicate: ([filterValue], cellValue) => {
              return cellValue == null || cellValue < filterValue;
            },
          },
        ],
      },
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Email ID',
      headerTooltip: 'Email ID',
      field: 'email',
      initialFlex: 3,
      lockPosition: true,
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <div
              className="email-label"
              title={params.value}
              onClick={() => {
                setEditData(params.data);
                setShow(true);
              }}
            >
              {params.value}
            </div>
          );
        } else return '';
      },
    },
    {
      headerName: 'Name',
      headerTooltip: 'Name',
      field: 'contactName',
      tooltipField: 'contactName',
      initialFlex: 3,
    },
    {
      headerName: 'Account',
      headerTooltip: 'Account',
      field: 'account',
      tooltipField: 'account',
      initialFlex: 4,
    },
    {
      headerName: 'Location',
      headerTooltip: 'Location',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
      initialFlex: 5,
    },
    {
      headerName: 'Job Function',
      headerTooltip: 'Job Function',
      field: 'jobFunction',
      tooltipField: 'jobFunction',
      initialFlex: 3,
    },
    {
      headerName: 'Role',
      headerTooltip: 'Role',
      field: 'designation',
      tooltipField: 'designation',
      initialFlex: 3,
      initialHide: true,
    },
    {
      headerName: 'Department',
      headerTooltip: 'Department',
      field: 'department',
      tooltipField: 'department',
      initialFlex: 3,
      initialHide: true,
    },
    {
      headerName: 'Email Marketing',
      headerTooltip: 'Email Marketing',
      field: 'isEmailOptOut',
      tooltipField: 'isEmailOptOutTooltip',
      cellRenderer: ({ data }) => {
        if (data?.isEmailOptOut !== 1) {
          return <img src={CheckedIcon} className="width-20" />;
        } else {
          return '';
        }
      },
      initialFlex: 3,
      initialHide: true,
      menuTabs: ['generalMenuTab', 'columnsMenuTab'],
    },
    {
      headerName: 'Email Processed',
      headerTooltip: 'Email Processed',
      field: 'emailProcessed',
      tooltipField: 'emailProcessed',
      initialFlex: 3,
      initialHide: true,
    },
    {
      headerName: 'Contract ID',
      headerTooltip: 'Contract ID',
      field: 'contractNumber',
      tooltipField: 'contractNumber',
      initialFlex: 3,
    },
    {
      headerName: 'Group Name',
      headerTooltip: 'Group Name',
      field: 'groupName',
      tooltipField: 'groupName',
      initialFlex: 3,
      cellRenderer: (params) => {
        if (params.value != null) {
          return (
            <div
              className="email-label"
              title={params.value}
              onClick={() => {
                // Redirect to user group page and open edit group window
                navigate(UrlPath.userGroup, {
                  state: { userGroupAction: params.data },
                });
              }}
            >
              {params.value}
            </div>
          );
        } else return '';
      },
    },
    {
      headerName: 'Entitled',
      headerTooltip: 'Entitled',
      field: 'entitled',
      tooltipField: 'entitled',
      initialFlex: 2,
      cellRenderer: ({ data }) => {
        if (data?.entitled === 'Yes') {
          return <img src={CheckedIcon} className="width-20" />;
        } else return '';
      },
    },
  ];
  const getRowId = (params) => {
    if (params?.data && params.data.contactId && params.data.contractNumber) {
      return 'user-' + params.data.contactId + '-' + params.data.contractNumber;
    }
  };

  const gridStyle = { height: '83vh' };

  const [buttonIcons, setButtonIcons] = useState({
    CloneIcon: false,
    DeleteIcon: false,
    ExportIcon: false,
    UserGroupIcon: false,
  });

  // set input to CSA selected user
  useEffect(() => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    if (
      csaActionData?.type &&
      csaActionData?.value &&
      csaActionData?.type === 'user'
    ) {
      setInputValue(csaActionData?.value);
    }
    if (
      csaActionData?.type &&
      csaActionData?.type === 'account' &&
      csaActionData?.data?.account
    ) {
      setInputValue(csaActionData?.data?.account);
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    if (!searchText || searchText.length > 2) {
      gridRef.current.api.onFilterChanged();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;
    gridRef.current.api.setGridOption(
      'serverSideDatasource',
      getServerSideDatasource(),
    );
  };

  const getServerSideDatasource = () => {
    return {
      getRows: (params) => {
        try {
          let AgParams = params?.request;
          let contractFilterModel =
            AgParams?.filterModel['contractNumber']?.filter;

          if (
            contractFilterModel?.length > 0 &&
            contractFilterModel !== 'NoContractSelected'
          ) {
            let FilterColumns =
              'email,contactName,contractNumber,account,officeLocation,jobFunction,entitled,groupName,emailProcessed,department,designation';
            let searchText = document.getElementById(
              'user-search-filter-input',
            ).value;
            let queryParams = {
              Filter: getFilterColumns(AgParams),
              FilteredColumns: JSON.stringify(
                gridRef.current.api.getFilterModel(),
              ),
              Field:
                'contactName,contactId,groupid,groupName,contractNumber,email,phone,department,jobFunction,designation,isEmailOptOut,emailProcessed,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
              Sort: getSortColumns(
                AgParams,
                'modifiedDate:desc,contactId:desc,contractNumber:asc',
              ),
              PageSize: AgParams.endRow - AgParams.startRow,
              Page:
                AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
              groupBy: getGroupByColumns(AgParams),
              querySearch: searchText,
              searchColumns: searchText ? FilterColumns : '',
              id: String(Date.now()),
            };
            gridRef.current.api.showLoadingOverlay();
            httpService
              .get(Endpoints.userApi, queryParams)
              .then(({ data }) => {
                let rowData = data?.results ?? data?.aggResultValue;

                // new key created for tooltip
                rowData = rowData.map((el) => {
                  el.isEmailOptOutTooltip =
                    el.isEmailOptOut !== 1 ? 'Yes' : 'No';
                  return { ...el };
                });

                params.success({
                  rowData: rowData,
                  rowCount: data?.metadata.count,
                });

                // show user details if CSA selected a user from CSA dashboard
                let ccaData =
                  persistDataStore.getState()?.userActionData
                    ?.csaUserActionInfo;
                if (
                  ccaData &&
                  ccaData.type === 'user' &&
                  ccaData.showUserDetails
                ) {
                  setEditData(rowData[0]);
                  setShow(true);
                  ccaData.showUserDetails = false;
                  persistDataStore.dispatch({
                    type: PersistReduxAction.csaActionData,
                    payload: ccaData,
                  });
                }

                if (rowData?.length === 0)
                  gridRef.current.api.showNoRowsOverlay();
                else {
                  gridRef.current.api.hideOverlay();
                }
              })
              .catch(() => {
                params.fail();
                gridRef.current.api.hideOverlay();
              });
          } else {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            if (contractFilterModel === 'NoContractSelected') {
              gridRef.current.api.showNoRowsOverlay();
            }
          }
        } catch (err) {
          params.fail();
        }
      },
    };
  };

  const contractChangeHandler = () => {
    if (gridRef.current && contracts && contracts.length > 0) {
      let filteredContracts = contracts.filter((x) => x.checked);
      let filterValue = {
        filter:
          filteredContracts.length !== 0
            ? filteredContracts
                .map((t) =>
                  t.contractNumber ? '"' + t.contractNumber + '"' : '',
                )
                .join(',')
            : 'NoContractSelected',
        filterType: 'text',
        type: 'inRange',
      };

      gridRef.current.api.setFilterModel(
        getAllFilterModel('contractNumber', filterValue),
      );
    } else {
      gridRef.current.api.showNoRowsOverlay();
    }
  };

  const getAllFilterModel = (colId, newModel) => {
    let currentModel = gridRef.current.api.getFilterModel();
    currentModel[colId] = newModel;
    return currentModel;
  };

  const getUsers = () => {
    gridRef.current.api.onFilterChanged();
    gridRef.current.api.ensureIndexVisible(0, 'top');
  };

  const onSelectionChanged = (elements) => {
    let rowLength = elements.api.getSelectedRows().length;
    setButtonIcons({
      ...buttonIcons,
      CloneIcon: rowLength > 0,
      DeleteIcon: rowLength > 0,
      ExportIcon: rowLength > 0,
      UserGroupIcon: rowLength > 1,
    });
  };

  const onSelectionChangedDebounce = debounce(onSelectionChanged, 100);

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };
  const exportUserCSV = () => {
    if (buttonIcons.ExportIcon) {
      gridRef.current.api.exportDataAsCsv({
        onlySelected: true,
        fileName: 'User_' + getDate(),
      });
    }
  };

  const openDeletedSuccess = (title) => {
    Notify({
      alert: true,
      type: 'success',
      title: title,
    });
  };

  const deleteUsers = () => {
    let deleteUserIds = [];
    let selectedUsers = gridRef.current.api.getSelectedRows();
    if (selectedUsers && selectedUsers.length > 0) {
      selectedUsers.map((user) => deleteUserIds.push(user.contactId));
      let deleteSuccessTitle =
        deleteUserIds.length > 1 ? 'Users Deleted' : 'User Deleted';

      setLoading(true);
      httpService
        .delete(Endpoints.userApi, deleteUserIds)
        .then(({ data }) => {
          if (data) {
            openDeletedSuccess(deleteSuccessTitle);
            gridRef.current.api
              .getSelectedNodes()
              .forEach((node) => node.setSelected(false));
            gridRef.current.api.onFilterChanged();
          }
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    }
  };

  const openStatusModal = (title) => {
    Notify({
      alert: true,
      type: 'warning',
      title: title,
      confirmBtnText: 'YES DELETE',
      cancelBtnText: 'NO, KEEP IT',
      onConfirm: () => deleteUsers(),
    });
  };

  const getDeletedTitle = () => {
    let selectedUsers = gridRef.current.api.getSelectedRows();
    if (selectedUsers && selectedUsers.length > 0) {
      switch (true) {
        case selectedUsers.length === 1 &&
          selectedUsers.every((us) => us.entitled === 'Yes'):
          return 'This user has active entitlements , are you sure you want to delete the user?';
        case selectedUsers.length === 1 &&
          selectedUsers.every((us) => us.entitled === 'No'):
          return 'Are you sure you want to permanently delete the user?';
        case selectedUsers.length > 1 &&
          selectedUsers.every((us) => us.entitled === 'Yes'):
          return 'All users have active entitlements , are you sure you want to delete the users?';
        case selectedUsers.length > 1 &&
          selectedUsers.every((us) => us.entitled === 'No'):
          return 'Are you sure you want to permanently delete the users?';
        default:
          return 'Some users have active entitlements , are you sure you want to delete the user?';
      }
    }
    return '';
  };

  const deleteClickHandler = () => {
    if (buttonIcons.DeleteIcon) {
      let title = getDeletedTitle();
      openStatusModal(title);
    }
  };

  const cloneClickHandler = () => {
    if (buttonIcons.CloneIcon) {
      let selectedUsers = gridRef.current.api.getSelectedRows();
      if (
        !selectedUsers.every(
          (x) => x.contractNumber === selectedUsers[0].contractNumber,
        )
      ) {
        Notify({
          alert: true,
          type: 'warning',
          title:
            'Please select users from the same contract number to begin cloning',
          confirmBtnText: 'OK',
        });
      } else if (selectedUsers.some((x) => x.groupId)) {
        Notify({
          alert: true,
          type: 'warning',
          title: 'User belongs to group will not be allowed for clone',
          confirmBtnText: 'OK',
        });
      } else {
        setShowCloneUserEntitlements(true);
      }
    }
  };

  const handleCloneFromEditUser = (newData) => {
    setShowCloneUserEntitlements(true);
    setCloneToUser([newData]);
  };

  return (
    <>
      {show && (
        <AddEditUser
          show={show}
          contracts={contracts}
          handleClose={handleClose}
          editData={editData}
          setEditData={setEditData}
          getUsers={getUsers}
          accounts={accounts}
          handleCloneFromEditUser={(p) => handleCloneFromEditUser(p)}
          setCloneEntitlementData={(o) => setCloneEntitlementData(o)}
        />
      )}
      {showBulkUsers && (
        <BulkUploadUsers
          showBulkUsers={showBulkUsers}
          handleCloseBulkUers={handleCloseBulkUers}
          contracts={contracts}
          accounts={accounts}
          getUpdatedUsers={getUsers}
        />
      )}
      {showCloneUserEntitlements && (
        <CloneUserEntitlements
          showCloneUserEntitlements={showCloneUserEntitlements}
          handleCloseCloneUserEntitlements={handleCloseCloneUserEntitlements}
          handleCloneUserEntitlementsSuccess={
            handleCloneUserEntitlementsSuccess
          }
          selectedToUsers={
            cloneToUser ? cloneToUser : gridRef.current.api.getSelectedRows()
          }
          cloneEntitlementData={cloneEntitlementData}
        />
      )}

      {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

      <Container fluid>
        <div className="row mt-2">
          <div className="col">
            <input
              type="text"
              id="user-search-filter-input"
              className="form-control search-box"
              defaultValue={inputValue}
              placeholder="Search by email, name, account, location, job function, contract id or entitled"
              onInput={onFilterTextBoxChangedDebounce}
            ></input>
          </div>
          <div className="col my-1 action-item-bar">
            <span className="user-grid-icons icon-divider">
              <CloneIcon
                className={
                  buttonIcons.CloneIcon ? 'icon-active' : 'cursor-default'
                }
                alt="Clone entitlements"
                width="18"
                title="Clone entitlements"
                onClick={cloneClickHandler}
              ></CloneIcon>
            </span>
            <span className="user-grid-icons icon-divider">
              <DeleteIcon
                className={
                  buttonIcons.DeleteIcon ? 'icon-active' : 'cursor-default'
                }
                alt="Delete Users"
                width="18"
                title="Please select at least one user to delete from the user list"
                onClick={deleteClickHandler}
              ></DeleteIcon>
            </span>
            <span className="user-grid-icons icon-divider">
              <ExportIcon
                className={
                  buttonIcons.ExportIcon ? 'icon-active' : 'cursor-default'
                }
                alt="User Export"
                width="18"
                title="Please select at least one user to export details"
                onClick={exportUserCSV}
              ></ExportIcon>
            </span>
            <span className="user-grid-icons icon-divider">
              <AddUserIcon
                alt="Add User"
                width="18"
                title="Add User"
                onClick={handleShow}
              ></AddUserIcon>
            </span>
            <span className="user-grid-icons icon-divider">
              <CloudIcon
                alt="Import Users"
                width="20"
                title="Import Users"
                onClick={handleShowBulkUsers}
              ></CloudIcon>
            </span>
          </div>
        </div>
        <div className="ag-grid-table with-tabs mbx-12">
          <AgGridComponent
            config={defaultConfig}
            gridStyle={gridStyle}
            defaultColumnDef={defaultColdef}
            getRowId={getRowId}
            columns={columns}
            onGridReady={onGridReadyHandler}
            onSelectionChanged={onSelectionChangedDebounce}
          />
        </div>
      </Container>
    </>
  );
};

export default UserDetails;
