import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import { Countries } from './countries';
import { Country, State, City } from 'country-state-city';
import * as Yup from 'yup';
import httpService from '../../services/http-service';
import DropdownInput from '../../sharedcomponents/DropdownInput/DropdownInput';
import TextFieldInput from '../../sharedcomponents/TextFieldInput/TextFieldInput';
import { Endpoints, UserId, loaderStyle } from './../../constants';
import Loader from '../../components/Loader';
import './locations.scss';

const LocationAddEdit = ({
  show,
  handleClose,
  contracts,
  locationDetails,
  onSaveClick,
  loading,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [initialLocatonValue, setInitialLocatonValue] = useState();
  const [accountsData, setAccountsData] = useState([]);
  let [stateNames, setStateNames] = useState([]);
  let locationInitialValue = {
    officeLocationId: '-1',
    accountId: '',
    officeLocationName: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    isDeleted: 0,
    userId: UserId,
    contractNumber: '',
  };
  contracts = contracts ?? [];
  const SubmitHandler = (value) => {
    onSaveClick(value, isEdit);
  };

  const validate = Yup.object({
    contractNumber: Yup.string().required(
      'Please select a contract number to proceed',
    ),
    accountId: Yup.string().required('Please select an account to proceed'),
    address_1: Yup.string().max(
      255,
      'Address 2 must be 255 characters or less',
    ),
    address_2: Yup.string()
      .max(255, 'Address 1 must be 255 characters or less')
      .required('Please enter address 1 to proceed'),
    city: Yup.string()
      .max(100, 'City must be 100 characters or less')
      .required('Please select a city to proceed'),
    state: Yup.string().when([], {
      is: () => stateNames && stateNames.length > 0,
      then: Yup.string().trim().required('Please select a state to proceed'),
      otherwise: Yup.string(),
    }),
    postalCode: Yup.string()
      .max(20, 'Post code must be 20 characters or less')
      .required('Please select a post code to proceed'),
    country: Yup.string().trim().required('Please select a country to proceed'),
  });

  const countryData = Country.getAllCountries().map((cty) => {
    return { name: cty.name, isoCode: cty.isoCode };
  });

  const excludedCountries = [
    'China',
    'Taiwan',
    'Iran',
    'North Korea',
    'South Korea',
    'American Samoa',
  ];

  const countryNames = countryData
    .map((cty) => cty.name)
    .filter((name) => !excludedCountries.includes(name))
    .sort(Intl.Collator().compare);

  const getLocationValues = () => {
    return {
      officeLocationId: locationDetails.officeLocationId,
      accountId: locationDetails.accountId,
      contractNumber: locationDetails.contractNumber,
      officeLocationName: locationDetails.officeLocationName ?? '',
      address_1: locationDetails.address_1 ?? '',
      address_2: locationDetails.address_2 ?? '',
      city: locationDetails.city ?? '',
      state: locationDetails.state ?? '',
      postalCode: locationDetails.postalCode ?? '',
      country: locationDetails.country ?? '',
      isDeleted: locationDetails.isDeleted ?? 0,
      userId: locationDetails.userId ?? UserId,
    };
  };
  const locationFormik = useFormik({
    initialValues: isEdit ? getLocationValues() : locationInitialValue,
    validationSchema: validate,
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });

  const getAccounts = (contractNumber) => {
    if (contractNumber) {
      let queryLocationParams = {
        PageSize: 5000,
        field: 'accountId,accountName',
        filter: `contractNumber:"${contractNumber}"`,
      };
      httpService
        .get(Endpoints.accountApi, queryLocationParams)
        .then((res) => {
          setAccountsData(res.data?.results);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    let isEditValue =
      locationDetails != null && locationDetails.officeLocationId != null
        ? true
        : false;
    setIsEdit(isEditValue);
    setInitialLocatonValue(getLocationValues());
    locationFormik.resetForm();
    if (isEditValue && locationDetails.contractNumber) {
      getAccounts(locationDetails.contractNumber);
    }
    locationFormik.setFieldValue(locationDetails);
  }, [locationDetails]);

  useEffect(() => {
    if (isEdit) {
      if (
        JSON.stringify(initialLocatonValue) !==
        JSON.stringify(locationFormik.values)
      ) {
        setIsValueChanged(true);
      } else {
        setIsValueChanged(false);
      }
    }

    if (locationFormik.values.country) {
      setStateValues(locationFormik.values.country);
    }
  }, [locationFormik.values]);

  const onChangeHandler = (val, name) => {
    let obj = {
      target: { name: name, value: val[name] ?? val },
    };
    locationFormik.handleChange(obj);
    if (name === 'contractNumber') {
      resetAccountDropdown();
      if (val[name] !== '' && name === 'contractNumber') {
        getAccounts(val[name]);
      } else {
        setAccountsData();
      }
    }

    if (name === 'country') {
      locationFormik.setFieldValue('state', '');
    }
  };

  const setStateValues = (countryName) => {
    let isoCode = countryData.filter(
      (country) => country.name === countryName,
    )[0]?.isoCode;
    const stateData = State.getStatesOfCountry(isoCode);
    stateNames = stateData
      .map((state) => state.name)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));

    setStateNames(stateNames);
  };

  const resetAccountDropdown = () => {
    let accountData = {
      target: {
        id: 'accountId',
        value: '',
      },
    };
    locationFormik.handleChange(accountData);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}

        <Modal.Header closeButton>
          <span className="location-form-title">
            {isEdit ? 'Location Details' : 'Add a New Location'}
          </span>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={locationFormik.handleSubmit} className="row">
            <DropdownInput
              formLabel="Contract Number"
              name="contractNumber"
              dropdownOptions={contracts}
              formik={locationFormik}
              ddPlaceHolder={
                locationFormik.values.contractNumber !== '' &&
                contracts?.length > 0
                  ? contracts?.filter(
                      (el) =>
                        el.contractNumber ===
                        locationFormik.values.contractNumber,
                    )[0]?.contractNumber
                  : 'Select'
              }
              labelKeyName="contractNumber"
              ddName={'contracts'}
              onChangeHandler={(e) => onChangeHandler(e, 'contractNumber')}
              addAsterisk
            />

            <DropdownInput
              formLabel="Account"
              name="accountId"
              dropdownOptions={accountsData}
              formik={locationFormik}
              ddPlaceHolder={
                locationFormik.values.accountId !== '' &&
                accountsData?.length > 0
                  ? accountsData?.filter(
                      (el) => el.accountId === locationFormik.values.accountId,
                    )[0]?.accountName
                  : 'Select'
              }
              labelKeyName="accountName"
              ddName={'account'}
              onChangeHandler={(e) => onChangeHandler(e, 'accountId')}
              addAsterisk
            />
            {/* <TextFieldInput
              formLabel="Location Name"
              placeholder="Location Name"
              id="officeLocationName"
              {...locationFormik.getFieldProps('officeLocationName')}
              formik={locationFormik}
              addAsterisk
              autoComplete="off"
            /> */}
            <TextFieldInput
              formLabel="Address 1"
              placeholder="Address 1"
              id="address_2"
              {...locationFormik.getFieldProps('address_2')}
              formik={locationFormik}
              autoComplete="off"
              addAsterisk
            />
            <TextFieldInput
              formLabel="Address 2"
              placeholder="Address 2"
              id="address_1"
              {...locationFormik.getFieldProps('address_1')}
              formik={locationFormik}
              autoComplete="off"
            />
            <TextFieldInput
              formLabel="City"
              placeholder="City"
              id="city"
              {...locationFormik.getFieldProps('city')}
              formik={locationFormik}
              autoComplete="off"
              addAsterisk
            />
            <TextFieldInput
              formLabel="Post Code"
              placeholder="Post Code"
              id="postalCode"
              {...locationFormik.getFieldProps('postalCode')}
              formik={locationFormik}
              autoComplete="off"
              addAsterisk
            />
            <DropdownInput
              formLabel="Country"
              placeholder="Country"
              enableSearch={true}
              searchKeys={[]}
              id="country"
              dropdownOptions={countryNames}
              ddName={'country'}
              ddPlaceHolder={
                locationFormik.values.country !== ''
                  ? locationFormik.values.country
                  : 'Select'
              }
              {...locationFormik.getFieldProps('country')}
              onChangeHandler={(e) => onChangeHandler(e, 'country')}
              formik={locationFormik}
              addAsterisk
              autoComplete="off"
            />
            <DropdownInput
              formLabel="State"
              placeholder="State"
              enableSearch={true}
              searchKeys={[]}
              id="state"
              dropdownOptions={stateNames}
              ddName={'state'}
              ddPlaceHolder={
                locationFormik.values.state !== ''
                  ? locationFormik.values.state
                  : 'Select'
              }
              {...locationFormik.getFieldProps('state')}
              onChangeHandler={(e) => onChangeHandler(e, 'state')}
              formik={locationFormik}
              customDropdownMessage="No states under the selected country"
              addAsterisk={stateNames?.length > 0 ? true : false}
              autoComplete="off"
            />
            <Row className="pe-0">
              <Form.Group className="pe-0 mb-1 user-save-btn-body">
                <button
                  type="Submit"
                  className="btn btn-dark float-end btn-opacity"
                  disabled={isEdit && !isValueChanged}
                >
                  {isEdit ? 'Update Location' : 'Save Location'}
                </button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LocationAddEdit;
