import {
  PendingActive,
  PendingApproval,
  PendingInactive,
} from '../../constants';

export const getSortColumns = (params, defaultValue) => {
  let groupId = getGroupByColumns(params);
  let cols = params?.sortModel;
  if (cols && cols.length > 0) {
    // on API param Add sort column name when groupby done with same column name else make it empty
    if (groupId === cols[0].colId || !groupId) {
      let groupSort = columnKeyReplacer(cols[0].colId) + ':' + cols[0].sort;
      // Adding extra sort to avoid duplicate data on pagination
      let defaultSort = defaultValue && !groupId ? ',' + defaultValue : '';
      return groupSort + defaultSort;
    } else {
      return '';
    }
  }
  return params.rowGroupCols && params.rowGroupCols.length > 0
    ? ''
    : defaultValue;
};

export const getFilterColumns = (params, dateKey, toIncludeDate) => {
  let filterCols = params.filterModel;
  let filterText = '';
  let isGroupBy =
    params?.rowGroupCols?.length > 0 && params?.groupKeys?.length > 0;
  if (isGroupBy) {
    params.rowGroupCols.forEach((grpCols, index) => {
      if (params.groupKeys[index] && dateKey !== grpCols && !toIncludeDate) {
        if (index !== 0) filterText = filterText + ' AND ';

        filterText =
          filterText +
          grpCols.id +
          filterMapper('equals', params.groupKeys[index]);
      }
    });
  }
  if (filterCols) {
    for (let col in filterCols) {
      if (
        !dateKey || // When only one parameter, filter into one string
        (dateKey === col && toIncludeDate) || // When 2nd parameter is passed to include date parameter
        (dateKey !== col && !toIncludeDate) // When 2nd parameter is passed to exclude date parameter
      ) {
        if (filterText.length > 0) {
          filterText = filterText + ' AND ';
        }
        filterText =
          filterText +
          columnKeyReplacer(col) +
          filterMapper(filterCols[col].type, filterCols[col].filter);
      }
    }
  }
  return filterText;
};

const columnKeyReplacer = (key) => {
  switch (key) {
    case 'distributor':
      return 'deliveryPlatform';
    case 'contractNumber_1':
      return 'contractNumber';
    case 'modifiedDate_1':
      return 'modifiedDate';
    default:
      return key;
  }
};

const filterMapper = (key, value) => {
  // Using it for user dashboard redirection props to user entitlement page
  // as we are hiding inRange option on UI
  if (
    key === 'contains' &&
    (value === `"${PendingActive}","${PendingInactive}"` ||
      value === `"${PendingApproval}"`)
  )
    key = 'inRange';

  switch (key) {
    case 'equals':
      return ':"' + value + '"';
    case 'notEqual':
      return '!:"' + value + '"';
    case 'contains':
      return ':"*' + value + '*"';
    case 'notContains':
      return '!:"*' + value + '*"';
    case 'startsWith':
      return ':"' + value + '*"';
    case 'endsWith':
      return ':"*' + value + '"';
    case 'inRange':
      return ' IN(' + value + ')';
    case 'dateBetween':
      return value;
    default:
      return '';
  }
};

export const getGroupByColumns = (params) => {
  let cols = params.rowGroupCols;
  if (cols && cols.length > 0) {
    for (let i = 0; i < cols.length; i++) {
      if (!params.groupKeys[i]) return cols[i].id;
    }
  }
  return '';
};

export const isGroupBy = (params) => {
  // returns a boolean if grid is using groupby or not
  let isGroupBy = false;
  if (
    params?.rowGroupCols?.length > 0 &&
    params?.rowGroupCols?.length !== params?.groupKeys?.length
  ) {
    isGroupBy = true;
  }
  return isGroupBy;
};

export const filterOptions = [
  'contains',
  'notContains',
  'equals',
  'notEqual',
  'startsWith',
  'endsWith',
];
