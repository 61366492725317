import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import {
  Active,
  Endpoints,
  GenericError,
  PendingActive,
  PendingApproval,
  PendingInactive,
  Rejected,
  UrlPath,
  UserId,
  Verbiage,
  loaderStyle,
} from '../../constants';
import { debounce } from '../../helpers/debounce';
import httpService from '../../services/http-service';
import { Notify } from '../../sharedcomponents/Alert/Notify';
import AgGridComponent from '../../sharedcomponents/ag-grid/AgGrid';
import {
  getFilterColumns,
  getGroupByColumns,
  getSortColumns,
  isGroupBy,
} from '../../sharedcomponents/ag-grid/ag-grid-helper';
import { ReactComponent as DeleteIcon } from './../../assets/icons/common/delete.svg';
import { ReactComponent as ApproveIcon } from './../../assets/icons/common/entitlement-approval.svg';
import { ReactComponent as RejectIcon } from './../../assets/icons/common/entitlement-rejected.svg';
import { ReactComponent as ExportIcon } from './../../assets/icons/common/export.svg';
import { ReactComponent as UserGroupIcon } from './../../assets/icons/common/user-group.svg';
import { ReactComponent as CloudIcon } from './../../assets/icons/common/cloud.svg';
import addEntitlementIcon from './../../assets/icons/entitlements/add-entitlement.svg';
import mailing from './../../assets/icons/entitlements/mailing.svg';
import { persistDataStore } from './../../redux/persistStore';
import store from './../../redux/store';
import BulkUploadUserEntitlements from '../user-details/bulk-upload-user-entitlements/bulk-upload-user-entitlements';

// Integrated with new version of aggrid(v31.0.2)
const Entitlements = ({ contracts }) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [bulkUserEntitlementEnable, setBulkUserEntitlementEnable] = useState();
  const [showBulkUserEntitlements, setShowBulkUserEntitlements] =
    useState(false);
  const [buttonIcons, setButtonIcons] = useState({
    DeleteIcon: false,
    ExportIcon: false,
    ApproveIcon: false,
    RejectIcon: false,
  });
  const location = useLocation();
  const gridRef = useRef();

  const defaultConfig = {
    editable: true,
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    pagination: false,
    isAutoSizeColums: true,
    enableCharts: false,
    sideBar: true,
    overlayNoRowsTemplate:
      contracts && contracts.some((x) => x.checked)
        ? Verbiage.entitlements
        : Verbiage.noData,
  };

  const isFirstColumn = (params) => {
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let firstColumn = displayedColumns[0] === params?.column;
    if (firstColumn) {
      return firstColumn;
    }
  };

  const isSameOrFirstColumn = (params) => {
    // Show checkbox on each row of first column and on grouped, show it only on grouped columns
    let displayedColumns = params?.api?.getAllDisplayedColumns();
    let rowGroupCheckBox = !params?.node?.group;
    let showCheckBox =
      // rowGroupCheckBox &&
      displayedColumns[params?.node?.level] === params?.column;
    return showCheckBox;
  };

  const columnFilterParams = {
    // common column definition
    filter: 'agTextColumnFilter',
    // filterParams: {
    //   buttons: ['reset', 'apply'],
    //   maxNumConditions: 1,
    // },
    enableRowGroup: true,
    enablePivot: false,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    enableValue: false, // disable value aggregation in column header
  };

  const defaultColdef = {
    ...columnFilterParams,
    sortable: true,
    resizable: true,
    checkboxSelection: isSameOrFirstColumn,
    headerCheckboxSelection: isFirstColumn,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    cellRenderer: (params) => {
      let newValue = params.value;
      if (params.column.colId === 'emailId')
        if (!params.node?.group && params.data?.groupName) {
          newValue = groupIcon(params);
        }

      return newValue ?? null;
    },
  };

  const isRowSelectable = useCallback((params) => {
    return !params?.data?.groupId;
  }, []);

  const contractChangeHandler = () => {
    if (gridRef.current && contracts && contracts.length > 0) {
      let filteredContracts = contracts.filter((x) => x.checked);
      let commonFilterType = {
        filterType: 'text',
        type: 'inRange',
      };
      let filterValue = {
        filter:
          filteredContracts.length !== 0
            ? filteredContracts
                .map((t) =>
                  t.contractNumber ? '"' + t.contractNumber + '"' : '',
                )
                .join(',')
            : 'NoContractSelected',
        ...commonFilterType,
      };
      // add status filter after been redirected from user dashboard
      let newFilterModel = getAllFilterModel('contractNumber', filterValue);

      if (location?.state && filteredContracts.length !== 0) {
        let statusFilterValue = {
          filter: location?.state
            ?.map((statusEnt) => (statusEnt ? '"' + statusEnt + '"' : ''))
            .join(','),
          ...commonFilterType,
        };
        let statusFilter = getAllFilterModel('status', statusFilterValue);
        newFilterModel.status = { ...statusFilter.status };
      }
      gridRef?.current?.api?.setFilterModel(newFilterModel);

      // clear location state on refresh
      window.history.replaceState({}, document.title);
    }
  };

  useEffect(() => {
    let filteredContracts = contracts?.filter((x) => x.checked);
    let storeData = store.getState().userInfo?.userInfo;
    setBulkUserEntitlementEnable(
      storeData?.SPGGroups?.includes('SFS_CCA') &&
        filteredContracts?.some((x) => x.bulkEntitlementEnable),
    );

    // Reduce callback onchange of contract selection
    const reduceCallback = setTimeout(() => {
      contractChangeHandler();
    }, 1000);
    return () => clearTimeout(reduceCallback);
  }, [contracts]);

  const groupIcon = (params) => (
    <>
      <svg
        className="icon-active"
        width="15"
        style={{ marginBottom: '8px' }}
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{params.data.groupName}</title>
        <path
          d="M9.96875 10.0485C11.7812 10.0485 13.2188 8.61102 13.2188 6.79852C13.2188 5.01727 11.75 3.54852 9.96875 3.54852C8.1875 3.54852 6.75 5.01727 6.75 6.79852C6.71875 8.61102 8.1875 10.0485 9.96875 10.0485ZM9.96875 5.04852C10.9375 5.04852 11.7188 5.86102 11.7188 6.79852C11.7188 7.76727 10.9375 8.54852 9.96875 8.54852C9.03125 8.54852 8.21875 7.76727 8.21875 6.79852C8.21875 5.86102 9.03125 5.04852 9.96875 5.04852ZM16 5.04852C17.375 5.04852 18.5 3.95477 18.5 2.54852C18.5 1.17352 17.375 0.0485229 16 0.0485229C14.5938 0.0485229 13.5 1.17352 13.5 2.54852C13.5 3.95477 14.5938 5.04852 16 5.04852ZM11.5312 11.0485H8.4375C5.96875 11.0485 4 12.9235 4 15.236C4 15.7048 4.375 16.0485 4.875 16.0485H15.0938C15.5938 16.0485 16 15.7048 16 15.236C16 12.9235 14 11.0485 11.5312 11.0485ZM5.5625 14.5485C5.875 13.4235 7.03125 12.5485 8.40625 12.5485H11.5312C12.9062 12.5485 14.0625 13.4235 14.4062 14.5485H5.5625ZM17.2188 6.04852H15.3125C14.9062 6.04852 14.5312 6.17352 14.1875 6.32977C14.1875 6.48602 14.2188 6.64227 14.2188 6.79852C14.2188 7.86102 13.8438 8.82977 13.1875 9.54852H19.4375C19.75 9.54852 20 9.29852 20 8.98602C20 7.36102 18.75 6.04852 17.2188 6.04852ZM5.71875 6.79852C5.71875 6.64227 5.75 6.48602 5.78125 6.32977C5.4375 6.14227 5.0625 6.04852 4.65625 6.04852H2.75C1.21875 6.04852 0 7.36102 0 8.98602C0 9.29852 0.21875 9.54852 0.53125 9.54852H6.78125C6.125 8.82977 5.71875 7.86102 5.71875 6.79852ZM4 5.04852C5.375 5.04852 6.5 3.95477 6.5 2.54852C6.5 1.17352 5.375 0.0485229 4 0.0485229C2.59375 0.0485229 1.5 1.17352 1.5 2.54852C1.5 3.95477 2.59375 5.04852 4 5.04852Z"
          fill=""
        />
      </svg>
      {' ' + params.value}
    </>
  );

  const customValueGetter = (params, columnId) => {
    // returns single string joining multiple keys
    let newValue = '';
    if (params) {
      let colId = columnId ?? params.column?.colId;
      switch (colId) {
        case 'contactName':
          if (
            params.data?.contactName ||
            (params.data?.firstName && params.data?.lastName)
          )
            newValue =
              params.data?.contactName ??
              params.data?.firstName + ' ' + params.data?.lastName;
          break;
        case 'deliveryPlatform':
          if (
            params.data?.distributor &&
            params.data?.distributorPlatform &&
            params.data?.deliveryType
          )
            newValue =
              params.data?.distributor +
              ' : ' +
              params.data?.distributorPlatform +
              ' (' +
              params?.data?.deliveryType +
              ')';
          break;
        default:
          newValue = params.data[colId];
      }
    }
    return newValue;
  };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  const autoGroupColumnDef = {
    // column definition for grouped column
    ...columnFilterParams,
    enableRowGroup: false,
    lockPosition: 'left',
    sortable: false,
    cellRenderer: 'agGroupCellRenderer',
  };

  const columns = [
    {
      headerName: '',
      field: 'contractNumber',
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          'inRange',
          {
            displayKey: 'inRange',
            displayName: 'Range Between',
            predicate: ([filterValue], cellValue) => {
              return cellValue == null || cellValue < filterValue;
            },
          },
        ],
      },
      suppressHeaderMenuButton: true,
      sortable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: 'Email ID',
      headerTooltip: 'Email ID',
      field: 'emailId',
      tooltipField: 'emailId',
      initialFlex: 3,
    },
    {
      headerName: 'Users',
      headerTooltip: 'Users',
      field: 'contactName',
      valueGetter: customValueGetter,
      tooltipValueGetter: customValueGetter,
      tooltipField: 'contactName',
      initialFlex: 3,
    },
    {
      headerName: 'Locations',
      headerTooltip: 'Locations',
      field: 'officeLocation',
      tooltipField: 'officeLocation',
      initialFlex: 3,
    },
    {
      headerName: 'Packages',
      headerTooltip: 'Packages',
      field: 'packageName',
      tooltipField: 'packageName',
      initialFlex: 3,
    },
    {
      headerName: 'Products',
      headerTooltip: 'Products',
      field: 'productName',
      tooltipField: 'productName',
      initialFlex: 3,
    },
    {
      headerName: 'Delivery Platform (Type)',
      headerTooltip: 'Delivery Platform (Type)',
      field: 'deliveryPlatform',
      valueGetter: customValueGetter,
      tooltipValueGetter: customValueGetter,
      initialFlex: 3,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
      tooltipField: 'status',
      initialFlex: 2,
    },
  ];

  // set input to CSA selected user
  useEffect(() => {
    let csaActionData =
      persistDataStore.getState()?.userActionData?.csaUserActionInfo;
    if (
      csaActionData?.type &&
      csaActionData?.value &&
      csaActionData?.type === 'user'
    ) {
      setInputValue(csaActionData?.value);
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    let moreThan2 = searchText && searchText.length > 2;
    // Apply custom search filter
    if (!searchText || moreThan2) {
      gridRef?.current?.api?.onFilterChanged();
    }

    let isRowExist = gridRef?.current?.api?.getRenderedNodes()?.length > 0;

    // Check if any row is rendered if not then show overlay
    if (moreThan2 && !isRowExist) {
      gridRef?.current?.api?.showNoRowsOverlay();
    } else {
      gridRef?.current?.api?.hideOverlay();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridRef.current = params;

    gridRef.current.api.setGridOption(
      'serverSideDatasource',
      getServerSideDatasource(),
    );
  };

  const getServerSideDatasource = (statusFilter) => {
    return {
      getRows: (params) => {
        try {
          let AgParams = params?.request;
          let groupedColumn = getGroupByColumns(AgParams);
          let contractFilterModel =
            AgParams?.filterModel['contractNumber']?.filter;

          if (
            contractFilterModel &&
            Object.keys(contractFilterModel)?.length > 0 &&
            contractFilterModel !== 'NoContractSelected'
          ) {
            let FilterColumns =
              'emailId,contactName,deliveryPlatform,officeLocation,packageName,productName,status';
            let searchText = document.getElementById(
              'entitlement-search-filter-input',
            ).value;
            let queryParams = {
              Filter: getFilterColumns(AgParams),
              FilteredColumns: JSON.stringify(
                gridRef.current?.api.getFilterModel(),
              ),
              Field: isGroupBy(AgParams)
                ? `count(${groupedColumn})`
                : 'contactId,contractNumber,userEntitlementId,deliveryPlatform,emailId,productComponentId,packageName,productName,officeLocation,groupId,groupName,status,contactName,modifiedDate',
              Sort: getSortColumns(
                AgParams,
                'modifiedDate:desc,userEntitlementId:desc',
              ),
              PageSize: AgParams.endRow - AgParams.startRow,
              Page:
                AgParams.startRow / (AgParams.endRow - AgParams.startRow) + 1,
              groupBy: groupedColumn,
              querySearch: searchText,
              searchColumns: searchText ? FilterColumns : '',
              id: String(Date.now()),
            };
            const fromUserDashboard = location?.state;
            gridRef?.current?.api?.showLoadingOverlay();
            httpService
              .get(Endpoints.userentitlementsApi, queryParams)
              .then(({ data }) => {
                if (data) {
                  let rowData = data?.results ?? data?.aggResultValue;
                  if (
                    data?.aggResultValue &&
                    data?.aggResultValue?.length > 0
                  ) {
                    // On groupby - create unique key to avoid duplicate rows
                    let groupKeys = AgParams.groupKeys.join(',');
                    rowData = rowData.map((el) => {
                      return { ...el, groupKeys: groupKeys };
                    });
                  }
                  params.success({
                    rowData: rowData,
                    rowCount: data?.metadata.count,
                  });

                  if (rowData?.length === 0)
                    gridRef?.current?.api?.showNoRowsOverlay();
                  else {
                    if (!fromUserDashboard) {
                      gridRef?.current?.api?.hideOverlay();
                    }
                  }

                  if (fromUserDashboard) {
                    gridRef?.current?.api?.hideOverlay();
                    if (statusFilter) {
                      // apply status of pending active and inactive to status column
                      setTimeout(
                        () =>
                          gridRef?.current?.api?.setFilterModel(statusFilter),
                        50,
                      );
                    }
                  }
                }
              })
              .catch((err) => {
                params.fail();
                gridRef?.current?.api?.hideOverlay();
              });
          } else {
            params.success({
              rowData: [],
              rowCount: 0,
            });

            if (contractFilterModel === 'NoContractSelected') {
              gridRef?.current?.api?.showNoRowsOverlay();
            }
          }
        } catch (err) {
          params.fail();
        }
      },
    };
  };

  const getAllFilterModel = (colId, newModel) => {
    let currentModel = gridRef?.current?.api?.getFilterModel();
    currentModel[colId] = newModel;
    return currentModel;
  };

  const getChildCount = useCallback((data) => {
    // Server side grouped row child count
    return data?.count ?? 100;
  }, []);

  const onSelectionChanged = (elements) => {
    let nonGroupSelectedRows = elements?.api
      ?.getSelectedRows()
      ?.filter((el) => el?.groupKeys !== '');
    let rowLength = nonGroupSelectedRows.length;
    let isApprovalEts = nonGroupSelectedRows.some(
      (el) => el?.status === PendingApproval,
    );

    setButtonIcons({
      ...buttonIcons,
      DeleteIcon: rowLength > 0,
      ExportIcon: rowLength > 0,
      ApproveIcon: isApprovalEts,
      RejectIcon: isApprovalEts,
    });
  };

  const onSelectionChangedDebounce = debounce(onSelectionChanged, 100);

  const notifyDeleteEntitlements = () => {
    // Alert user to confirm delete entitlements
    if (buttonIcons.DeleteIcon) {
      let selectedEntitlements = gridRef.current?.api?.getSelectedRows();
      let statusToDelete = [Active, PendingActive];
      let filteredSelectionID = [];
      selectedEntitlements.forEach((el) => {
        // Exclude group entitlement(Precautionary check)
        if (!el?.groupId)
          if (statusToDelete.includes(el.status)) filteredSelectionID.push(el);
      });
      let title =
        (filteredSelectionID.length > 0) &
        (selectedEntitlements.length === filteredSelectionID.length)
          ? 'Delete selected entitlements?'
          : `Only '${Active}' & '${PendingActive}' entitlements will be deleted`;

      Notify({
        alert: true,
        type: 'warning',
        title: title,
        confirmBtnText: 'YES DELETE',
        cancelBtnText: 'NO, KEEP IT',
        onConfirm: () => deleteEntitlements(filteredSelectionID),
      });
    }
  };

  const deleteEntitlements = (filteredSelectionID) => {
    // delete user after confirmation
    if (filteredSelectionID.length > 0) {
      let deleteSuccessTitle =
        filteredSelectionID.length > 1
          ? 'Entitlements Deleted'
          : 'Entitlement Deleted';
      let entitlementID = filteredSelectionID?.map(
        (el) => el.userEntitlementId,
      );
      setLoading(true);
      httpService
        .delete(Endpoints.userentitlementsApi, entitlementID)
        .then((res) => {
          if (res.status === 200) {
            getEntitlements();
            Notify({
              alert: true,
              type: 'success',
              title: deleteSuccessTitle,
            });
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
    gridRef.current?.api?.deselectAll();
  };

  const notifyPendingApprovalEts = (approvalRqdStatus) => {
    // Alert user to confirm approval status of entitlements
    if (buttonIcons.ApproveIcon || buttonIcons.RejectIcon) {
      let selectedEntitlements = gridRef.current?.api?.getSelectedRows();
      let statusToApprove = [PendingApproval];
      let filteredSelectionID = [];
      selectedEntitlements.forEach((el) => {
        // Exclude group entitlement(Precautionary check)
        if (!el?.groupId)
          if (statusToApprove.includes(el.status)) filteredSelectionID.push(el);
      });
      let title =
        (filteredSelectionID.length > 0) &
        (selectedEntitlements.length === filteredSelectionID.length)
          ? `${approvalRqdStatus} selected entitlements?`
          : `Only ${PendingApproval} entitlements will be ${
              approvalRqdStatus === 'Approve' ? 'approved' : 'rejected'
            }`;

      let approvalStatus =
        approvalRqdStatus === 'Approve'
          ? PendingActive
          : approvalRqdStatus === 'Reject'
          ? Rejected
          : null;

      Notify({
        alert: true,
        type: 'warning',
        title: title,
        confirmBtnText: `YES ${approvalRqdStatus}`,
        cancelBtnText: 'NO, KEEP IT',
        onConfirm: () =>
          saveStatusOfPendingApprovalEts(filteredSelectionID, approvalStatus),
      });
    }
  };

  const saveStatusOfPendingApprovalEts = (
    filteredPendingApprovalETS,
    approvalStatus,
  ) => {
    // Save entitlement status after confirmation
    if (filteredPendingApprovalETS.length > 0 && approvalStatus) {
      let updatedPAEts = [];
      let postData = filteredPendingApprovalETS.map((obj) => {
        updatedPAEts.push({ ...obj, status: approvalStatus });
        return {
          userEntitlementId: obj.userEntitlementId,
          contactId: obj.contactId,
          productComponentId: obj.productComponentId,
          contractNumber: obj.contractNumber,
          status: approvalStatus,
        };
      });
      let entitlementModel = {
        userEntitlements: postData,
        userId: UserId,
      };
      let approvalSuccessTitle =
        filteredPendingApprovalETS.length > 0 &&
        approvalStatus === PendingActive
          ? 'Entitlements Approved'
          : 'Entitlements Rejected';

      setLoading(true);
      httpService
        .post(Endpoints.userentitlementsApi, entitlementModel)
        .then((res) => {
          if (res.status === 201) {
            getEntitlements();
            Notify({
              alert: true,
              type: 'success',
              title: approvalSuccessTitle,
            });
          }
        })
        .catch(() => {})
        .finally(() => setLoading(false));

      sendEmailToEU(filteredPendingApprovalETS, approvalStatus);
    }
    gridRef.current?.api?.deselectAll();
  };

  const sendEmailToEU = (filteredPendingApprovalETS, approvalStatus) => {
    try {
      let storeData = store.getState();
      let CCAEmail = storeData?.userInfo?.userInfo?.email;

      let status = approvalStatus === PendingActive ? 'Approved' : 'Rejected';
      const emailBodies = createEmailBodies(filteredPendingApprovalETS, status);

      Object.entries(emailBodies).forEach(([emailId, mailBody]) => {
        let emailData = [];
        let mail_to_address = emailId;
        let mail_cc_address = CCAEmail;
        let mail_from_address = 'donotreply@spglobal.com';
        let mail_subject = 'Activity on your Entitlement Requests';

        emailData.push({
          MAIL_FROM_ADDRESS: mail_from_address,
          MAIL_TO_ADDRESS: mail_to_address,
          MAIL_CC_ADDRESS: mail_cc_address,
          MAIL_BODY: mailBody,
          MAIL_SUBJECT: mail_subject,
        });

        httpService
          .post(Endpoints.sendEmailApi, emailData[0])
          .then((response) => {
            if (response.status === 200) {
              setTimeout(() => {}, 200);
            }
          })
          .catch((err) => {
            Notify({
              alert: true,
              type: 'error',
              title: GenericError.somethingWentWrong,
            });
          });
      });
    } catch (err) {}
  };

  const createEmailBodies = (pendingETS, status) => {
    const emailsGroup = pendingETS.reduce((acc, item) => {
      const {
        emailId,
        packageName,
        distributor,
        distributorPlatform,
        contactName,
      } = item;
      if (!acc[emailId]) {
        acc[emailId] = {
          packages: [],
          contactName,
        };
      }

      acc[emailId].packages.push({
        packageName,
        distributor,
        distributorPlatform,
      });
      return acc;
    }, {});

    const emailBodies = Object.entries(emailsGroup).reduce(
      (acc, [emailId, { packages, contactName }]) => {
        const tableRows = packages
          .map(
            ({ packageName, distributor, distributorPlatform }) =>
              `<tr>
          <td style='width: 33.33%;'>${packageName}</td>
          <td style='width: 33.33%;'>${distributor}:${distributorPlatform}</td>
          <td style='width: 33.33%;'>${status}</td>
        </tr>`,
          )
          .join('');

        const mailBody = `<div style='font-size: 14; font-family: Arial'><p>Dear ${contactName},</p><p>Please see below status for your Entitlement Requests. Click below to login to S&P Global CI Subscription Manager and view your Entitlements.</p><p>${window.location.origin}${UrlPath.entitlements}</p><table border='1' style='border-collapse: collapse; width: 100%;'><thead><tr><th style='width: 33.33%;'><b>Package</b></th><th style='width: 33.33%;'><b>Delivery Platform</b></th><th style='width: 33.33%;'><b>Status</b></th></tr></thead><tbody>${tableRows}</tbody></table><p>Kind regards,<br><b>Client Service</b><br>S&P Global Commodity Insights</div><hr><div style='font-size: 10; font-family: Arial'><p>If you have questions or feedback, please email <a style='color:red !important; text-decoration:none;'>ci.support@spglobal.com</a></p><p>**Please do not reply to this email. This mailbox cannot accept incoming replies.**</p></div></div>`;

        acc[emailId] = mailBody;
        return acc;
      },
      {},
    );

    return emailBodies;
  };

  const getRowId = (params) => {
    if (params?.data?.userEntitlementId != null) {
      return 'entitlement-' + params.data.userEntitlementId;
    } else {
      // assign a unique ID to each data item
      return Object.entries(params?.data).join(',');
    }
  };

  const getEntitlements = () => {
    gridRef?.current?.api?.onFilterChanged();
    gridRef?.current?.api?.ensureIndexVisible(0, 'top');
  };

  const getGroupCSVData = (params) => {
    // Export CSV on group including grouped column data
    if (!params.value) {
      let displayedColumns = params?.api?.getAllDisplayedColumns();
      let currentColumn = displayedColumns[params?.accumulatedRowIndex];
      let columnId = currentColumn?.colId?.split('ag-Grid-AutoColumn-')[1];
      return customValueGetter(params?.node, columnId);
    } else {
      return params?.value;
    }
  };

  const exportEntitlementCSV = () => {
    if (buttonIcons.ExportIcon) {
      gridRef.current?.api?.exportDataAsCsv({
        onlySelected: true,
        fileName: 'Entitlements_' + getDate(),
        processCellCallback: getGroupCSVData,
      });
    }
  };

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };

  const handleCloseBulkUerEntitlements = () =>
    setShowBulkUserEntitlements(false);
  const handleShowBulkUserEntitlements = () =>
    setShowBulkUserEntitlements(true);

  return (
    <>
      {loading && <Loader type="scaleLoader" cssClass={loaderStyle} />}
      {showBulkUserEntitlements && (
        <BulkUploadUserEntitlements
          showBulkUserEntitlements={showBulkUserEntitlements}
          handleCloseBulkUerEntitlements={handleCloseBulkUerEntitlements}
          contracts={contracts}
          getUpdatedUserEntitlements={getEntitlements}
        />
      )}
      <>
        <div className="row mt-2">
          <div className="col">
            <input
              type="text"
              id="entitlement-search-filter-input"
              className="form-control search-box"
              defaultValue={inputValue}
              placeholder="Search by users, packages, products, delivery platform or status"
              onInput={onFilterTextBoxChangedDebounce}
            ></input>
          </div>
          <div className="col action-item-bar">
            <span className="user-grid-icons icon-divider">
              <ApproveIcon
                className={`approve-icon ${
                  buttonIcons.ApproveIcon ? 'icon-active' : 'cursor-default'
                }`}
                alt="Approve Entitlements"
                width="18"
                title="Approve entitlements"
                onClick={() => notifyPendingApprovalEts('Approve')}
              />
            </span>
            <span className="user-grid-icons icon-divider">
              <RejectIcon
                className={`reject-icon ${
                  buttonIcons.RejectIcon ? 'icon-active' : 'cursor-default'
                }`}
                alt="Reject Entitlements"
                width="18"
                title="Reject entitlements"
                onClick={() => notifyPendingApprovalEts('Reject')}
              />
            </span>
            <span className="user-grid-icons icon-divider">
              <DeleteIcon
                className={
                  buttonIcons.DeleteIcon ? 'icon-active' : 'cursor-default'
                }
                alt="Delete Entitlements"
                width="18"
                title="Delete entitlements"
                onClick={notifyDeleteEntitlements}
              />
            </span>
            <span className="user-grid-icons icon-divider">
              <ExportIcon
                className={
                  buttonIcons.ExportIcon ? 'icon-active' : 'cursor-default'
                }
                alt="Entitlements Export"
                width="18"
                title="Export entitlements"
                onClick={exportEntitlementCSV}
              />
            </span>
            <span className="user-grid-icons icon-divider">
              <Link to="/add-entitlements" title="Create New entitlements">
                <img
                  src={addEntitlementIcon}
                  className="logo-img"
                  alt="ADD ENTITLEMENTS"
                  width="19"
                />
              </Link>
            </span>
            <span className="user-grid-icons icon-divider">
              <Link to="/product-entitlements" title="Manage email preferences">
                <img
                  src={mailing}
                  className="logo-img"
                  alt="Manage email preferences"
                  width="19"
                />
              </Link>
            </span>
            {bulkUserEntitlementEnable && (
              <span className="user-grid-icons icon-divider">
                <CloudIcon
                  alt="Import User Entitlements"
                  width="20"
                  title="Import User Entitlements"
                  onClick={handleShowBulkUserEntitlements}
                ></CloudIcon>
              </span>
            )}
          </div>
        </div>
        <div className="ag-grid-table mb-2">
          <AgGridComponent
            autoGroupColumnDef={autoGroupColumnDef}
            config={defaultConfig}
            defaultColumnDef={defaultColdef}
            columns={columns}
            getChildCount={getChildCount}
            onGridReady={onGridReadyHandler}
            onSelectionChanged={onSelectionChangedDebounce}
            groupDisplayType="multipleColumns"
            getRowId={getRowId}
            isRowSelectable={isRowSelectable}
          />
          <div className="notes-text-label mt-1" id="notes-label">
            <span className="notes-asterisk-label">
              <UserGroupIcon
                className="icon-active"
                alt="User Group"
                width="18"
              ></UserGroupIcon>
            </span>
            User is part of a group and can not be entitled separately.
            Entitlements must be updated at group level.
          </div>
        </div>
      </>
    </>
  );
};

export default Entitlements;
