/**
 *
 * Usage
 *
 * var object = { 'a': [{ 'b': { 'c': 3 } }] };
 *
 * get(object, 'a[0].b.c'); // => 3
 *
 * get(object, ['a', '0', 'b', 'c']); // => 3
 *
 * get(object, 'a.b.c', 'default'); // => 'default'
 *
 */

import get from 'lodash.get';

export default get;
