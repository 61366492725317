import { PersistReduxAction } from './../../constants';
const initialState = {};

const csaUserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PersistReduxAction.csaActionData: {
      return {
        ...state,
        csaUserActionInfo: action.payload,
      };
    }
    default:
      return state;
  }
};
export default csaUserDetailsReducer;
