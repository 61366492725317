import React from 'react';
import { Modal } from 'react-bootstrap';
import cn from './../../helpers/cn';
import Loader from './../../components/Loader';

const ModalBox = (props) => {
  const {
    show,
    handleClose,
    size,
    modalTitle,
    classHeader,
    classBody,
    contentClassName,
    backdropClassName,
    dialogClassName,
    showHeader = true,
    showLoader =false
  } = props;

  const loaderStyle = {
    width: '100%',
    height: '100%',
    background: '#cdcecf6e',
    display: 'flex',
    position: 'absolute',
    zIndex: '1100',
    justifyContent: 'center',
    alignItems: 'center'
  };
  return (
    <Modal
      dialogClassName={dialogClassName?? 'modal-size-md'}
      onClose={handleClose}
      size={size}
      show={show}
      onHide={handleClose}
      backdrop="static"
      contentClassName={cn(contentClassName)}
      backdropClassName={cn(backdropClassName)}
      centered
    >
      {showLoader ? ( <Loader type="scaleLoader" cssClass={loaderStyle} /> ) : ''}
      {showHeader && (
        <Modal.Header className={classHeader} closeButton>
          <span className="user-form-title">{modalTitle}</span>
          <p className="btn-modal-close" onClick={handleClose}>
            <i className="fa fa-times text-danger"></i>
          </p>
        </Modal.Header>
      )}
      <Modal.Body className={classBody}>{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalBox;
