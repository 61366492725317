export const Notify = (obj) => {
  let evt = new CustomEvent('showAlert', { detail: obj });
  window.dispatchEvent(evt);
};

// Example

// import { Notify } from "../../sharedcomponents/Alert/Notify"

// <button onClick={() => Notify({ alert: true, type: "success", title: "New user has been added" })}>Notify</button>

// Keys ->
//     alert: true,
//         type: detail.type,
//             title: detail.title,
//                 confirmBtnText: detail.confirmBtnText,
//                     onConfirm: detail.onConfirm,
//                         onCancel: detail.onCancel,
//                            cancelBtnText: detail.cancelBtnText,
