import { ReduxAction } from '../../constants';
const initialState = {};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.tabType: {
      return {
        ...state,
        tabType: action.payload,
      };
    }
    default:
      return state;
  }
};
export default packageReducer;
