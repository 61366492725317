import DOMPurify from 'dompurify';

const sanitizerOptions = {
  ADD_ATTR: ['target'],
  ADD_TAGS: ['iframe'],
  ALLOW_DATA_ATTR: true,
  FORBID_ATTR: [''],
  FORBID_TAGS: [''],
  KEEP_CONTENT: true,
};

const sanitizer = (str, options) =>
  DOMPurify.sanitize(str, { ...sanitizerOptions, ...options });

export default sanitizer;
