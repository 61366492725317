import React from 'react';
import { Container } from 'react-bootstrap';
import footerlogo from './../assets/img/logo/logo.svg';

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <div className="text-muted d-flex">
        <div className="position-relative text-start pad-top-3 w-50">
          <ul className="list-inline">
            <li className="list-inline-item text-dark">
              <a
                className="footer-links"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.spglobal.com/en/terms-of-use"
              >
                Website Terms of Use
              </a>
            </li>
            <li className="list-inline-item text-dark">
              <a
                className="footer-links"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.spglobal.com/en/privacy/privacy-policy-english"
              >
                Privacy Policy
              </a>
            </li>
            <li className="list-inline-item text-dark">
              <a
                className="footer-links"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.spglobal.com/en/cookie-notice"
              >
                Cookie Notice
              </a>
            </li>
          </ul>
          <p className="copyright-text">
            &copy; {new Date().getFullYear()}{' '}
            <span href="/" className="">
              S&P Global
            </span>
          </p>
        </div>
        <div className="d-flex w-50">
          <div className="footer-logo">
            <img src={footerlogo} alt="S&P Global Commodity Insights" />
          </div>
        </div>
      </div>
    </Container>
  </footer>
);

export default Footer;
