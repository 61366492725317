import React, { memo, useEffect, useState } from 'react';
import { createTreeData } from '../../helpers/create-tree-json';
import TreeMultiSelectDropdown from '../../sharedcomponents/tree-multi-select-dropdown/tree-multi-select-dropdown';
import { Endpoints, ProductDeliveryPlatforms } from './../../constants';
import HttpService from './../../services/http-service';

const ProductDistributorsDropdown = memo(
  ({ DataSet, Type, callBackDistributors }) => {
    const [distributorsResult, setDistributorsResult] = useState([]);
    const [treeDistributorsData, setTreeDistributorsData] = useState();
    const [treeDefaultChecked, setTreeDefaultChecked] = useState([]);
    const parentKeys = ['platformCategories', 'deliveryType'];
    useEffect(() => {
      // Controller to abort previous API calls if any ongoing flight
      const controller = new AbortController();
      const signal = controller.signal;
      getDistributorsDataSet(signal);
      return () => {
        controller?.abort();
      };
    }, []);

    const getDistributorsDataSet = async (signal) => {
      try {
        let finalDistributorsResult = [];

        if (!DataSet?.results) {
          let queryParamsDistributor = {
            PageSize: 5000,
            Field:
              'distributor,distributorPlatform,distributorDisplayName,distributorPlatformDisplayName,distributorId,deliveryType,platformCategories',
          };

          const distributorsResponse = await HttpService.get(
            Endpoints.contractPackagesApi,
            queryParamsDistributor,
            { signal },
          );
          if (distributorsResponse) {
            finalDistributorsResult = filterDistributorResult(
              distributorsResponse.data?.results,
            );
          } else {
            finalDistributorsResult = undefined;
          }
        } else {
          finalDistributorsResult = filterDistributorResult(DataSet?.results);
        }
        if (finalDistributorsResult) {
          let treeData = await createTreeData(
            finalDistributorsResult,
            parentKeys,
          );

          setTreeDistributorsData(
            finalDistributorsResult.length > 0 ? treeData : [],
          );
          setTreeDefaultChecked(applyDefaultChecked(treeData));
          setDistributorsResult(finalDistributorsResult);
        }
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') setTreeDistributorsData([]);
      }
    };

    const applyDefaultChecked = (treeData) => {
      try {
        let result = [];
        treeData.forEach((el) => {
          if (el?.children?.length > 0) {
            let newResult = applyDefaultChecked(el.children);
            result.push(...newResult);
          } else {
            result.push(el.value);
          }
        });
        return result;
      } catch (err) {}
    };

    const filterDistributorResult = (resp) => {
      try {
        let result = [];
        resp?.forEach((el, i) => {
          if (
            Type && Type === 'ProductEntitlements'
              ? ProductDeliveryPlatforms.includes(el.distributor)
              : !ProductDeliveryPlatforms.includes(el.distributor)
          )
            if (
              el.platformCategories &&
              el.platformCategories !== '' &&
              el.distributor &&
              el.distributor !== '' &&
              el.distributorPlatform &&
              el.distributorPlatform !== '' &&
              el.deliveryType &&
              el.deliveryType !== ''
            )
              result.push({
                id: i,
                checked: Type && Type === 'ProductEntitlements',
                distributorLabel:
                  el.distributor + ' : ' + el.distributorPlatform,
                ...el,
              });
        });

        return result;
      } catch (err) {}
    };

    const handleSelection = (selectedTreeData) => {
      let tempData = distributorsResult?.map((obj) => {
        obj.checked = selectedTreeData?.includes(String(obj.id));
        return obj;
      });
      setDistributorsResult(tempData);
    };

    useEffect(() => {
      const updatedState = distributorsResult
        ?.filter((distributorsData) => distributorsData.checked === true)
        ?.map((filterdistributorsData) => {
          return {
            distributor:
              filterdistributorsData.distributor +
              ':' +
              filterdistributorsData.distributorPlatform,
            distributorId: filterdistributorsData.distributorId,
          };
        });
      callBackDistributors(updatedState);
    }, [distributorsResult]);

    const count = distributorsResult.reduce(
      (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
      0,
    );

    return (
      <TreeMultiSelectDropdown
        ddOptions={treeDistributorsData}
        getSelectedTreeData={handleSelection}
        defaultChecked={[...treeDefaultChecked]}
        enableSearch
        enableSelectAll
        ddPlaceHolder={
          <>
            Delivery Platform
            <span className={count === 0 ? 'd-none' : 'd-inline'}>
              ({count})
            </span>
          </>
        }
      />
    );
  },
  (op, np) => op?.DataSet === np?.DataSet,
);

export default ProductDistributorsDropdown;
