import { ReduxAction } from '../../constants';
const initialState = {};

const distributorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReduxAction.distributors: {
      return {
        ...state,
        userDistributors: action.payload?.distributors,
      };
    }
    default:
      return state;
  }
};
export default distributorsReducer;
