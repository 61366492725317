import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { useDispatch } from 'react-redux';
import store from '../../redux/store';
import { Endpoints, GenericError, ReduxAction, Verbiage, UserId } from '../../constants';
import httpService from '../../services/http-service';
import { Notify } from '../../sharedcomponents/Alert/Notify';
import TextFieldInput from '../../sharedcomponents/TextFieldInput/TextFieldInput';
import DropdownInput from '../../sharedcomponents/DropdownInput/DropdownInput';
import BuildingsBwImg from './../../assets/img/vector/buildings-bw.png';

const UserInfo = () => {
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState();
  const [isDirty, setIsDirty] = useState(true);
  const [initialValue, setInitialValue] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    contactId: '',
    department: '',
    jobFunction: '',
    designation: '',
    accountId: '',
    officeLocationId: '',
    contactId: '',
    isEmailOptOut: false,
  });
  const [officeLocationName, setOfficeLocationName] = useState([]);
  const [jobDesignationCollection, setJobDesignationCollection] = useState({});
  const [userContractNumber, setUserContractNumber] = useState([]);
  const [accountsData, setAccountsData] = useState([]);
  const dispatch = useDispatch();

  const validate = Yup.object({
    firstName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter first name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    lastName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter last name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    phone: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .matches(
        '^[+]*[0-9 ()-]*$',
        'Numerical values with +, -, (, ) are accepted',
      ),
    department: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .matches(
        '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        'No special characters values are accepted',
      ),
    jobFunction: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Job function'),
    designation: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Role'),
    accountId: Yup.string().required('Please select account to proceed'),
    officeLocationId: Yup.string().required(
      'Please select location to proceed',
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: (values) => {
      AddUser(values);
    },
  });

  const getjobFunctionDesignationData = () => {
    try {
      let storeData = store.getState();
      let storeJobDesignationDetails = storeData.jobDesignationDetails;
      let queryParams = {
        keyItem: `JOBFUNCTIONCOLLECTION, DESIGNATIONCOLLECTION`,
      };
      if (
        storeJobDesignationDetails?.jobFunctionCollection?.length > 0 ||
        storeJobDesignationDetails?.designationCollection?.length > 0
      ) {
        setJobDesignationCollection(storeJobDesignationDetails);
      } else {
        httpService
          .get(Endpoints.jobFunctionDesignationData, queryParams)
          .then((res) => {
            const response = res?.data?.results;
            let jobDesignationDetails = {};
            response.map((item) => {
              if (item.keyItem === 'JOBFUNCTIONCOLLECTION') {
                let jobFunc = JSON.parse(item.keyValue);

                jobDesignationDetails.jobFunctionCollection = jobFunc;
              } else if (item.keyItem === 'DESIGNATIONCOLLECTION') {
                let designation = JSON.parse(item.keyValue);

                jobDesignationDetails.designationCollection = designation;
              }
            });
            dispatch({
              type: ReduxAction.jobFuncDesignation,
              payload: jobDesignationDetails,
            });
            setJobDesignationCollection(jobDesignationDetails);
          });
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (localStorage?.userID) {
      getUserInfo();
    }
    // eslint-disable-next-line
  }, [localStorage]);

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      getAccounts();
    }, 800);
    return () => clearTimeout(reduceCallback);
  }, [userContractNumber]);

  useEffect(() => {
    if (JSON.stringify(initialValue) !== JSON.stringify(formik.values)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [formik.values]);

  useEffect(() => {
    if (initialValue && initialValue.accountId.length > 0) {
      getLocations(initialValue.accountId);
      getjobFunctionDesignationData();
      setLoading(false);
    }
  }, [accountsData]);

  const getAccounts = () => {
    if (userContractNumber) {
      let queryParams = {
        pageSize: 5000,
        field: 'accountId,accountName',
        Filter: `contractNumber in("${userContractNumber}")`,
      };
      httpService
        .get(Endpoints.accountApi, queryParams)
        .then((res) => {
          setAccountsData(res.data?.results);
        })
        .catch((err) => {});
    } else {
      setAccountsData([]);
    }
  };

  const getUserInfo = () => {
    let queryParams = {
      Filter: `email:"${localStorage.userID.toLowerCase()}"`,
      Field:
        'contactName,contactId,groupid,groupName,contractNumber,email,phone,department,jobFunction,designation,isEmailOptOut,emailProcessed,account,accountId,officeLocation,officeLocationId,entitled,modifiedDate',
      id: String(Date.now()),
    };
    httpService
      .get(Endpoints.userApi, queryParams)
      .then(({ data }) => {
        let results = data?.results[0];
        setUserDetails(results);
        setUserContractNumber(results.contractNumber);
        setInitialValue({
          firstName: results.firstName ?? '',
          lastName: results.lastName ?? '',
          email: results.email ?? '',
          accountId: results.accountId ?? '',
          contactId: results.contactId ?? '',
          officeLocationId: results.officeLocationId ?? '',
          designation: results.designation ?? '',
          jobFunction: results.jobFunction ?? '',
          role: results.designation ?? '',
          department: results.department ?? '',
          phone: results.phone ?? '',
          isEmailOptOut: results.isEmailOptOut == 1 ? true : false,
        });
      })
      .catch(() => {
        Notify({
          alert: true,
          type: 'error',
          title: GenericError.somethingWentWrong,
        });
      });
  };

  const AddUser = (userDetails) => {
    setLoading(true);
    let User_Info = {
      userid: UserId,
      isDeleted: 0,
      ...userDetails,
    };
    User_Info.contactId = userDetails.contactId;
    User_Info.isEmailOptOut = User_Info.isEmailOptOut ? 1 : 0;
    User_Info.email = userDetails.email;
    let postData = {};
    for (let key in User_Info) {
      if (typeof User_Info[key] === 'string') {
        postData[key] = User_Info[key].trim();
      } else {
        postData[key] = User_Info[key];
      }
    }
    httpService
      .post(Endpoints.userApi, [postData])
      .then((resp) => {
        if (resp?.data?.code === 0) {
          postData.isEmailOptOut = postData.isEmailOptOut === 1;
          setInitialValue({ ...postData });
          setIsDirty(false);
          Notify({
            alert: true,
            type: 'success',
            title: Verbiage.endUserDetailsEdited,
          });
        }
      })
      .catch((err) => {
        let errorMsg =
          err?.response?.data?.resultCode === '-2' || err?.response?.data?.resultCode === '-3'
            ? err?.response?.data?.cause
            : GenericError.somethingWentWrong;
        Notify({
          alert: true,
          type: 'error',
          title: errorMsg,
        });
      })
      .finally(() => setLoading(false));
  };

  const resetLocationDropdown = () => {
    let locationData = {
      target: {
        id: 'officeLocationId',
        value: '',
      },
    };
    formik.handleChange(locationData);
  };

  const AccountHandler = (val, name) => {
    let obj = {
      target: { name: name, value: val[name] ?? val },
    };
    formik.handleChange(obj);
    if (name === 'accountId') {
      resetLocationDropdown();
      if (val[name] !== '' && name === 'accountId') {
        getLocations(val[name]);
      } else {
        setOfficeLocationName();
      }
    }
  };

  const getLocations = (accountId, signal) => {
    if (userContractNumber) {
      let queryLocationParams = {
        PageSize: 5000,
        id: new Date().getTime(),
        field: 'contractNumber,officeLocationName,officeLocationId',
        filter: `accountId:"${accountId}" AND contractNumber in("${userContractNumber}")`,
      };
      httpService
        .get(Endpoints.accountApi, queryLocationParams, { signal })
        .then((res) => {
          if (res) {
            setOfficeLocationName(
              getDuplicateLocationsWithContractNum(res.data?.results),
            );
          }
        })
        .catch((err) => {});
    }
  };

  const getDuplicateLocationsWithContractNum = (locations) => {
    let uniqueLocations = [];
    let duplicateLocations = [];
    let tempLocationsWithContractNum = locations?.filter((el) => {
      if (!uniqueLocations?.includes(el.officeLocationId)) {
        uniqueLocations?.push(el.officeLocationId);

        return el;
      } else {
        duplicateLocations?.push(el.officeLocationId);
      }
    });

    tempLocationsWithContractNum = locations?.filter((el) => {
      if (duplicateLocations?.includes(el.officeLocationId)) {
        el.officeLocationName =
          el.officeLocationName + ' (' + el.contractNumber + ')';
      }
      return el;
    });
    return tempLocationsWithContractNum;
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <>
          <div className="content mx-3">
            <div className="container-fluid">
              <Row sm={12} className="user-right-section">
                <Col sm={12} className="user-info-sub-header">
                  User Info
                </Col>
                <Form className="row" onSubmit={formik.handleSubmit}>
                  <TextFieldInput
                    formLabel="First name"
                    defaultValue={userDetails?.firstName}
                    placeholder="First Name"
                    id="firstName"
                    {...formik.getFieldProps('firstName')}
                    formik={formik}
                    labelClass="user-info-form-label"
                  />
                  <TextFieldInput
                    formLabel="Last name"
                    defaultValue={userDetails?.lastName}
                    placeholder="Last Name"
                    id="lastName"
                    {...formik.getFieldProps('lastName')}
                    formik={formik}
                    labelClass="user-info-form-label"
                  />
                  <TextFieldInput
                    formLabel="Email"
                    defaultValue={userDetails?.email}
                    id="email"
                    disabled={true}
                    labelClass="user-info-form-label"
                  />
                  <DropdownInput
                    formLabel="Account"
                    name="accountId"
                    targetValue="accountId"
                    dropdownOptions={accountsData}
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.accountId !== '' && accountsData?.length > 0
                        ? accountsData?.filter(
                            (el) => el.accountId === formik.values.accountId,
                          )[0]?.accountName
                        : 'Select'
                    }
                    labelKeyName="accountName"
                    ddName={'account'}
                    onChangeHandler={(e) => AccountHandler(e, 'accountId')}
                    addAsterisk
                  />
                  <DropdownInput
                    formLabel="Location"
                    name="officeLocationId"
                    dropdownOptions={officeLocationName}
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.officeLocationId !== '' &&
                      officeLocationName?.length > 0
                        ? officeLocationName?.filter(
                            (el) =>
                              el.officeLocationId ===
                              formik.values.officeLocationId,
                          )[0]?.officeLocationName
                        : 'Select'
                    }
                    labelKeyName="officeLocationName"
                    ddName={'officeLocation'}
                    onChangeHandler={(e) =>
                      AccountHandler(e, 'officeLocationId')
                    }
                    addAsterisk
                  />
                  <DropdownInput
                    enableSearch={true}
                    searchKeys={[]}
                    formLabel="Job function"
                    {...formik.getFieldProps('jobFunction')}
                    name="jobFunction"
                    dropdownOptions={
                      jobDesignationCollection.jobFunctionCollection
                    }
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.jobFunction !== ''
                        ? formik.values.jobFunction
                        : 'Select'
                    }
                    ddName={'jobFunction'}
                    onChangeHandler={(e) => AccountHandler(e, 'jobFunction')}
                    addAsterisk
                  />
                  <DropdownInput
                    enableSearch={true}
                    formLabel="Role"
                    {...formik.getFieldProps('designation')}
                    name="designation"
                    dropdownOptions={
                      jobDesignationCollection.designationCollection
                    }
                    formik={formik}
                    ddPlaceHolder={
                      formik.values.designation !== ''
                        ? formik.values.designation
                        : 'Select'
                    }
                    ddName={'designation'}
                    onChangeHandler={(e) => AccountHandler(e, 'designation')}
                    addAsterisk
                  />
                  <TextFieldInput
                    formLabel="Department"
                    defaultValue={userDetails?.department}
                    placeholder="Department"
                    id="department"
                    {...formik.getFieldProps('department')}
                    formik={formik}
                    labelClass="user-info-form-label"
                  />
                  <TextFieldInput
                    formLabel="Business Phone number"
                    defaultValue={userDetails?.phone}
                    placeholder="Business Phone number"
                    id="phone"
                    {...formik.getFieldProps('phone')}
                    formik={formik}
                    labelClass="user-info-form-label"
                  />
                  {userDetails?.groupName && (
                    <TextFieldInput
                      formLabel="Group Name"
                      defaultValue={userDetails?.groupName}
                      id="groupName"
                      disabled={true}
                      labelClass="user-info-form-label"
                    />
                  )}
                  <Col sm={12}>
                    <Form.Group>
                      <button
                        disabled={!isDirty}
                        type="Submit"
                        className="user-save-btn btn btn-dark float-end btn-opacity"
                      >
                        Update
                      </button>
                    </Form.Group>
                  </Col>
                </Form>
              </Row>
              <Col sm={12} className="mt-3">
                <div className="d-flex">
                  <Form.Check type="checkbox" className="user-history-title">
                    <Form.Check.Input
                      type={'checkbox'}
                      {...formik.getFieldProps('isEmailOptOut')}
                      formik={formik}
                      checked={formik?.values?.isEmailOptOut}
                    />
                    <Form.Check.Label>
                      <div className="label-mt-2">
                        Marketing Email Opt-Out : You will not receive S&P
                        Global Commodity Insights promotional emails,such as
                        insightful content, event invitations and product
                        information.
                      </div>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </Col>
            </div>
          </div>
          <div className="user-dashboard-footer">
            <Col sm={8}>
              <img
                className="user-buildings-img img-fluid"
                src={BuildingsBwImg}
                alt=""
              />
            </Col>
          </div>
        </>
      )}
    </>
  );
};

export default UserInfo;
