import axios from 'axios';

// import env from 'react-dotenv';
import { DEV, QA, QAEU, PROD, PRODEU, LOCAL } from '../config';
// import errorHandler from './response-handler-interceptor';
import { UserId } from '../constants';

if (window.env) {
  if (window.env.REACT_APP_ENV === '__rae__') {
    window.config = DEV;
  }
  if (window.env.REACT_APP_ENV === 'DEV') {
    window.config = DEV;
  }
  if (window.env.REACT_APP_ENV === 'QA') {
    window.config = QA;
  }
  if (window.env.REACT_APP_ENV === 'QAEU') {
    window.config = QAEU;
  }
  if (window.env.REACT_APP_ENV === 'PROD') {
    window.config = PROD;
  }
  if (window.env.REACT_APP_ENV === 'PRODEU') {
    window.config = PRODEU;
  }
}

class HttpService {
  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  get(url, params, queryParams) {
    return axios.get(window.config.API_URL + url, {
      headers: this.getHeaders(),
      params: params,
      ...queryParams,
    });
  }

  post(url, data, params, queryParams) {
    return axios.post(window.config.API_URL + url, data, {
      headers: this.getHeaders(),
      params: params,
      ...queryParams,
    });
  }

  update(url, data, params) {
    return axios.put(window.config.API_URL + url, data, {
      headers: this.getHeaders(),
      params: params,
    });
  }

  delete(url, data) {
    return axios.delete(window.config.API_URL + url, {
      data: data,
      headers: this.getHeaders(),
    });
  }

  getHeaders() {
    const token = localStorage.getItem('token');
    return {
      Authorization: `Bearer ${token ?? ''}`,
      'Content-Type': 'application/json',
      UserId: UserId,
    };
  }
}

export default new HttpService();
