import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import error_icon from './../../assets/icons/error-icon.svg';
import success_icon from './../../assets/icons/success-icon.svg';
import warning_icon from './../../assets/icons/warning-icon.svg';
import ModalBox from './../../sharedcomponents/modal-box/ModalBox';

const AlertBox = () => {
  const [alertParams, setAlertParams] = React.useState({});

  useEffect(() => {
    //Listen to your custom event
    window.addEventListener('showAlert', (e) => {
      const { detail } = e;
      if (detail) {
        const defaultConfig = {
          confirmBtnText: 'DONE',
          alert: true,
          type: 'success',
          title: "Click 'DONE' to close this window",
          showCloseBtn: true,
          onClose: () => {},
        };
        let latestConfig = {
          ...defaultConfig,
          ...alertParams,
          ...detail,
          onConfirm: detail.onConfirm,
          onCancel: detail.onCancel,
          cancelBtnText: detail.cancelBtnText,
          showCloseBtn: detail.showCloseBtn ?? defaultConfig.showCloseBtn,
          dialogClassName: detail.dialogClassName ?? '',
          onClose: detail.onClose ?? defaultConfig.onClose,
        };

        setAlertParams(latestConfig);
      }
    });

    return hideAlert;
    // eslint-disable-next-line
  }, []);

  const hideAlert = () => {
    window.removeEventListener('showAlert', () => {}, false);
    if (alertParams.onClose) {
      alertParams.onClose();
    }
    setAlertParams({ alert: false });
  };

  const AlertConstant = {
    success: {
      title_main: 'Success',
      icon: success_icon,
    },
    error: {
      title_main: 'Error',
      icon: error_icon,
    },
    warning: {
      title_main: 'Warning',
      icon: warning_icon,
    },
    nothing: {
      title_main: '',
      icon: '',
    },
  };

  return (
    <>
      {alertParams?.alert ? (
        <ModalBox
          show={alertParams.alert}
          showHeader={false}
          handleClose={hideAlert}
          onHide={hideAlert}
          dialogClassName={'alert-box ' + alertParams?.dialogClassName}
          classHeader="border-0"
          contentClassName="alert-box-shadow"
          backdropClassName="alert-z-index"
          classBody=""
          centered
        >
          <div className="alert-body">
            {alertParams?.showCloseBtn && (
              <button
                type="button"
                className="btn-close alert-close-btn"
                aria-label="Close"
                onClick={hideAlert}
              ></button>
            )}
            {alertParams?.type && (
              <>
                <span className="alert-icon-body">
                  <img
                    className="alert-icon-img"
                    src={AlertConstant[alertParams.type].icon}
                    alt=""
                  />
                </span>
                <h2 className="alert-title-main">
                  {AlertConstant[alertParams.type].title_main}!
                </h2>
              </>
            )}

            {alertParams?.title && (
              <h3 className="alert-title">{alertParams.title}</h3>
            )}
            <div className="d-flex justify-content-center gap-4">
              {alertParams?.cancelBtnText && (
                <Button
                  variant="outline-dark"
                  className="alert-btn"
                  onClick={() => {
                    hideAlert();
                    alertParams.onCancel && alertParams.onCancel();
                  }}
                >
                  {alertParams?.cancelBtnText}
                </Button>
              )}
              <Button
                variant="dark"
                className="btn-opacity alert-btn"
                onClick={() => {
                  hideAlert();
                  alertParams.onConfirm && alertParams.onConfirm();
                }}
              >
                {alertParams?.confirmBtnText}
              </Button>
              {alertParams?.secondBtnText && (
                <Button
                  variant="dark"
                  className="btn-opacity alert-btn"
                  onClick={() => {
                    hideAlert();
                    alertParams.onSecondAction && alertParams.onSecondAction();
                  }}
                >
                  {alertParams?.secondBtnText}
                </Button>
              )}
            </div>
          </div>
        </ModalBox>
      ) : null}
    </>
  );
};

export default AlertBox;
