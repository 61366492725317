import api from '../../assets/img/distributor/api.png';
import excel from '../../assets/img/distributor/excel.png';
import ftp from '../../assets/img/distributor/ftp.png';
import kenshoLink from '../../assets/img/distributor/kenshoLink.png';
import kenshoNerd from '../../assets/img/distributor/kenshoNerd.png';
import kenshoScribe from '../../assets/img/distributor/kenshoScribe.png';
import sowflake from '../../assets/img/distributor/sowflake.png';
import websocket from '../../assets/img/distributor/websocket.png';

import AccuMap from '../../assets/img/CSM+/platforms/AccuMap.png';
import AnalyticsExplorer from '../../assets/img/CSM+/platforms/Analytics Explorer.png';
import DailyExplorationAlert from '../../assets/img/CSM+/platforms/Daily Exploration Alert.png';
import EDIN from '../../assets/img/CSM+/platforms/EDIN.png';
import EnergyStudioImpact from '../../assets/img/CSM+/platforms/Energy Studio Impact.png';
import Harmony from '../../assets/img/CSM+/platforms/Harmony.png';
import Kingdom from '../../assets/img/CSM+/platforms/Kingdom.png';
import MINT from '../../assets/img/CSM+/platforms/MINT.png';
import PetroData from '../../assets/img/CSM+/platforms/PetroData.png';
import PetrodataRigs from '../../assets/img/CSM+/platforms/Petrodata Rigs.png';
import Que$tor from '../../assets/img/CSM+/platforms/Que$tor.png';
import Vantage from '../../assets/img/CSM+/platforms/Vantage.png';
import WellTest from '../../assets/img/CSM+/platforms/WellTest.png';

export const distributorImageNames = [
  api,
  excel,
  ftp,
  kenshoLink,
  kenshoNerd,
  kenshoScribe,
  sowflake,
  websocket,
];

export const distributorImageNamesCSMPlus = {
  AccuMap: AccuMap,
  'Analytics Explorer': AnalyticsExplorer,
  EDIN: EDIN,
  'Energy Studio: Impact': EnergyStudioImpact,
  'Enerdeq™ Browser': EDIN,
  'Daily Exploration Alert': DailyExplorationAlert,
  'International Energy Letter': DailyExplorationAlert,
  'GEPS-Upstream Intelligence': DailyExplorationAlert,
  'Drilling Wire': DailyExplorationAlert,
  Harmony: Harmony,
  MINT: MINT,
  Petra: Kingdom,
  PetroData: PetroData,
  Kingdom: Kingdom,
  'Petrodata™ Rigs': PetrodataRigs,
  Que$tor: Que$tor,
  Vantage: Vantage,
  WellTest: WellTest,
};
