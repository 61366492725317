import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  Cancelled,
  PendingInactive,
  ProductDeliveryPlatforms,
  Rejected,
  Verbiage,
  loaderStyle,
} from '../../../constants';
import { debounce } from '../../../helpers/debounce';
import AgGridComponent from '../../../sharedcomponents/ag-grid/AgGrid';
import { ReactComponent as CloneIcon } from './../../../assets/icons/common/clone.svg';
import { ReactComponent as ExportIcon } from './../../../assets/icons/common/export.svg';
import Loader from './../../../components/Loader';
import CloneUserEntitlements from './../../user-details/clone-user-entitlements/clone-user-entitlements';

const MySubscriptionDetails = ({
  userEntitlements,
  userEntitlementsData,
  updatedPackageDistributors,
  loadingTab,
  setFinalRowNodeData,
  userDetails,
  handleCloneUserEntitlementsSuccess,
  isRowSelectable,
}) => {
  const gridApi = useRef(null);
  const [rowData, setRowData] = useState();
  const [initialRowData, setInitialRowData] = useState([]);
  const [initialRowNodeData, setInitialRowNodeData] = useState([]);
  const [isRowDataLoaded, setIsRowDataLoaded] = useState(false);
  const [deselectRowsData, setDeselectRowsData] = useState([]);
  const [currentlySelectedEntitlements, setCurrentlySelectedEntitlements] =
    useState([]);
  const [showCloneUserEntitlements, setShowCloneUserEntitlements] =
    useState(false);

  const isFirstColumn = (params) => {
    let displayedColumns = params.api.getAllDisplayedColumns();
    let thisIsFirstColumn = displayedColumns[0] === params.column;
    if (thisIsFirstColumn) {
      return thisIsFirstColumn;
    }
  };

  const defaultConfig = {
    rowModelType: 'clientSide',
    isExportCSV: false,
    isExportExcel: false,
    pivotPanelShow: '',
    suppressDragLeaveHidesColumns: true,
    pagination: false,
    isAutoSizeColumns: true,
    enableCharts: false,
    rowGroupPanelShow: false,
    sideBar: true,
    overlayNoRowsTemplate: 'This user has no entitlements',
  };

  const defaultColdef = {
    sortable: true,
    resizable: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: isFirstColumn,
    headerCheckboxSelection: isFirstColumn,
    menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'],
    // cellRenderer: (params) => {
    //   if (!isRowSelectable(params)) {
    //     let displayedColumns = params?.api?.getAllDisplayedColumns();
    //     if (displayedColumns[0] === params?.column) {
    //       const checkboxMainDiv = params.eGridCell.querySelector(
    //         '.ag-selection-checkbox',
    //       );
    //       const checkbox = params.eGridCell.querySelector(
    //         'div[ref="eCheckbox"] input',
    //       );
    //       const checkboxWrapper = params.eGridCell.querySelector(
    //         'div[ref="eWrapper"]',
    //       );
    //       if (!isRowSelectable(params)) {
    //         checkboxMainDiv.classList.remove('ag-hidden');
    //         checkboxWrapper.classList.add('checkbox-disable');
    //         checkbox.disabled = true;
    //         checkbox.setAttribute(
    //           'title',
    //           Verbiage.reachOutToContractAdminToUpdateEntitlements,
    //         );
    //       }
    //     }
    //   }

    //   return params.value ?? null;
    // },
  };

  const getColumnClass = (params) => {
    if (userDetails?.results[0]?.groupId) return '';
    if (
      (params?.node?.data?.status === Cancelled ||
        params?.node?.data?.status === PendingInactive ||
        params?.node?.data?.status === Rejected) &&
      !isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    }
    if (
      (!params?.node?.data?.status ||
        params?.node?.data?.status === Cancelled ||
        params?.node?.data?.status === PendingInactive ||
        params?.node?.data?.status === Rejected) &&
      params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'new-selected-entitlement-row';
    }
    if (
      params?.node?.data?.status &&
      !params?.node?.selected &&
      isRowDataLoaded
    ) {
      return 'unselected-entitlement-row';
    } else {
      return '';
    }
  };

  const distributorGetter = (params) => {
    if (params?.data)
      return (
        params?.data?.distributor +
        ' : ' +
        params?.data?.distributorPlatform +
        ' (' +
        params?.data?.deliveryType +
        ')'
      );

    return '';
  };
  const subscriptionColumns = [
    {
      headerName: 'Packages',
      headerTooltip: 'Packages',
      field: 'packageName',
      tooltipField: 'packageName',
      initialFlex: 3,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Delivery Platform (Type)',
      headerTooltip: 'Delivery Platform (Type)',
      field: 'deliveryPlatform',
      initialFlex: 3,
      valueGetter: distributorGetter,
      tooltipValueGetter: distributorGetter,
      cellClass: getColumnClass,
    },
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      field: 'status',
      tooltipField: 'status',
      initialFlex: 2,
      cellClass: getColumnClass,
    },
  ];

  const onFilterTextBoxChanged = useCallback(({ target }) => {
    let searchText = target.value;
    let moreThan2 = searchText && searchText.length > 2;
    // Apply custom search filter
    if (!searchText || moreThan2) {
      gridApi?.current?.api?.setQuickFilter(searchText);
    } else {
      gridApi?.current?.api?.setQuickFilter('');
    }

    let isRowExist = gridApi?.current?.api?.getRenderedNodes()?.length > 0;

    // Check if any row is rendered if not then show overlay
    if (moreThan2 && !isRowExist) {
      gridApi?.current?.api?.showNoRowsOverlay();
    } else {
      gridApi?.current?.api?.hideOverlay();
    }
  }, []);

  const onFilterTextBoxChangedDebounce = debounce(onFilterTextBoxChanged, 1000);

  const onGridReadyHandler = (params) => {
    gridApi.current = params;
    params.api.showLoadingOverlay();
  };

  // useeffects starts
  useEffect(() => {
    if (userEntitlements?.length > 0 && userEntitlementsData?.length > 0) {
      let mySubscriptions = userEntitlements
        .filter((ue) => !ProductDeliveryPlatforms.includes(ue.distributor))
        .map((ue) => {
          let fullDataItem = userEntitlementsData.find(
            (data) =>
              data.deliveryType === ue.deliveryType &&
              data.distributor === ue.distributor &&
              data.distributorDisplayName === ue.distributorDisplayName &&
              data.distributorPlatform === ue.distributorPlatform &&
              data.packageName === ue.packageName,
          );
          return fullDataItem ? { ...fullDataItem, ...ue } : ue;
        });

      mySubscriptions.sort(
        (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate),
      );

      setRowData(mySubscriptions);

      setInitialRowData(mySubscriptions);
    }
    if (userEntitlements && userEntitlements?.length == 0) {
      gridApi.current?.api?.showNoRowsOverlay();
      setIsRowDataLoaded(true);
    }
  }, [userEntitlements, userEntitlementsData]);

  useEffect(() => {
    const reduceCallback = setTimeout(() => {
      if (rowData?.length > 0 && isRowDataLoaded) {
        setSelectionsInGrid();
      }
    }, 100);
    return () => clearTimeout(reduceCallback);
  }, [rowData]);

  useEffect(() => {
    if (updatedPackageDistributors.data?.results.length > 0) {
      let newRowData = updatedPackageDistributors.data?.results;

      const matchesInitialEntitlement = (newEntitlement) => {
        return initialRowData.some(
          (initialEntitlement) =>
            newEntitlement.distributor === initialEntitlement.distributor &&
            newEntitlement.distributorPlatform ===
              initialEntitlement.distributorPlatform &&
            newEntitlement.deliveryType === initialEntitlement.deliveryType &&
            newEntitlement.packageName === initialEntitlement.packageName &&
            ![PendingInactive, Cancelled, Rejected].includes(
              initialEntitlement.status,
            ),
        );
      };

      newRowData = newRowData.filter(
        (entitlement) => !matchesInitialEntitlement(entitlement),
      );
      newRowData = newRowData.concat(initialRowData);
      setRowData(newRowData);
    } else if (
      initialRowData.length > 0 ||
      (isRowDataLoaded && updatedPackageDistributors.length == 0)
    ) {
      setRowData(initialRowData);
    }
  }, [updatedPackageDistributors]);
  // useeffects ends

  const getDate = () => {
    let currentDate = new Date();
    return (
      currentDate.getDate() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getFullYear()
    );
  };
  const exportEntitlementCSV = () => {
    gridApi.current?.api?.exportDataAsCsv({
      fileName: 'Subscriptions_' + getDate(),
    });
  };

  const setSelectionsInGrid = () => {
    if (deselectRowsData) {
      let nodesToDeselect = [];
      gridApi.current?.api.showLoadingOverlay();
      gridApi.current.api.selectAll();

      gridApi.current.api.forEachNode((node) => {
        // Deselect Cancelled, Rejected and Pending Inactive entitlements
        if ([PendingInactive, Cancelled, Rejected].includes(node.data.status))
          nodesToDeselect.push(node);
      });
      if (isRowDataLoaded && deselectRowsData?.length > 0) {
        gridApi?.current?.api?.forEachNode((node) => {
          if (
            deselectRowsData.some(
              (nd) =>
                nd.productComponentId === node.data.productComponentId &&
                nd.status === node.data.status,
            )
          ) {
            nodesToDeselect.push(node);
          }
        });
      }
      gridApi.current.api.setNodesSelected({
        nodes: nodesToDeselect,
        newValue: false,
      });
    }
    let intialNodesSelected = [];
    const preselectedIds = currentlySelectedEntitlements.filter(
      (ent, index, arr) => {
        return arr.findIndex(
          (t) =>
            (t.productComponentId === ent.productComponentId &&
              t.status === ent.status) === index,
        );
      },
    );
    gridApi?.current?.api?.forEachNode((node) => {
      if (
        preselectedIds.some(
          (t) =>
            t.productComponentId === node.data.productComponentId &&
            t.status === node.data.status,
        )
      ) {
        intialNodesSelected.push(node);
      }
    });

    gridApi.current.api.setNodesSelected({
      nodes: intialNodesSelected,
      newValue: true,
    });
    setDeselectRowsData([]);
    setIsRowDataLoaded(true);
    // gridApi.current?.api.refreshCells();
    gridApi.current?.api.hideOverlay();
  };

  let inProgressFlag = false;
  const onRowSelected = async (params) => {
    const { api, node } = params;
    const isSelected = node.selected;

    if (!inProgressFlag) {
      inProgressFlag = true;

      if (isSelected) {
        let deSelectedRowIndex = deselectRowsData?.findIndex(
          (el) => el.productComponentId === node.productComponentId,
        );
        let newDeSelectedRow = deselectRowsData.splice(deSelectedRowIndex, 1);
        setDeselectRowsData(newDeSelectedRow);
      } else {
        let newDeSelectedRow = [...deselectRowsData, node.data];
        setDeselectRowsData(newDeSelectedRow);
      }

      setTimeout(() => {
        inProgressFlag = false;
        gridApi.current?.api.refreshCells();
      }, 200);
    }
  };

  const onFirstEntitlementDataRendered = useCallback((params) => {
    setSelectionsInGrid();

    let initialNodes = [];
    gridApi?.current?.api?.forEachNode((node) => {
      initialNodes.push(node);
    });
    setInitialRowNodeData(initialNodes);
  }, []);

  const onSelectionChanged = () => {
    if (initialRowNodeData.length > 0) {
      // gridApi.current.api.refreshCells();

      let currentlySelected = [];
      gridApi.current.api.getSelectedNodes().forEach((node) => {
        currentlySelected.push(node.data);
      });
      setCurrentlySelectedEntitlements(currentlySelected);

      let nodeData = [];
      gridApi.current.api.forEachNode((node) => {
        nodeData.push(node);
      });
      setFinalRowNodeData(nodeData);
    }
  };

  const onSelectionChangedDebounce = debounce(onSelectionChanged, 500);

  const endUserCloneClickHandler = () => {
    if (!userDetails?.results[0]?.groupId) setShowCloneUserEntitlements(true);
  };

  const handleCloseCloneUserEntitlements = () => {
    setShowCloneUserEntitlements(false);
  };

  return (
    <>
      {showCloneUserEntitlements && (
        <CloneUserEntitlements
          showCloneUserEntitlements={showCloneUserEntitlements}
          handleCloseCloneUserEntitlements={handleCloseCloneUserEntitlements}
          handleCloneUserEntitlementsSuccess={
            handleCloneUserEntitlementsSuccess
          }
          selectedToUsers={
            userDetails.results?.length > 0 ? userDetails.results : []
          }
        />
      )}
      <div className="position-relative">
        {loadingTab && <Loader type="scaleLoader" cssClass={loaderStyle} />}
        <Container fluid>
          <div className="row mt-2">
            <div className="col">
              <input
                type="text"
                id="entitlement-search-filter-input"
                className="form-control search-box"
                placeholder="Search by packages, delivery platform or status"
                onInput={onFilterTextBoxChangedDebounce}
              ></input>
            </div>
            <div className="col action-item-bar">
              <span className="user-grid-icons icon-divider ">
                <CloneIcon
                  className={
                    userDetails?.results[0]?.groupId
                      ? 'cursor-default'
                      : 'icon-active'
                  }
                  alt="Clone entitlements"
                  width="18"
                  title={
                    userDetails?.results[0]?.groupId
                      ? Verbiage.reachOutToContractAdminToUpdateEntitlements
                      : 'Clone entitlements'
                  }
                  onClick={endUserCloneClickHandler}
                ></CloneIcon>
              </span>
              <span className="user-grid-icons icon-divider">
                <ExportIcon
                  className={
                    rowData?.length > 0 ? 'icon-active' : 'cursor-default'
                  }
                  alt="Entitlements Export"
                  width="18"
                  title="Export entitlements"
                  onClick={exportEntitlementCSV}
                ></ExportIcon>
              </span>
            </div>
          </div>
          <div className="ag-grid-table user mbx-12">
            <AgGridComponent
              config={defaultConfig}
              defaultColumnDef={defaultColdef}
              data={rowData}
              columns={subscriptionColumns}
              onGridReady={onGridReadyHandler}
              onSelectionChanged={onSelectionChangedDebounce}
              onFirstDataRendered={onFirstEntitlementDataRendered}
              onRowSelected={onRowSelected}
              isRowSelectable={isRowSelectable}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default MySubscriptionDetails;
