export const LOCAL = {
  API_URL: 'https://localhost:5001/sfs/',
  clientId: '0oa1mw9rweiiKEWIT0h8',
  issuer: 'https://secure.signinpreview.spglobal.com/oauth2/spglobal',
};

export const DEV = {
  API_URL: 'https://dev.api.platts.com/sfs/',
  clientId: '0oa1mw9rweiiKEWIT0h8',
  issuer: 'https://secure.signinpreview.spglobal.com/oauth2/spglobal',
};

export const QA = {
  API_URL: 'https://qa-us.api.platts.com/sfs/',
  clientId: '0oa1mw9rweiiKEWIT0h8',
  issuer: 'https://secure.signinpreview.spglobal.com/oauth2/spglobal',
};

export const QAEU = {
  API_URL: 'https://qa-eu.api.platts.com/sfs/',
  clientId: '0oa1mw9rweiiKEWIT0h8',
  issuer: 'https://secure.signinpreview.spglobal.com/oauth2/spglobal',
};

export const PROD = {
  API_URL: 'https://prod-us.api.platts.com/sfs/',
  clientId: '0oa1o2vjxaqC3UiGD1d8',
  issuer: 'https://secure.signin.spglobal.com/oauth2/spglobal',
};

export const PRODEU = {
  API_URL: 'https://prod-eu.api.platts.com/sfs/',
  clientId: '0oa1o2vjxaqC3UiGD1d8',
  issuer: 'https://secure.signin.spglobal.com/oauth2/spglobal',
};
