import Refined from '../../assets/img/packages/Refined.jpg';
import Agriculture from '../../assets/img/packages/Agriculture.JPG';
import AmericanGasPower from '../../assets/img/packages/American Gas & Power.jpg';
import Coal from '../../assets/img/packages/Coal.JPG';
import Crude from '../../assets/img/packages/Crude.JPG';
import EnergyTransition from '../../assets/img/packages/Energy Transition.JPG';
import EuropeanGasPower from '../../assets/img/packages/European Gas & Power.JPG';
import LNG from '../../assets/img/packages/LNG.JPG';
import Nuclear from '../../assets/img/packages/Nuclear.jpg';
import Petrochemicals from '../../assets/img/packages/Petrochemicals.JPG';
import Shipping from '../../assets/img/packages/Shipping.jpg';
import SteelRawMaterials from '../../assets/img/packages/Steel and Raw Materials.JPG';
import Metals from '../../assets/img/packages/Metals.JPG';
import AmericasGasPower from '../../assets/img/packages/Americas Gas and Power.jpg';
import NonferrousMetals from '../../assets/img/packages/Nonferrous Metals.jpg';

import AgricultureFood from '../../assets/img/CSM+/packages/Agriculture and Food.png';
import AmericasGasPowerPlus from '../../assets/img/CSM+/packages/Americas Gas and Power.png';
import AnalyticsExplorerEnterprise from '../../assets/img/CSM+/packages/Analytics Explorer Enterprise.png';
import APACGasPowerPlus from '../../assets/img/CSM+/packages/APAC Gas and Power.png';
import Biofuels from '../../assets/img/CSM+/packages/Biofuels.png';
import CanadaUpstreamContent from '../../assets/img/CSM+/packages/Canada Upstream Content.png';
import CarbonScenarios from '../../assets/img/CSM+/packages/Carbon and Scenarios.png';
import Chemicals from '../../assets/img/CSM+/packages/Chemicals.png';
import CleanEnergyTechnology from '../../assets/img/CSM+/packages/Clean Energy Technology.png';
import CostsSupplyChainOperators from '../../assets/img/CSM+/packages/Costs and Supply Chain Operators.png';
import CostsSupplyChain from '../../assets/img/CSM+/packages/Costs and Supply Chain.png';
import CropScience from '../../assets/img/CSM+/packages/Crop Science.png';
import CrudeOilProduction from '../../assets/img/CSM+/packages/Crude Oil.png';
import EMEAGasPower from '../../assets/img/CSM+/packages/EMEA Gas and Power.png';
import EnergyStrategiesTransformation from '../../assets/img/CSM+/packages/Energy Strategies and Transformation.png';
import EngineeringEnterprise from '../../assets/img/CSM+/packages/Engineering Enterprise.png';
import FerrousMetals from '../../assets/img/CSM+/packages/Ferrous Metals.png';
import Fertilizers from '../../assets/img/CSM+/packages/Fertilizers.png';
import FuelsRefining from '../../assets/img/CSM+/packages/Fuels and Refining.png';
import GeoscienceEnterprise from '../../assets/img/CSM+/packages/Geoscience Enterprise.png';
import InternationalUpstreamContent from '../../assets/img/CSM+/packages/International Upstream Content.png';
import LNGPlus from '../../assets/img/CSM+/packages/LNG.png';
import NonFerrousMetals from '../../assets/img/CSM+/packages/Non-Ferrous Metals.png';
import ShippingPlus from '../../assets/img/CSM+/packages/Shipping.png';
import UnitedStatesLandEnergy from '../../assets/img/CSM+/packages/United States Land for Energy.png';
import UnitedStatesUpstreamContent from '../../assets/img/CSM+/packages/United States Upstream Content.png';

export const packageImageNames = {
  Refined: Refined,
  Agriculture: Agriculture,
  'American Gas & Power': AmericanGasPower,
  Coal: Coal,
  Crude: Crude,
  'Energy Transition': EnergyTransition,
  'European Gas & Power': EuropeanGasPower,
  LNG: LNG,
  Nuclear: Nuclear,
  Petrochemicals: Petrochemicals,
  Shipping: Shipping,
  'Steel and Raw Materials': SteelRawMaterials,
  Metals: Metals,
  'Americas Gas and Power': AmericasGasPower,
  'Nonferrous Metals': NonferrousMetals,
};

export const packageImageNamesCSMPlus = {
  'Agriculture and Food': AgricultureFood,
  'Americas Gas and Power': AmericasGasPowerPlus,
  'Analytics Explorer Enterprise': AnalyticsExplorerEnterprise,
  'APAC Gas and Power': APACGasPowerPlus,
  Biofuels: Biofuels,
  'Canada Upstream Content': CanadaUpstreamContent,
  'Carbon and Scenarios': CarbonScenarios,
  Chemicals: Chemicals,
  'Clean Energy Technology': CleanEnergyTechnology,
  'Costs and Supply Chain Operators': CostsSupplyChainOperators,
  'Costs and Supply Chain': CostsSupplyChain,
  'Crop Science': CropScience,
  'Crude Oil': CrudeOilProduction,
  'EMEA Gas and Power': EMEAGasPower,
  'Energy Strategies and Transformation': EnergyStrategiesTransformation,
  'Engineering Enterprise': EngineeringEnterprise,
  'Ferrous Metals': FerrousMetals,
  Fertilizers: Fertilizers,
  'Fuels and Refining': FuelsRefining,
  'Geoscience Enterprise': GeoscienceEnterprise,
  'International Upstream Content': InternationalUpstreamContent,
  LNG: LNGPlus,
  'Non-Ferrous Metals': NonFerrousMetals,
  Oil: CrudeOilProduction,
  Shipping: ShippingPlus,
  'United States Land for Energy': UnitedStatesLandEnergy,
  'United States Upstream Content': UnitedStatesUpstreamContent,
};
