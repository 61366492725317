import React from 'react';
import { useNavigate } from 'react-router-dom';

// Basic component with login button
const LoginButton = () => {
  const navigate = useNavigate();

  const login = () => {
    navigate('/');
  };

  return (
    <div>
      <button className="login-button" onClick={login}>
        Log In
      </button>
    </div>
  );
};

export default LoginButton;
