import React, { memo, useEffect, useState } from 'react';
import MultiSelectDropdown from '../../sharedcomponents/multi-select-dropdown/multi-select-dropdown';
import { Endpoints, ProductDeliveryPlatforms } from './../../constants';
import HttpService from './../../services/http-service';

const ProductsDropdown = memo(
  ({ DataSet, contracts, callBackProducts, disabled = false }) => {
    const [productsResult, setProductsResult] = useState();

    useEffect(() => {
      if (contracts || DataSet) getProductsDataSet();
    }, [contracts, DataSet]);

    const getProductsDataSet = async () => {
      try {
        if (!DataSet) {
          if (contracts?.length > 0 && contracts?.some((el) => el.checked)) {
            let productDistributors = ProductDeliveryPlatforms?.map(
              (t) => '"' + t + '"',
            ).join(',');
            let queryParamsProduct = {
              PageSize: 5000,
              Field: 'productName,productId',
              Filter: `contractNumber in(${contracts
                .filter((x) => x.checked)
                .map((t) => (t.id ? '"' + t.id + '"' : ''))
                .join(',')})
              ${
                productDistributors
                  ? 'AND distributor in (' + productDistributors + ')'
                  : ''
              }`,
            };

            const productResponse = await HttpService.get(
              Endpoints.contractProductsApi,
              queryParamsProduct,
            );
            let tempState = productResponse?.data?.results?.map((el, i) => {
              return { id: i, checked: false, ...el };
            });
            setProductsResult(tempState);
          } else {
            setProductsResult([]);
          }
        } else {
          if (DataSet) {
            let tempState = DataSet?.results?.map((el, i) => {
              return { id: i, checked: false, ...el };
            });
            setProductsResult(tempState ?? []);
          }
        }
      } catch (err) {
        setProductsResult([]);
      }
    };

    useEffect(() => {
      const updatedState = productsResult
        ?.filter((productData) => productData?.checked === true)
        ?.map((filterProductData) => {
          return {
            productName: filterProductData?.productName,
            productId: filterProductData?.productId,
          };
        });
      callBackProducts(updatedState);
    }, [productsResult]);

    const count =
      productsResult?.reduce(
        (counter, obj) => (obj.checked === true ? (counter += 1) : counter),
        0,
      ) ?? 0;
    return (
      <MultiSelectDropdown
        searchKeys={['productName']}
        enableSelectAll={true}
        enableSearch={true}
        labelKeyName="productName"
        ddOptionsKey="productId"
        ddOptions={productsResult}
        setddOptions={setProductsResult}
        ddName={'products'}
        ddPlaceHolder={
          <>
            Products
            <span className={count === 0 ? 'd-none' : 'd-inline'}>
              ({count})
            </span>
          </>
        }
        disabled={disabled}
      />
    );
  },
  (op, np) => op.DataSet === np.DataSet && op.contracts === np.contracts,
);

export default ProductsDropdown;
