import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import DropdownInput from '../../../sharedcomponents/DropdownInput/DropdownInput';
import TextFieldInput from '../../../sharedcomponents/TextFieldInput/TextFieldInput';
import {
  Endpoints,
  GenericError,
  ReduxAction,
  Verbiage,
} from './../../../constants';
import store from './../../../redux/store';
import httpService from './../../../services/http-service';
import { Notify } from './../../../sharedcomponents/Alert/Notify';
import Loader from './../../../components/Loader';
import './end-user-registration.scss';

const EndUserRegistration = ({}) => {
  const [officeLocationName, setOfficeLocationName] = useState([]);
  const [jobDesignationCollection, setJobDesignationCollection] = useState({});
  const [accountsData, setAccountsData] = useState([]);
  const [initialValue, setInitialValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    department: '',
    jobFunction: '',
    designation: '',
    accountId: '',
    officeLocationId: '',
    contactId: '-1',
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    getAccounts();

    getjobFunctionDesignationData();
  }, []);

  const validate = Yup.object({
    firstName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter first name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    lastName: Yup.string()
      .trim()
      .max(80, 'Must be 80 characters or less')
      .required('Please enter last name to proceed')
      .matches('^[a-zA-Z ]*$', 'No numerical or symbolic values are accepted'),
    email: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .required('Please enter email to proceed')
      .email('Invalid email address'),
    phone: Yup.string()
      .max(40, 'Must be 40 characters or less')
      .matches(
        '^[+]*[0-9 ()-]*$',
        'Numerical values with +, -, (, ) are accepted',
      ),
    department: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .matches(
        '^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$',
        'No special characters values are accepted',
      ),
    jobFunction: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Job function'),
    designation: Yup.string()
      .max(80, 'Must be 80 characters or less')
      .required('Please select Role'),
    accountId: Yup.string().required('Please select account to proceed'),
    officeLocationId: Yup.string().required(
      'Please select location to proceed',
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validate,
    onSubmit: (values) => {
      saveEndUserForm(values);
    },
  });

  const saveEndUserForm = (userDetails) => {
    try {
      setLoading(true);
      let postData = {
        userid: userDetails.email.trim(),
        isDeleted: 0,
        isSendWelcomeEmail: true,
      };
      for (let key in userDetails) {
        if (typeof userDetails[key] === 'string') {
          postData[key] = userDetails[key].trim();
        } else {
          postData[key] = userDetails[key];
        }
      }
      httpService
        .post(Endpoints.userRegistrationUserPostApi, [postData])
        .then((resp) => {
          if (resp?.data?.code === 0) {
            Notify({
              alert: true,
              type: 'success',
              title:
                'Registration Successful. You will receive the login details in an email',
            });
            formik.resetForm();
          }
        })
        .catch((err) => {
          let errorMsg =
            err?.response?.data?.resultCode === '-2' ||
            err?.response?.data?.resultCode === '-3' ||
            err?.response?.data?.resultCode === '-4'
              ? err?.response?.data?.cause
              : GenericError.somethingWentWrong;
          Notify({
            alert: true,
            type: 'error',
            title: errorMsg,
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
    }
  };

  const dropdownOnChangeHandler = (val, name) => {
    let obj = {
      target: { name: name, value: val[name] ?? val },
    };
    formik.handleChange(obj);
    if (name === 'accountId') {
      resetLocationDropdown();
      if (val[name] !== '' && name === 'accountId') {
        getLocations(val[name]);
      } else {
        setOfficeLocationName();
      }
    }
  };

  const resetLocationDropdown = () => {
    let locationData = {
      target: {
        id: 'officeLocationId',
        value: '',
      },
    };
    formik.handleChange(locationData);
  };

  const getAccounts = () => {
    let contracts = atob(id);
    if (contracts) {
      let queryParams = {
        pageSize: 5000,
        field: 'accountId,accountName',
        filter: `contractNumber in("${contracts}")`,
      };
      httpService
        .get(Endpoints.userRegistrationAccountApi, queryParams)
        .then((res) => {
          setAccountsData(res.data?.results);
        })
        .catch((err) => {});
    } else {
      setAccountsData([]);
    }
  };

  const getLocations = (accountId) => {
    let contracts = atob(id);
    if (contracts) {
      let queryLocationParams = {
        PageSize: 5000,
        id: new Date().getTime(),
        field: 'contractNumber,officeLocationName,officeLocationId',
        filter: `accountId:"${accountId}" AND contractNumber:"${contracts}"`,
      };
      httpService
        .get(Endpoints.userRegistrationAccountApi, queryLocationParams)
        .then((res) => {
          if (res) {
            setOfficeLocationName(
              getDuplicateLocationsWithContractNum(res.data?.results),
            );
          }
        })
        .catch((err) => {});
    }
  };

  const getDuplicateLocationsWithContractNum = (locations) => {
    let uniqueLocations = [];
    let duplicateLocations = [];
    let tempLocationsWithContractNum = locations?.filter((el) => {
      if (!uniqueLocations?.includes(el.officeLocationId)) {
        uniqueLocations?.push(el.officeLocationId);

        return el;
      } else {
        duplicateLocations?.push(el.officeLocationId);
      }
    });

    tempLocationsWithContractNum = locations?.filter((el) => {
      if (duplicateLocations?.includes(el.officeLocationId)) {
        el.officeLocationName =
          el.officeLocationName + ' (' + el.contractNumber + ')';
      }
      return el;
    });
    return tempLocationsWithContractNum;
  };

  const getjobFunctionDesignationData = () => {
    try {
      let storeData = store.getState();
      let storeJobDesignationDetails = storeData.jobDesignationDetails;
      let queryParams = {
        keyItem: `JOBFUNCTIONCOLLECTION, DESIGNATIONCOLLECTION`,
      };
      if (
        storeJobDesignationDetails?.jobFunctionCollection?.length > 0 ||
        storeJobDesignationDetails?.designationCollection?.length > 0
      ) {
        setJobDesignationCollection(storeJobDesignationDetails);
      } else {
        httpService
          .get(Endpoints.userRegistrationConfigurationApi, queryParams)
          .then((res) => {
            const response = res?.data?.results;
            let jobDesignationDetails = {};
            response.map((item) => {
              if (item.keyItem === 'JOBFUNCTIONCOLLECTION') {
                let jobFunc = JSON.parse(item.keyValue);

                jobDesignationDetails.jobFunctionCollection = jobFunc;
              } else if (item.keyItem === 'DESIGNATIONCOLLECTION') {
                let designation = JSON.parse(item.keyValue);

                jobDesignationDetails.designationCollection = designation;
              }
            });
            dispatch({
              type: ReduxAction.jobFuncDesignation,
              payload: jobDesignationDetails,
            });
            setJobDesignationCollection(jobDesignationDetails);
          })
          .catch((err) => {});
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader type="scaleLoader" />
      ) : (
        <Row sm={12} className="user-registartion-section">
          <Col sm={12} className="user-info-sub-header">
            User Registration
          </Col>
          <Form className="row" onSubmit={formik.handleSubmit}>
            <TextFieldInput
              formLabel="First name"
              placeholder="First name"
              id="firstName"
              {...formik.getFieldProps('firstName')}
              formik={formik}
              addAsterisk
            />
            <TextFieldInput
              formLabel="Last name"
              placeholder="Last name"
              id="lastName"
              {...formik.getFieldProps('lastName')}
              formik={formik}
              addAsterisk
            />
            <TextFieldInput
              formLabel="Email"
              placeholder="Email address"
              id="email"
              {...formik.getFieldProps('email')}
              formik={formik}
              addAsterisk
            />
            <DropdownInput
              formLabel="Account"
              name="accountId"
              targetValue="accountId"
              dropdownOptions={accountsData}
              formik={formik}
              ddPlaceHolder={
                formik.values.accountId !== '' && accountsData?.length > 0
                  ? accountsData?.filter(
                      (el) => el.accountId === formik.values.accountId,
                    )[0]?.accountName
                  : 'Select'
              }
              labelKeyName="accountName"
              ddName={'accountsData'}
              onChangeHandler={(e) => dropdownOnChangeHandler(e, 'accountId')}
              addAsterisk
            />
            <DropdownInput
              enableSearch={true}
              searchKeys={['officeLocationName']}
              formLabel="Location"
              name="officeLocationId"
              dropdownOptions={officeLocationName}
              formik={formik}
              ddPlaceHolder={
                formik.values.officeLocationId !== '' &&
                officeLocationName?.length > 0
                  ? officeLocationName?.filter(
                      (el) =>
                        el.officeLocationId === formik.values.officeLocationId,
                    )[0]?.officeLocationName
                  : 'Select'
              }
              labelKeyName="officeLocationName"
              ddName={'officeLocation'}
              onChangeHandler={(e) =>
                dropdownOnChangeHandler(e, 'officeLocationId')
              }
              addAsterisk
            />
            <DropdownInput
              enableSearch={true}
              searchKeys={[]}
              formLabel="Job function"
              {...formik.getFieldProps('jobFunction')}
              name="jobFunction"
              dropdownOptions={jobDesignationCollection.jobFunctionCollection}
              formik={formik}
              ddPlaceHolder={
                formik.values.jobFunction !== ''
                  ? formik.values.jobFunction
                  : 'Select'
              }
              ddName={'jobFunction'}
              onChangeHandler={(e) => dropdownOnChangeHandler(e, 'jobFunction')}
              addAsterisk
            />
            <DropdownInput
              enableSearch={true}
              formLabel="Role"
              {...formik.getFieldProps('designation')}
              name="designation"
              dropdownOptions={jobDesignationCollection.designationCollection}
              formik={formik}
              ddPlaceHolder={
                formik.values.designation !== ''
                  ? formik.values.designation
                  : 'Select'
              }
              ddName={'designation'}
              onChangeHandler={(e) => dropdownOnChangeHandler(e, 'designation')}
              addAsterisk
            />
            <TextFieldInput
              formLabel="Department"
              placeholder="Department"
              id="department"
              {...formik.getFieldProps('department')}
              formik={formik}
            />
            <TextFieldInput
              formLabel="Business Phone number"
              placeholder="Business Phone number"
              id="phone"
              {...formik.getFieldProps('phone')}
              formik={formik}
            />
            <div className="user-details-fix d-flex">
              <Form.Group className="col-12 user-save-btn-body">
                <button
                  type="Submit"
                  className="user-save-btn btn btn-dark float-end btn-opacity"
                >
                  Submit
                </button>
              </Form.Group>
            </div>
          </Form>
        </Row>
      )}
    </>
  );
};

export default EndUserRegistration;
